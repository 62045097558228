import React, { useMemo, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { LoaderIcon, McLink, MyList } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import MyPopover from 'src/Components/Authenticated/MyPopover'
import Portlet from 'src/Components/Authenticated/Portlet'
import McFormModal from 'src/Components/Modals/McFormModal'
import { MainPage, MyCheckboxLabel } from 'src/Components/My'
import {
  BalanceCheckTypeOptions,
  formatCentsAsEuro,
  PayoutTypeOptions,
} from 'src/Helpers/CurrencyHelpers'
import { GetLocalTextDTO } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { GetLocalizedImageFileUrl } from 'src/Images'
import {
  ChargePaymentMethodTypes,
  PaymentMethodTypes,
} from 'src/Models/Server/Enums'
import { useGetCurrencyPaymentMethodsQuery } from 'src/Services/ToprCoreApi/RTK/Slices/CurrencyPaymentMethodSlice'
import {
  useEditCurrencyImageMutation,
  useGetCurrencyQuery,
} from 'src/Services/ToprCoreApi/RTK/Slices/CurrencySlice'
import { useGetMerchantCurrenciesQuery } from 'src/Services/ToprCoreApi/RTK/Slices/MerchantCurrencySlice'
import AppImageForm from './AppImageForm'
import { HasPaymentMethodType } from './CurrencyPaymentMethods/CpmHelpers'
import CurrencyPaymentMethodsTable from './CurrencyPaymentMethods/CurrencyPaymentMethodsTable'
import MerchantCurrenciesTable from './MerchantCurrencies/MerchantCurrenciesTable'

const CurrencyDetails: React.FC = () => {
  const params = useParams<'currencyId'>()

  const [isEditImageModalVisble, setIsEditImageModalVisble] = useState(false)
  const { data: currency, isLoading: currencyIsLoading } = useGetCurrencyQuery(
    params.currencyId
  )

  const { data: currencyPaymentMethods } = useGetCurrencyPaymentMethodsQuery(
    params.currencyId
  )

  const { data: merchantCurrencies } = useGetMerchantCurrenciesQuery(
    params.currencyId
  )

  const [editCurrencyImage, { isLoading }] = useEditCurrencyImageMutation()

  const currencyName = `${GetLocalTextDTO(currency?.Description)} (${
    currency?.Code
  })`

  const hasConsumerChargeError = useMemo(() => {
    if (currency === undefined) {
      return false
    }

    return (
      currency.ConsumerCharge &&
      !HasPaymentMethodType(currencyPaymentMethods, ChargePaymentMethodTypes)
    )
  }, [currency, currencyPaymentMethods])

  const hasAutomaticChargeError = useMemo(() => {
    if (currency === undefined) {
      return false
    }

    return (
      currency.AutomaticChargeAllowed &&
      !HasPaymentMethodType(currencyPaymentMethods, [
        PaymentMethodTypes.AutomaticChargeCollection,
      ])
    )
  }, [currency, currencyPaymentMethods])

  const hasWithdrawError = useMemo(() => {
    if (currency === undefined) {
      return false
    }

    return (
      currency.ConsumerWithdraw &&
      !HasPaymentMethodType(currencyPaymentMethods, [
        PaymentMethodTypes.Withdraw,
      ])
    )
  }, [currency, currencyPaymentMethods])

  const renderCurrencyInfo = () => {
    if (currency === undefined || currencyIsLoading) {
      return <LoaderIcon IsLoading={true} />
    }

    const renderTenants = () => {
      if (currency.Tenants === null || currency.Tenants === undefined) {
        return null
      }

      return (
        <>
          <ul>
            {currency.Tenants.map((tenant, key) => (
              <li key={key}>
                <i className="fa fa-building dropdown-icon"></i>
                {tenant}
              </li>
            ))}
          </ul>
        </>
      )
    }

    const maxBalanceEuro =
      currency.MaxBalance !== null && currency.MaxBalance !== undefined
        ? formatCentsAsEuro(currency.MaxBalance)
        : 'Geen MaxBalance'

    const balanceCheckType = BalanceCheckTypeOptions.find(
      (x) => x.value === `${currency.BalanceCheckTypeID}`
    )?.label
    const payoutType = PayoutTypeOptions.find(
      (x) => x.value === `${currency.CurrencyPayoutTypeID}`
    )?.label

    return (
      <>
        <Row>
          <Col md={6}>
            <MyList>
              <MyList.ListItem label="Naam">
                {currencyName}
                <span className="pull-right">{currency.OwnerMerchant}</span>
              </MyList.ListItem>
              <MyList.ListItem label="Amount description">
                {currency.AmountDescription}
              </MyList.ListItem>
              <MyList.ListItem label="StringFormat">
                <MyCheckboxLabel
                  label="IsDecimal"
                  checked={currency.IsDecimal}
                  pullRight
                />
                {currency.StringFormat}
              </MyList.ListItem>
              <MyList.ListItem label="Max Balance">
                {maxBalanceEuro}
              </MyList.ListItem>
              <MyList.ListItem>
                <Row>
                  <Col md={6}>
                    <h6>Instellingen</h6>
                    <MyCheckboxLabel
                      label="IsPublic"
                      checked={currency.IsPublic}
                    />
                    <MyCheckboxLabel
                      label="IsActive"
                      checked={currency.IsActive}
                    />
                    <MyCheckboxLabel
                      label="CancelAllowed"
                      checked={currency.CancelAllowed}
                    />
                    <MyCheckboxLabel
                      label="HasUserCurrencyValue"
                      checked={currency.HasUserCurrencyValue}
                    />
                  </Col>
                  <Col md={6}>
                    <h6>Tenants</h6>
                    {renderTenants()}
                  </Col>
                </Row>
              </MyList.ListItem>
              <MyList.ListItem>
                <Row>
                  <Col md={6}>
                    <h6>Klanten</h6>
                    <MyCheckboxLabel
                      label="ConsumerCharge"
                      checked={currency.ConsumerCharge}
                    >
                      {hasConsumerChargeError && (
                        <MyPopover
                          message={
                            'Er is nog geen currency payment method voor opwaarderen toegevoegd.'
                          }
                        />
                      )}
                    </MyCheckboxLabel>
                    <MyCheckboxLabel
                      label="ConsumerWithdraw"
                      checked={currency.ConsumerWithdraw}
                    >
                      {hasWithdrawError && (
                        <MyPopover
                          message={
                            'Er is nog geen currency payment method voor terug boeken toegevoegd.'
                          }
                        />
                      )}
                    </MyCheckboxLabel>
                    <MyCheckboxLabel
                      label="ConsumerSendMoney"
                      checked={currency.ConsumerSendMoney}
                    />
                    <MyCheckboxLabel
                      label="AutomaticChargeAllowed"
                      checked={currency.AutomaticChargeAllowed}
                    >
                      {hasAutomaticChargeError && (
                        <MyPopover
                          message={
                            'Er is nog geen currency payment method voor automatisch opwaarderen toegevoegd.'
                          }
                        />
                      )}
                    </MyCheckboxLabel>
                  </Col>
                  <Col md={6}>
                    <h6>Manager</h6>
                    <MyCheckboxLabel
                      label="ManagerCharge"
                      checked={currency.ManagerCharge}
                    />
                    <MyCheckboxLabel
                      label="ManagerWithdraw"
                      checked={currency.ManagerWithdraw}
                    />
                  </Col>
                </Row>
              </MyList.ListItem>
            </MyList>
          </Col>
          <Col md={6}>
            <MyList>
              <MyList.ListItem label="AppPhoto">
                <img
                  src={GetLocalizedImageFileUrl(
                    currency.AppPhotoLocalizedImageGUID
                  )}
                  className="fluid"
                />
                <Button
                  bsStyle="primary"
                  type="button"
                  bsSize="xs"
                  onClick={() => setIsEditImageModalVisble(true)}
                >
                  Wijzigen
                </Button>
              </MyList.ListItem>
              <MyList.ListItem label="BalanceCheckType">
                {balanceCheckType}
              </MyList.ListItem>
              <MyList.ListItem label="CurrencyPayoutType">
                {payoutType}
              </MyList.ListItem>
            </MyList>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={currencyName}
          previousPages={[
            {
              to: `/currencies`,
              title: t.common.currencies,
            },
          ]}
        />
        <Portlet
          title={currencyName}
          headerContent={
            <>
              <McLink to={`/currency/${params.currencyId}/edit`} icon="fa-edit">
                {t.common.edit}
              </McLink>
            </>
          }
        >
          {renderCurrencyInfo()}
        </Portlet>
        <CurrencyPaymentMethodsTable
          currency={currency}
          currencyPaymentMethods={currencyPaymentMethods}
        />
        <MerchantCurrenciesTable merchantCurrencies={merchantCurrencies} />
        <McFormModal
          title={'Image aanpassen'}
          isVisible={isEditImageModalVisble}
          closeClicked={() => setIsEditImageModalVisble(false)}
        >
          <AppImageForm
            isLoading={isLoading}
            closeClicked={() => setIsEditImageModalVisble(false)}
            onSubmit={async (data) => {
              //console.log(data)
              editCurrencyImage({
                currencyId: params.currencyId,
                imageFile: data.ImageFile,
              }).then(() => {
                setIsEditImageModalVisble(false)
              })
            }}
          />
        </McFormModal>
      </MainPage>
    </>
  )
}

export default CurrencyDetails
