export enum CouponTypesDTO {
  Euro = 1,
  Percentage = 2,
  Bulk = 3,
}

export enum TransactionSearchType {
  Reference = 0,
  Guid = 1,
}

export enum VoucherTypes {
  AmountDiscount = 1,
  PercentageDiscount = 2,
  FreeProduct = 22,
}

export enum LoyaltyStatusDTO {
  Active = 0,
  NotYetActive = 1,
  Expired = 2,
  Deactivated = 3,
}

export enum RolesDTO {
  ToprWebManager = 300,
}

export enum AutomaticChargeCollectionStatusTypesDTO {
  //ToCollect = 0,
  Collected = 1,
  Canceled = 2, //CanceledEmailSent = 2,
  CanceledMailMerged = 3, //CanceledFirstReminder = 3,
  ReadyForBlock = 4, //CanceledSecondReminder = 4,
  Blocked = 5, //ContactConsumer = 5,
  //AppointmentConsumer = 6,
  //ErrorOnCreate = 7
}

export enum LoyaltyProgramTypes {
  CompleteRuleSetOnce = 1,
  CompleteRuleSetMax = 2,
  IndividualRuleOnce = 3,
  IndividualRuleMax = 4,
  BalanceRuleSet = 5,
  RedeemCoupon = 6,
}

export enum LoyaltyRewardTypes {
  CouponReward = 1,
  CurrencyStaticReward = 2,
  CurrencyOrderPercentageReward = 3,
}

export enum LoyaltyRuleTypes {
  Article = 1,
  ArticleGroup = 2,
  Balance = 3,
  TransactionAmountPositive = 4, //opwaardering
  TransactionAmountNegative = 5, //betaling
  RedeemCoupon = 6,
}

export enum LoyaltyTypes {
  Currency = 1,
  Coupon = 2,
}

export enum UserScheduleType {
  Daily = 1,
  //Weekly = 2,
  Monthly = 3,
}

export enum LoyaltyDateRangeStatus {
  Active = 1,
  NotYetActive = 2,
  Expired = 3,
}

export enum ConsumerSubscriptionItemType {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
  Trips = 5,
  Unlimited = 6,
}

export enum TenantIds {
  Topr = 9,
  Siedler = 10,
}

export enum PosOrderDeliveryTypesDTO {
  Pickup = 0,
  Delivery = 1,
  PickupAndDelivery = 2,
}

export enum UserCouponStatus {
  Active = 1,
  NotYetActive = 2,
  Expired = 3,
  Redeemed = 4,
}

export type QrCodeTypes = 'Table' | 'Ticket'

export enum PaymentMethodTypes {
  Withdraw = 16,
  AutomaticChargeCollection = 17,

  //charge
  OmnikassaIDEAL = 12,
  Omnikassa = 11,
  OmnikassaMastercard = 13,
  OmnikassaMisterCash = 15,
  OmnikassaV2 = 18,
  OmnikassaV2Cards = 26,
  OmnikassaV2IDEAL = 19,
  OmnikassaV2Maestro = 24,
  OmnikassaV2Mastercard = 20,
  OmnikassaV2MisterCash = 22,
  OmnikassaV2Paypal = 23,
  OmnikassaV2VISA = 21,
  OmnikassaV2V_PAY = 25,
  MollieSofort = 6,
  PayPal = 30,
}

export const ChargePaymentMethodTypes: PaymentMethodTypes[] = [
  PaymentMethodTypes.OmnikassaV2IDEAL,
  PaymentMethodTypes.OmnikassaV2,
  PaymentMethodTypes.OmnikassaV2Cards,
  PaymentMethodTypes.OmnikassaV2Maestro,
  PaymentMethodTypes.OmnikassaV2Mastercard,
  PaymentMethodTypes.OmnikassaV2MisterCash,
  PaymentMethodTypes.OmnikassaV2Paypal,
  PaymentMethodTypes.OmnikassaV2VISA,
  PaymentMethodTypes.OmnikassaV2V_PAY,
  PaymentMethodTypes.MollieSofort,
  PaymentMethodTypes.PayPal,
  PaymentMethodTypes.Omnikassa,
  PaymentMethodTypes.OmnikassaMastercard,
  PaymentMethodTypes.OmnikassaMisterCash,
  PaymentMethodTypes.OmnikassaIDEAL,
]
