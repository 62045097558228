import { action } from 'mobx'
import React, {
  InputHTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  useRef,
} from 'react'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import MyLabel from './MyLabel/MyLabel'

interface IProps<T> {
  name: Path<T>
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  label?: string
  labelClassName?: string
  infoText?: string
  inputMask?: RegExp
  icon?: string
  upperCase?: boolean

  //type?: 'text' | 'checkbox' | 'text-button'

  actionButton?: {
    text?: string
    icon?: string
    type: 'submit' | 'button'
    disabled?: boolean
    bsStyle?:
      | 'primary'
      | 'success'
      | 'info'
      | 'warning'
      | 'danger'
      | 'link'
      | 'default'
    clicked?: (inputRef: MutableRefObject<HTMLInputElement>) => void
  }
  formGroupStyle?: React.CSSProperties
}

type ICombinedProps<T> = IProps<T> & InputHTMLAttributes<HTMLInputElement>

export const InputMasks = {
  Number: /^[0-9]*$/,
  Currency: /^[0-9]{1,4}(?:,[0-9]{0,2})?$/,
  ActivationCode: /^[0-9]{0,8}$/,
  ZipCode: /^[0-9]{1,4}(?:[a-zA-Z]{0,2})?$/,
  Percent: /^[0-9]{1,3}$/,
  CurrencyCode: /^[0-9a-zA-Z]{1,3}$/,
}

const MyTextbox = <ObjectType,>({
  name,
  rules,
  label,
  labelClassName,
  infoText,
  icon,
  inputMask,
  //onChange,
  className,

  actionButton,
  useFormProps,
  formGroupStyle,
  type,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formObject,
  upperCase,
  ...otherProps
}: PropsWithChildren<ICombinedProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps

  const inputRef = useRef<HTMLInputElement>(null)

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const renderIcon = () => {
    if (icon) {
      return (
        <div className="input-group-addon">
          <i className={`fa ${icon}`} />
        </div>
      )
    }
    return null
  }
  const validateInputMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputMask) {
      const valueAndKey = e.currentTarget.value + e.key
      if (e.key !== 'Enter' && !inputMask.test(valueAndKey)) {
        e.preventDefault()
      }
    }
  }

  const renderTextInput = () => {
    const myValue = value === undefined ? '' : value
    return (
      <>
        <input
          type={type}
          ref={inputRef}
          value={myValue as any}
          name={name.toString()}
          onChange={(e) => {
            if (upperCase) {
              e.target.value = e.target.value.toUpperCase()
            }
            onChange(e)
          }}
          onKeyPress={(e) => {
            validateInputMask(e)
          }}
          {...otherProps}
          className={`form-control ${className}` || 'form-control'}
        />
      </>
    )
  }

  const renderActionButton = () => {
    if (actionButton) {
      const buttonClass =
        actionButton.bsStyle !== undefined
          ? `btn-${actionButton.bsStyle}`
          : 'btn-primary'

      const renderIcon = () => {
        if (actionButton.icon === undefined) {
          return null
        }
        return (
          <>
            <i className={`fa ${actionButton.icon}`} />{' '}
          </>
        )
      }

      const disabled =
        actionButton.disabled === undefined ? false : actionButton.disabled

      return (
        <span className="input-group-btn">
          <button
            onClick={() => {
              if (actionButton.clicked) {
                actionButton.clicked(inputRef)
              }
            }}
            className={`btn ${buttonClass}`}
            type={actionButton.type}
            disabled={disabled}
          >
            {renderIcon()}
            {actionButton.text}
          </button>
        </span>
      )
    }
    return null
  }

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const renderTextBox = () => {
    const hasIconOrActionButton =
      icon !== undefined || actionButton !== undefined
    return (
      <>
        <div className="form-group" style={formGroupStyle}>
          {label && (
            <MyLabel
              htmlFor={name}
              isRequired={required}
              className={labelClassName}
              infoText={infoText}
            >
              {label}
            </MyLabel>
          )}
          {hasIconOrActionButton && (
            <div className="input-group">
              {renderIcon()}
              {renderTextInput()}
              {renderActionButton()}
            </div>
          )}
          {!hasIconOrActionButton && renderTextInput()}
          {errorText()}
        </div>
      </>
    )
  }

  return <>{renderTextBox()}</>
}

export default MyTextbox
