import React from 'react'
import { ButtonToolbar, Col, FormGroup, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import UserCouponStatusBadge from 'src/Components/Authenticated/UserCouponStatusBadge'
import { MyDataTable } from 'src/Components/My'
import {
  FormatDateTimeLocalized,
  GetUserCouponStatus,
} from 'src/Helpers/DateHelpers'
import { sortDate, sortUserCouponStatus } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import { ICouponTotalsForUserVTO } from 'src/Models/Server/Response/Coupon/ICouponTotalsForUserVTO'
import { IUserCouponVTO } from 'src/Models/Server/Response/Coupon/IUserCouponVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { userInfoStore } from '../../InfoTab/Store'
import { userLoyaltyStore } from '../Store'

interface IProps {
  userCoupons: IUserCouponVTO[]
  couponTotalsUser: ICouponTotalsForUserVTO[]
}

const UserCouponsPortlet: React.FC<IProps> = ({
  userCoupons,
  couponTotalsUser,
}) => {
  const navigate = useNavigate()
  // if (userCoupons.length === 0) {
  //   return null
  // }

  const renderCouponTotals = () => {
    //table table-bordered
    return (
      <MyDataTable
        data={couponTotalsUser}
        columns={[
          {
            dataField: 'FullTitle',
            text: t.common.name,
            sort: true,
          },
          {
            dataField: 'Total',
            text: t.common.total,
            sort: true,
          },
          {
            dataField: 'Redeemed',
            text: t.common.redeemed,
            sort: true,
          },
        ]}
      />
    )
  }

  const renderUserCoupons = () => {
    const columns: IColumn<IUserCouponVTO>[] = [
      {
        dataField: 'FullTitle',
        text: t.common.name,
        sort: true,
        formatter: (userCoupon) => {
          return (
            <>
              {userCoupon.FullTitle}{' '}
              {userCoupon.Redeemed === null && (
                <McButton
                  iconLeft="fa-times"
                  bsSize="xs"
                  bsStyle="danger"
                  pullRight
                  title={t.common.delete}
                  isLoading={
                    userCoupon.UserCouponId ===
                    userLoyaltyStore.DeletingUserCouponId
                  }
                  onClick={() => {
                    appUiStore.ShowConfirm({
                      confirmAction: () => {
                        userLoyaltyStore.DeletUserCoupon(
                          userCoupon.UserCouponId
                        )
                      },
                      confirmActionText: t.common.delete,
                      message: t.formatString(
                        t.coupons.deleteUserCouponConfirm,
                        {
                          fullTitle: userCoupon.FullTitle,
                        }
                      ),
                    })
                  }}
                >
                  {t.common.delete}
                </McButton>
              )}
            </>
          )
        },
      },
      // {
      //   dataField: 'ValidFrom',
      //   text: t.common.validFrom,
      //   sort: true,
      //   formatter: ( userCoupon: IUserCouponVTO) => {
      //     return FormatDateTimeLocalized(userCoupon.ValidFrom)
      //   },
      //   sortFunc: (order, dataField, rowA, rowB) => {
      //     return sortDate(order, rowA, rowB, 'ValidFrom')
      //   },
      // },
      {
        dataField: 'Redeemed',
        text: t.common.status,
        sort: true,
        sortFunc: (
          order,
          dataField,
          rowA: IUserCouponVTO,
          rowB: IUserCouponVTO
        ) => {
          const statusA = GetUserCouponStatus(
            rowA.ValidFrom,
            rowA.ValidTill,
            rowA.Redeemed
          )
          const statusB = GetUserCouponStatus(
            rowB.ValidFrom,
            rowB.ValidTill,
            rowB.Redeemed
          )

          return sortUserCouponStatus(order, statusA, statusB)
        },
        formatter: (userCoupon: IUserCouponVTO) => {
          return (
            <>
              <Row>
                <Col md={6}>
                  <UserCouponStatusBadge
                    validFrom={userCoupon.ValidFrom}
                    validTill={userCoupon.ValidTill}
                    redeemed={userCoupon.Redeemed}
                  />
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label>{t.common.redeemed}</label>
                    {FormatDateTimeLocalized(userCoupon.Redeemed)}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>{t.common.validFrom}</label>
                  {FormatDateTimeLocalized(userCoupon.ValidFrom)}
                </Col>
                <Col md={6}>
                  <label>{t.common.validTill}</label>
                  {FormatDateTimeLocalized(userCoupon.ValidTill)}
                </Col>
              </Row>
            </>
          )
        },
      },
      // {
      //   dataField: 'Redeemed',
      //   text: t.common.redeemed,
      //   sort: true,
      //   sortFunc: (order, dataField, rowA, rowB) => {
      //     return sortDate(order, rowA, rowB, 'Redeemed')
      //   },
      //   formatter: ( userCoupon: IUserCouponVTO) => {
      //     return FormatDateTimeLocalized(userCoupon.Redeemed)
      //   },
      // },
      {
        dataField: 'Created',
        text: t.common.created,
        sort: true,
        formatter: (userCoupon: IUserCouponVTO) => {
          return FormatDateTimeLocalized(userCoupon.Created)
        },
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Created')
        },
      },
    ]

    return (
      <>
        <MyDataTable
          data={userCoupons}
          columns={columns}
          defaultSorting={{
            dataField: 'Created',
            order: 'desc',
          }}
        />
      </>
    )
  }

  const renderButtons = () => {
    const { User } = userInfoStore

    if (User === null) {
      return null
    }

    return (
      <ButtonToolbar className="pull-right">
        <McButton
          iconLeft="fa-plus"
          bsSize="xs"
          bsStyle="primary"
          title={t.coupons.userCouponAdd.title}
          onClick={() => {
            navigate('/usercoupon/add', {
              state: { ids: [User.UserId] },
            })
          }}
        >
          {t.coupons.userCouponAdd.title}
        </McButton>
      </ButtonToolbar>
    )
  }

  return (
    <Portlet title={t.common.coupons} headerContent={renderButtons()}>
      <h5>{t.common.totals}</h5>
      {renderCouponTotals()}

      <h5 style={{ marginTop: 15 }}></h5>
      {renderUserCoupons()}
    </Portlet>
  )
}
export default UserCouponsPortlet
