import { observer } from 'mobx-react'
import React, { Fragment } from 'react'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'
import {
  FormatDateLocalized,
  FormatDateTimeLocalized,
} from 'src/Helpers/DateHelpers'
import { sortDate, sortTokenStatus } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import {
  getTokenStatus,
  ITokenSTO,
  TokenStatus,
} from 'src/Models/Server/Response/Token/ITokenSTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { tokensStore } from './Store'

interface IProps {
  userGuid: string
  tokens: ITokenSTO[]
}

const TokensPortlet: React.FC<IProps> = ({ userGuid, tokens }) => {
  const renderTokens = () => {
    const { IsLoading } = tokensStore

    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    const statusFormatter = (token: ITokenSTO) => {
      const status = getTokenStatus(token)

      const statusName = TokenStatus[status].toString()
      const statusText = t.tokenStatus[statusName]
      let icon = 'fas fa-check-circle green'
      let actionButtons = null
      switch (status) {
        case TokenStatus.blocked:
          icon = 'fas fa-ban red'
          actionButtons = (
            <McButton
              bsSize="xs"
              pullRight
              bsStyle="success"
              onClick={() =>
                appUiStore.ShowConfirm({
                  message: t.tokens.unblockToken.confirmMessage,
                  confirmAction: () =>
                    tokensStore.UnblockToken(token.TokenGUID, userGuid),
                  confirmActionText: t.tokens.unblockToken.action,
                })
              }
            >
              {t.tokens.unblockToken.action}
            </McButton>
          )
          break
        case TokenStatus.notActivated:
          icon = 'far fa-check-square'
          break
        case TokenStatus.expired:
          icon = 'fas fa-exclamation-triangle yellow'
          break
        case TokenStatus.activated:
        default:
          icon = 'fas fa-check-circle green'
          actionButtons = (
            <McButton
              bsSize="xs"
              pullRight
              bsStyle="danger"
              onClick={() =>
                appUiStore.ShowConfirm({
                  message: t.tokens.blockToken.confirmMessage,
                  confirmAction: () =>
                    tokensStore.BlockToken(token.TokenGUID, userGuid),
                  confirmActionText: t.tokens.blockToken.action,
                })
              }
            >
              {t.tokens.blockToken.action}
            </McButton>
          )
          break
      }

      return (
        <Fragment>
          <i className={icon} /> {statusText} {actionButtons}
        </Fragment>
      )
    }

    const columns: IColumn<ITokenSTO>[] = [
      {
        formatter: statusFormatter,
        text: t.common.status,
        sort: true,
        sortFunc: (order, dataField, rowA: ITokenSTO, rowB: ITokenSTO) => {
          return sortTokenStatus(order, rowA, rowB)
        },
      },
      {
        dataField: 'Activated',
        formatter: (data: ITokenSTO) => {
          return FormatDateTimeLocalized(data.Activated)
        },

        text: t.tokens.dateActivated,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Activated')
        },
      },
      {
        dataField: 'ValidThru',
        formatter: (data: ITokenSTO) => {
          return FormatDateLocalized(data.ValidThru)
        },

        text: t.tokens.validThru,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'ValidThru')
        },
      },
      {
        dataField: 'ActivationCode',
        text: t.tokens.activationCode,
        sort: true,
        filter: true,
      },
      {
        dataField: 'Description',
        text: t.common.description,
        sort: true,
        filter: true,
      },
    ]

    return <MyDataTable data={tokens} columns={columns} />
  }

  return <Portlet title={t.common.tokens}>{renderTokens()}</Portlet>
}

export default observer(TokensPortlet)
