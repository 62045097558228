import React, { memo } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import { InputMasks, MyCheckbox, MySelect, MyTextbox } from 'src/Components/My'

import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { ICurrencyPaymentMethod } from 'src/Models/Server/Response/Currency/ICurrencyPaymentVTO'
import {
  getPaymentMethodTypesOptions,
  transactionCostsToText,
} from './CpmHelpers'
interface IProps {
  closeClicked: () => void
  onSubmit: (data: IFormValues) => void
  paymentProviderConfgurationList: INameIdVTO<number>[]
  debtorMerchants: ISelectOption[]
  isLoading: boolean
  editCurrencyPaymentMethod?: ICurrencyPaymentMethod
}

interface IFormValues {
  PaymentMethodType: ISelectOption
  IsFixed: boolean
  TransactionCosts: string
  PaymentProvider: ISelectOption
  DebtorMerchant: ISelectOption
}

const CpmForm: React.FC<IProps> = ({
  closeClicked,
  onSubmit,
  paymentProviderConfgurationList,
  debtorMerchants,
  isLoading,
  editCurrencyPaymentMethod,
}) => {
  let myDefaultValues: Partial<IFormValues> = {
    IsFixed: true,
    TransactionCosts: '0',
  }

  const getPaymentProviderOptions = () => {
    //put these on top
    //14		OmnikassaV2 Mybility Testomgeving
    //13	OmnikassaV2 Mybility

    //paymentProviderConfgurationList is readonly so create copy
    const myList = [...paymentProviderConfgurationList]

    const omnikassav2Index = myList.findIndex((x) => x.Id === 13)
    const omnikassaElements = myList.splice(omnikassav2Index, 2)

    const result = [...omnikassaElements, ...myList].map<ISelectOption>(
      (pp) => ({
        label: pp.Name,
        value: pp.Id.toString(),
      })
    )

    return result
  }

  const paymentMethodTypesOptions = getPaymentMethodTypesOptions()
  const paymentProviderOptions = getPaymentProviderOptions()
  const isEdit = editCurrencyPaymentMethod !== undefined
  if (isEdit) {
    const paymentTypeOption = paymentMethodTypesOptions.find(
      (x) =>
        x.value === editCurrencyPaymentMethod.PaymentMethodTypeID.toString()
    )
    const debtorMerchantId =
      editCurrencyPaymentMethod.DebtorMerchantID === null
        ? ''
        : editCurrencyPaymentMethod.DebtorMerchantID?.toString()
    const debtorMerchantOption = debtorMerchants.find(
      (x) => x.value === debtorMerchantId
    )
    const paymentProviderOption = paymentProviderOptions.find(
      (x) =>
        x.value ===
        editCurrencyPaymentMethod.PaymentProviderConfigurationID.toString()
    )

    const isFixed = editCurrencyPaymentMethod.FixedTransactionCosts !== null

    myDefaultValues = {
      IsFixed: isFixed,
      PaymentMethodType: paymentTypeOption,
      DebtorMerchant: debtorMerchantOption,
      PaymentProvider: paymentProviderOption,
      TransactionCosts: isFixed
        ? transactionCostsToText(
            editCurrencyPaymentMethod.FixedTransactionCosts
          )
        : transactionCostsToText(
            editCurrencyPaymentMethod.PercentageTransactionCosts
          ),
    }
  }
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: myDefaultValues,
  })
  const { handleSubmit, watch, reset } = useFormProps

  const isFixed = watch('IsFixed')

  const renderTransactionCosts = () => {
    return (
      <MyTextbox
        useFormProps={useFormProps}
        formObject={{} as IFormValues}
        name="TransactionCosts"
        label={
          isFixed ? 'Vaste transactiekosten' : 'Percentage transactiekosten'
        }
        inputMask={InputMasks.Currency}
        rules={{
          required: isFixed
            ? 'Voer de vaste transactiekosten in'
            : 'Voer het percentage transactiekosten in',
        }}
      />
    )
  }

  const buttonText = isEdit ? t.common.edit : t.common.add

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={'PaymentMethodType'}
          name="PaymentMethodType"
          rules={{
            required: 'Selecteer een PaymentMethodType',
          }}
          options={paymentMethodTypesOptions}
        />
        <MyCheckbox
          formObject={{} as IFormValues}
          label={'Vaste transactiekosten?'}
          name="IsFixed"
          useFormProps={useFormProps}
        />
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={'Betaald door'}
          rules={{
            required: 'Selecteer een Betaald door',
          }}
          name="DebtorMerchant"
          options={debtorMerchants}
        />
        {renderTransactionCosts()}
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={'Payment Provider'}
          name="PaymentProvider"
          rules={{
            required: 'Selecteer een Payment Provider',
          }}
          options={getPaymentProviderOptions()}
        />
      </div>
      <div className="modal-footer">
        <ButtonToolbar className="pull-right">
          <McButton onClick={closeClicked}>{t.common.cancel}</McButton>
          <McButton bsStyle="primary" type="submit" isLoading={isLoading}>
            {buttonText}
          </McButton>
        </ButtonToolbar>
      </div>
    </form>
  )
}

export default memo<IProps>(CpmForm)
