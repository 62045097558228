import { observer } from 'mobx-react'
import React from 'react'
import McFormModal from 'src/Components/Modals/McFormModal'
import { t } from 'src/I18n'
import AddManagerUserForm from './AddManagerUserForm'
import FindUserForm from './FindUserForm'
import { addManagerUserStore, FormModes } from './Store'
interface IProps {
  onClose: () => void
  onSuccess: () => void
  isVisible: boolean
  merchantGuid: string
}

const AddManagerUserModal: React.FC<IProps> = ({
  onClose,
  onSuccess,
  merchantGuid,
  isVisible,
}) => {
  const { FormMode } = addManagerUserStore

  const renderForm = () => {
    if (FormMode === FormModes.FindUser) {
      return (
        <FindUserForm
          onSuccess={onSuccess}
          onClose={closeAndResetFormMode}
          merchantGuid={merchantGuid}
        />
      )
    }

    return (
      <AddManagerUserForm
        onSuccess={onSuccess}
        onClose={closeAndResetFormMode}
      />
    )
  }

  const closeAndResetFormMode = () => {
    onClose()
    addManagerUserStore.SetFormMode(FormModes.FindUser)
  }

  return (
    <McFormModal
      title={t.merchant.details.managerUsers.addUser}
      closeClicked={closeAndResetFormMode}
      isVisible={isVisible}
    >
      {renderForm()}
    </McFormModal>
  )
}

export default observer(AddManagerUserModal)
