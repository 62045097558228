import React, { memo } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import { MySlim } from 'src/Components/My'
import { t } from 'src/I18n'
import FileDTO from 'src/Models/Server/File/FileDTO'

interface IFormValues {
  ImageFile?: FileDTO
}

interface IProps {
  onSubmit: (data: IFormValues) => void
  isLoading: boolean
  closeClicked: () => void
  editItem?: FileDTO
}

const AppImageForm: React.FC<IProps> = ({
  closeClicked,
  onSubmit,
  isLoading,
  editItem,
}) => {
  const isEdit = editItem !== undefined

  const myDefaultValues: Partial<IFormValues> = {
    ImageFile: editItem == undefined ? null : editItem, //insert
  }

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: myDefaultValues,
  })
  const { handleSubmit } = useFormProps

  const buttonText = isEdit ? t.common.edit : t.common.add
  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <MySlim
            name="ImageFile"
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            label={t.savingcardInsert.fields.ImageFile.label}
            infoText={t.savingcardInsert.fields.ImageFile.info}
            rules={{
              required: t.savingcardInsert.fields.ImageFile.errors.required,
            }}
            sizeSlim={{ width: 580, height: 380 }}
          />
        </div>
        <div className="modal-footer">
          <ButtonToolbar className="pull-right">
            <McButton type="button" onClick={closeClicked}>
              {t.common.cancel}
            </McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isLoading}>
              {buttonText}
            </McButton>
          </ButtonToolbar>
        </div>
      </form>
    </>
  )
}

export default memo<IProps>(AppImageForm)
