import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
import ReportGroupMenu from '../../ReportGroupMenu'
import TopMenuListItem from './TopMenuListItem'
interface IProps {}

const ReportsMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore

  // SelectMany or flatmap
  const reportUrls = SelectedManagerUser.Reports.map((reportGroup) =>
    reportGroup.Reports.map((report) => report.Url)
  ).reduce((a, b) => {
    return a.concat(b)
  }, [])

  return (
    <TopMenuListItem
      title={t.topMenu.reports.title}
      urls={[
        '/report/paymentreport',
        '/report/payment/day/merchant',
        '/report/user/balances',
        '/report/currency/usage/day',
        '/report/currency/usage/week',
        '/report/currency/usage/month',
        ...reportUrls,
      ]}
    >
      {/* mega-menu-2  is een 2 columns breed menu  */}
      <ul className={'dropdown-menu mega-menu-2'} role="menu">
        <li>
          <div className="mega-menu-col">
            <ul className="mega-menu-menu">
              <li className="mega-menu-header">
                {t.topMenu.reports.payments.groupName}
              </li>
              <li>
                <NavLink to="/report/paymentreport">
                  <i className={`fa fa-table dropdown-icon`} />
                  {t.topMenu.reports.payments.paymentsPeriod}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/payment/day/merchant'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t.topMenu.reports.payments.paymentsDay}
                </NavLink>
              </li>
            </ul>
            <ul className="mega-menu-menu">
              <li className="mega-menu-header">
                {t.topMenu.reports.wallets.groupName}
              </li>
              <li>
                <NavLink to={'/report/user/balances'}>
                  <i className={`fa fa-table dropdown-icon`} />
                  {t.userBalances.title}
                </NavLink>
              </li>
            </ul>
            <ul className="mega-menu-menu"></ul>
          </div>
          <div className="mega-menu-col">
            <ul className="mega-menu-menu">
              <li className="mega-menu-header">{t.common.currencies}</li>
              <li>
                <NavLink to={'/report/currency/usage/day'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyUsage.title,
                      t.common.day.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/currency/usage/week'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyUsage.title,
                      t.common.week.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/currency/usage/month'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyUsage.title,
                      t.common.month.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/currency/charge/day'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyCharge.title,
                      t.common.day.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/currency/charge/week'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyCharge.title,
                      t.common.week.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/currency/charge/month'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t
                    .formatString(
                      t.currencyCharge.title,
                      t.common.month.toLocaleLowerCase()
                    )
                    .toString()}
                </NavLink>
              </li>
            </ul>
            {SelectedManagerUser.Reports.map((reportGroup, index) => (
              <ReportGroupMenu key={index} reportGroup={reportGroup} />
            ))}
          </div>
        </li>
      </ul>
    </TopMenuListItem>
  )
}

export default ReportsMenu
