import React, { useEffect } from 'react'
import 'bootstrap'
import { ToastContainer } from 'react-toastify'
import { observer } from 'mobx-react'
import { persistedStore } from './Stores/PersistedStore'
import accounting from 'accounting'
import 'react-toastify/dist/ReactToastify.css'
import './theme/mvp/admin/js/mvpready-admin'
import './theme/mvp/admin/css/mvpready-admin.css' //0
import './Style/style.scss' //1
import './Style/style.media.scss' //2
import './Tenants/Topr/style.scss' //4

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Tenant from './Tenants/Tenant'
import DashboardTest from './Screens/Authenticated/Dashboard/DashboardTest'
import Login from './Screens/Landing/Login/Login'
import ForgotPassword from './Screens/Landing/Login/ForgotPassword/ForgotPassword'
import ResetPassword from './Screens/Landing/Login/ResetPassword/ResetPassword'
import Merchants from './Screens/Authenticated/Merchant/Index/Merchants'
import MerchantInsert from './Screens/Authenticated/Merchant/Insert/Insert'
import MerchantDetails from './Screens/Authenticated/Merchant/Details/Details'
import LocationInsert from './Screens/Authenticated/Location/Insert/Insert'
import LocationDetails from './Screens/Authenticated/Location/Details/Details'
import PosDetails from './Screens/Authenticated/Pos/Details/Details'
import PosInsert from './Screens/Authenticated/Pos/Insert/Insert'
import TerminalInsert from './Screens/Authenticated/Terminal/Insert/Insert'
import TerminalDetails from './Screens/Authenticated/Terminal/Details/Details'
import HelpdeskUsers from './Screens/Authenticated/Helpdesk/Users/Index/Users'
import BulkChange from './Screens/Authenticated/Wallet/BulkChange/BulkChange'
import AutomaticChargeInsert from './Screens/Authenticated/Helpdesk/AutomaticCharge/Insert/Insert'
import AutomaticChargeEdit from './Screens/Authenticated/Helpdesk/AutomaticCharge/Edit/Edit'
import Coupons from './Screens/Authenticated/Coupons/Index/Coupons'
import Dashboard from './Screens/Authenticated/Dashboard/Dashboard'
import FileInsert from './Screens/Authenticated/File/Insert/Insert'
import FileEdit from './Screens/Authenticated/File/Edit/Edit'
import FileDetails from './Screens/Authenticated/File/Details/Details'
import MerchantPayments from './Screens/Authenticated/Finance/MerchantPayments/Index/MerchantPayments'
import MerchantPaymentDetails from './Screens/Authenticated/Finance/MerchantPayments/Details/MerchantPaymentDetails'
import MerchantRefunds from './Screens/Authenticated/Finance/MerchantRefunds/Index/MerchantRefunds'
import MerchantRefundDetails from './Screens/Authenticated/Finance/MerchantRefunds/Details/Details'
import MontlyTransactionTotals from './Screens/Authenticated/Finance/MontlyTransactionTotals/MontlyTransactionTotals'
import StornoStatus from './Screens/Authenticated/Finance/Stornos/StornoStatus/StornoStatus'
import UploadStornos from './Screens/Authenticated/Finance/Stornos/UploadStornos/UploadStornos'
import LoyaltyPrograms from './Screens/Authenticated/LoyaltyProgram/Index/LoyaltyPrograms'
import LoyaltyProgramInsert from './Screens/Authenticated/LoyaltyProgram/Insert/Insert'
import LoyaltyProgramDetails from './Screens/Authenticated/LoyaltyProgram/Details/Details'
import CardPaymentTotals from './Screens/Authenticated/Reports/Payments/CardPaymentTotals/CardPaymentTotals'
import PaymentsPerDayMerchants from './Screens/Authenticated/Reports/Payments/PaymentsPerDay/Merchants/PaymentsPerDayMerchants'
import PaymentsPerDayDetails from './Screens/Authenticated/Reports/Payments/PaymentsPerDay/PaymentsPerDayDetails/PaymentsPerDayDetails'
import PaymentsReport from './Screens/Authenticated/Reports/Payments/PaymentsReport/PaymentsReport'
import PaymentsTokenType from './Screens/Authenticated/Reports/Payments/PaymentsTokenType/PaymentsTokenType'
import BearbeitungsGebuhr from './Screens/Authenticated/Reports/Siedler/BearbeitungsGebuhr/BearbeitungsGebuhr'
import GebuhrKnodels from './Screens/Authenticated/Reports/Siedler/GebuhrKnodels/GebuhrKnodels'
import InvoiceCoupons from './Screens/Authenticated/Reports/Siedler/InvoiceCoupons/InvoiceCoupons'
import CompanyCoupons from './Screens/Authenticated/Reports/Siedler/CompanyCoupons/CompanyCoupons'
import ChargeGuthaben from './Screens/Authenticated/Reports/Siedler/ChargeGuthaben/ChargeGuthaben'
import InvoiceCurrency from './Screens/Authenticated/Reports/Siedler/InvoiceCurrency/InvoiceCurrency'

import UserBalances from './Screens/Authenticated/Reports/Wallet/UserBalances/UserBalances'
import SavingCards from './Screens/Authenticated/SavingCard/Index/SavingCards'
import SavingCardInsert from './Screens/Authenticated/SavingCard/Insert/Insert'
import SavingCardDetails from './Screens/Authenticated/SavingCard/Details/Details'
import SavingCardEdit from './Screens/Authenticated/SavingCard/Edit/Edit'
import SiedlerCompanies from './Screens/Authenticated/Siedler/Companies/Companies'
import TransactionSearch from './Screens/Authenticated/Helpdesk/Transaction/Transaction'
import SepaFile from './Screens/Authenticated/Finance/SepaFile/SepaFile'
import UserScheduleBonusInsert from './Screens/Authenticated/UserScheduleBonus/Insert/Insert'
import UserScheduleBonusEdit from './Screens/Authenticated/UserScheduleBonus/Edit/Edit'
import Subscriptions from './Screens/Authenticated/Subscription/Index/Subscriptions'
import SubscriptionInsert from './Screens/Authenticated/Subscription/Insert/SubscriptionInsert'
import SubscriptionInsertItem from './Screens/Authenticated/Subscription/InsertItem/InsertItem'
import SubscriptionEditItem from './Screens/Authenticated/Subscription/EditItem/EditItem'
import EventInsert from './Screens/Authenticated/Event/Insert/EventInsert'
import EventEdit from './Screens/Authenticated/Event/Edit/EventEdit'
import EventDetails from './Screens/Authenticated/Event/Details/EventDetails'
import EventIndex from './Screens/Authenticated/Event/Index/EventIndex'
import EventTicketInsert from './Screens/Authenticated/EventTicket/Insert/EventTicketInsert'
import EventTicketEdit from './Screens/Authenticated/EventTicket/Edit/EventTicketEdit'
import ChargeReport from './Screens/Authenticated/Reports/Currency/ChargeReport'
import Boot from './Screens/Landing/Boot/Boot'
import NoMatch from './Screens/NoMatch'

import { RequireAuthentication } from './Components/My'
import TestSignalR from './Screens/Test/SIgnalR/TestSignalR'
import { ConfirmModal, ErrorModal } from './Components/Modals'
import { appUiStore } from './Stores/AppUiStore'
import Test from './Screens/Test/Test'
import UserDetails from './Screens/Authenticated/Helpdesk/Users/Details/UserDetails'
import { Provider } from 'react-redux'
import { rootStore } from 'src/Stores/Redux/RootStore'
import InsertUserSubscription from './Screens/Authenticated/Subscription/User/Insert/InsertUserSubscription'
import EditUserSubscription from './Screens/Authenticated/Subscription/User/Edit/EditUserSubscription'
import SubscriptionEdit from './Screens/Authenticated/Subscription/Edit/SubscriptionEdit'
import TagIndex from './Screens/Authenticated/Tag/Index/TagIndex'
import AddUserCoupon from './Screens/Authenticated/Coupons/AddUserCoupons/AddUserCoupons'
import AddConsumerByToken from './Screens/Authenticated/Merchant/AddConsumerByToken/AddConsumerByToken'
import WalletCharge from './Screens/Authenticated/Wallet/WalletCharge/WalletCharge'
import WalletWithdraw from './Screens/Authenticated/Wallet/WalletWithdraw/WalletWithdraw'
import Currencies from './Screens/Authenticated/Currency/Currencies'
import CurrencyInsert from './Screens/Authenticated/Currency/CurrencyInsert'
import CurrencyDetails from './Screens/Authenticated/Currency/Details/CurrencyDetails'
import CurrencyUsageDayReport from './Screens/Authenticated/Reports/Currency/Usage/Day/CurrencyUsageDayReport'
import CurrencyUsageWeekReport from './Screens/Authenticated/Reports/Currency/Usage/Week/CurrencyUsageWeekReport'
import CurrencyUsageMonthReport from './Screens/Authenticated/Reports/Currency/Usage/Month/CurrencyUsageMonthReport'
import CurrencyChargeMonthReport from './Screens/Authenticated/Reports/Currency/Charge/Month/CurrencyChargeMonthReport'
import CurrencyChargeDayReport from './Screens/Authenticated/Reports/Currency/Charge/Day/CurrencyChargeDayReport'
import CurrencyChargeWeekReport from './Screens/Authenticated/Reports/Currency/Charge/Week/CurrencyChargeWeekReport'
import CurrencyEdit from './Screens/Authenticated/Currency/CurrencyEdit'

accounting.settings = {
  currency: {
    symbol: '€', // default currency symbol is '$'
    format: '%s %v', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ',', // decimal point separator
    thousand: '.', // thousands separator
    precision: 2, // decimal places
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
}

const App: React.FC = () => {
  const bodyOnClick = () => {
    appUiStore.ToggleOpenTopMenu(undefined)
  }
  useEffect(() => {
    document.body.className = `${Tenant.SystemName.toLowerCase()}`
    document.body.addEventListener('click', bodyOnClick)

    return () => {
      document.body.removeEventListener('click', bodyOnClick)
    }
  })

  const { IsHydrated } = persistedStore

  if (!IsHydrated) {
    return null
  }

  return (
    <>
      <Provider store={rootStore}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/test/*" element={<Test />} /> */}
            <Route path="/boot" element={<Boot />} />
            <Route path="/env" element={<Boot />} />
            <Route
              path="/test"
              element={
                <RequireAuthentication>
                  <Test />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler/companies"
              element={
                <RequireAuthentication>
                  <SiedlerCompanies />
                </RequireAuthentication>
              }
            />
            <Route
              path="/savingcard/edit/:id"
              element={
                <RequireAuthentication>
                  <SavingCardEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/savingcard/details/:id"
              element={
                <RequireAuthentication>
                  <SavingCardDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/savingcard/insert"
              element={
                <RequireAuthentication>
                  <SavingCardInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/savingcards"
              element={
                <RequireAuthentication>
                  <SavingCards />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/user/balances"
              element={
                <RequireAuthentication>
                  <UserBalances />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/invoice-currency"
              element={
                <RequireAuthentication>
                  <InvoiceCurrency />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/invoice-coupons"
              element={
                <RequireAuthentication>
                  <InvoiceCoupons />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/unternehmen-coupons"
              element={
                <RequireAuthentication>
                  <CompanyCoupons />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/charge-guthaben"
              element={
                <RequireAuthentication>
                  <ChargeGuthaben />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/gebuhr-knodels"
              element={
                <RequireAuthentication>
                  <GebuhrKnodels />
                </RequireAuthentication>
              }
            />
            <Route
              path="/siedler-report/bearbeitungsgebuhr"
              element={
                <RequireAuthentication>
                  <BearbeitungsGebuhr />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/payments/tokentype"
              element={
                <RequireAuthentication>
                  <PaymentsTokenType />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/paymentreport"
              element={
                <RequireAuthentication>
                  <PaymentsReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/payment/day/merchant/:merchantId/:currencyId/:month"
              element={
                <RequireAuthentication>
                  <PaymentsPerDayDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/usage/day"
              element={
                <RequireAuthentication>
                  <CurrencyUsageDayReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/usage/week"
              element={
                <RequireAuthentication>
                  <CurrencyUsageWeekReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/usage/month"
              element={
                <RequireAuthentication>
                  <CurrencyUsageMonthReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/charge/day"
              element={
                <RequireAuthentication>
                  <CurrencyChargeDayReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/charge/week"
              element={
                <RequireAuthentication>
                  <CurrencyChargeWeekReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/currency/charge/month"
              element={
                <RequireAuthentication>
                  <CurrencyChargeMonthReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/payment/day/merchant"
              element={
                <RequireAuthentication>
                  <PaymentsPerDayMerchants />
                </RequireAuthentication>
              }
            />
            <Route
              path="/report/cardpaymenttotals"
              element={
                <RequireAuthentication>
                  <CardPaymentTotals />
                </RequireAuthentication>
              }
            />
            <Route
              path="/loyaltyprogram/details/:id"
              element={
                <RequireAuthentication>
                  <LoyaltyProgramDetails />
                </RequireAuthentication>
              }
            />
            <Route path="/" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:id" element={<ResetPassword />} />
            <Route
              path="/merchants"
              element={
                <RequireAuthentication>
                  <Merchants />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchant/insert"
              element={
                <RequireAuthentication>
                  <MerchantInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchant/details/:id"
              element={
                <RequireAuthentication>
                  <MerchantDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/location/insert/:id"
              element={
                <RequireAuthentication>
                  <LocationInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/location/details/:id"
              element={
                <RequireAuthentication>
                  <LocationDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/pos/details/:posGuid"
              element={
                <RequireAuthentication>
                  <PosDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/user/details/:id/*"
              element={
                <RequireAuthentication>
                  <UserDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/pos/insert/:locationGuid/:merchantGuid/:locationName"
              element={
                <RequireAuthentication>
                  <PosInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/terminal/details/:terminalId"
              element={
                <RequireAuthentication>
                  <TerminalDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/terminal/insert/:posGuid/:posName"
              element={
                <RequireAuthentication>
                  <TerminalInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/helpdesk/users"
              element={
                <RequireAuthentication>
                  <HelpdeskUsers />
                </RequireAuthentication>
              }
            />
            <Route
              path="/transaction/search"
              element={
                <RequireAuthentication>
                  <TransactionSearch />
                </RequireAuthentication>
              }
            />

            <Route
              path="/wallets/bulkchange"
              element={
                <RequireAuthentication>
                  <BulkChange />
                </RequireAuthentication>
              }
            />
            <Route
              path="/automaticcharge/insert/:id/:email"
              element={
                <RequireAuthentication>
                  <AutomaticChargeInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/automaticcharge/edit/:id/:userGuid"
              element={
                <RequireAuthentication>
                  <AutomaticChargeEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/vouchers"
              element={
                <RequireAuthentication>
                  <Coupons />
                </RequireAuthentication>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuthentication>
                  <Dashboard />
                </RequireAuthentication>
              }
            />
            <Route
              path="/file/insert"
              element={
                <RequireAuthentication>
                  <FileInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/file/details/:id"
              element={
                <RequireAuthentication>
                  <FileDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/file/edit/:id"
              element={
                <RequireAuthentication>
                  <FileEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/finance/merchantpayments"
              element={
                <RequireAuthentication>
                  <MerchantPayments />
                </RequireAuthentication>
              }
            />
            <Route
              path="/finance/sepafile/generate"
              element={
                <RequireAuthentication>
                  <SepaFile />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchantpayment/:id"
              element={
                <RequireAuthentication>
                  <MerchantPaymentDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchantrefunds"
              element={
                <RequireAuthentication>
                  <MerchantRefunds />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchantrefunds/details/:id"
              element={
                <RequireAuthentication>
                  <MerchantRefundDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/finance/montlytransactiontotals"
              element={
                <RequireAuthentication>
                  <MontlyTransactionTotals />
                </RequireAuthentication>
              }
            />
            <Route
              path="/finance/stornostatus"
              element={
                <RequireAuthentication>
                  <StornoStatus />
                </RequireAuthentication>
              }
            />
            <Route
              path="/finance/uploadstornos"
              element={
                <RequireAuthentication>
                  <UploadStornos />
                </RequireAuthentication>
              }
            />
            <Route
              path="/loyaltyprograms"
              element={
                <RequireAuthentication>
                  <LoyaltyPrograms />
                </RequireAuthentication>
              }
            />
            <Route
              path="/loyaltyprogram/insert"
              element={
                <RequireAuthentication>
                  <LoyaltyProgramInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/user/schedule/bonus/insert/:userGuid"
              element={
                <RequireAuthentication>
                  <UserScheduleBonusInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/user/schedule/bonus/edit/:userGuid/:userScheduleBonusId"
              element={
                <RequireAuthentication>
                  <UserScheduleBonusEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <RequireAuthentication>
                  <Subscriptions />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/insert"
              element={
                <RequireAuthentication>
                  <SubscriptionInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/:id/edit"
              element={
                <RequireAuthentication>
                  <SubscriptionEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/:id/insert-item"
              element={
                <RequireAuthentication>
                  <SubscriptionInsertItem />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/edit-item/:consumerSubscriptionItemId"
              element={
                <RequireAuthentication>
                  <SubscriptionEditItem />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/user/:userGuid/insert/:consumerSubscriptionId"
              element={
                <RequireAuthentication>
                  <InsertUserSubscription />
                </RequireAuthentication>
              }
            />
            <Route
              path="/subscription/user/:userGuid/edit/:consumerSubscriptionId/:userSubscriptionId"
              element={
                <RequireAuthentication>
                  <EditUserSubscription />
                </RequireAuthentication>
              }
            />
            <Route
              path="/event/insert"
              element={
                <RequireAuthentication>
                  <EventInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/event/edit/:id"
              element={
                <RequireAuthentication>
                  <EventEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/event/details/:id"
              element={
                <RequireAuthentication>
                  <EventDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/eventTicket/:eventId/insert"
              element={
                <RequireAuthentication>
                  <EventTicketInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/eventTicket/:eventId/edit/:id"
              element={
                <RequireAuthentication>
                  <EventTicketEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/events"
              element={
                <RequireAuthentication>
                  <EventIndex />
                </RequireAuthentication>
              }
            />
            <Route
              path="/tags"
              element={
                <RequireAuthentication>
                  <TagIndex />
                </RequireAuthentication>
              }
            />
            <Route
              path="/usercoupon/add"
              element={
                <RequireAuthentication>
                  <AddUserCoupon />
                </RequireAuthentication>
              }
            />
            <Route
              path="/merchant/consumer/add"
              element={
                <RequireAuthentication>
                  <AddConsumerByToken />
                </RequireAuthentication>
              }
            />
            <Route
              path="/wallet/charge"
              element={
                <RequireAuthentication>
                  <WalletCharge />
                </RequireAuthentication>
              }
            />
            <Route
              path="/wallet/charge/:currencyId"
              element={
                <RequireAuthentication>
                  <WalletCharge />
                </RequireAuthentication>
              }
            />
            <Route
              path="/wallet/withdraw"
              element={
                <RequireAuthentication>
                  <WalletWithdraw />
                </RequireAuthentication>
              }
            />
            <Route
              path="/wallet/withdraw/:currencyId"
              element={
                <RequireAuthentication>
                  <WalletWithdraw />
                </RequireAuthentication>
              }
            />
            <Route
              path="/currencies"
              element={
                <RequireAuthentication>
                  <Currencies />
                </RequireAuthentication>
              }
            />
            <Route
              path="/currency/insert"
              element={
                <RequireAuthentication>
                  <CurrencyInsert />
                </RequireAuthentication>
              }
            />
            <Route
              path="/currency/:currencyId/edit"
              element={
                <RequireAuthentication>
                  <CurrencyEdit />
                </RequireAuthentication>
              }
            />
            <Route
              path="/currency/:currencyId/details"
              element={
                <RequireAuthentication>
                  <CurrencyDetails />
                </RequireAuthentication>
              }
            />
            <Route path="dbtest" element={<DashboardTest />} />
            <Route path="signalr" element={<TestSignalR />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
      <ConfirmModal
        confirmMessage={appUiStore.ConfirmMessage}
        isConfirming={appUiStore.IsConfirming}
        closeClicked={() => appUiStore.HideConfirm()}
      />
      <ErrorModal
        error={appUiStore.Error}
        closeClicked={() => appUiStore.HideError()}
      />
    </>
  )
}

export default observer(App)
