import React from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'src/I18n'
import ReportGroupVTO from 'src/Models/Server/Response/Report/ReportGroupVTO'
interface IReportGroupMenuProps {
  reportGroup: ReportGroupVTO
}

const ReportGroupMenu: React.FC<IReportGroupMenuProps> = ({ reportGroup }) => {
  return (
    <ul className="mega-menu-menu">
      <li className="mega-menu-header">{reportGroup.GroupName}</li>
      {reportGroup.Reports.map((report, index) => {
        const translatedName =
          t.topMenu.reports.managerReports[report.SystemGroupName][
            report.SystemName
          ]

        return (
          <li key={index}>
            <NavLink to={report.Url}>
              <i className={`fa fa-${report.Icon} dropdown-icon`} />
              {translatedName}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export default ReportGroupMenu
