import React from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'src/I18n'
import TopMenuListItem from './TopMenuListItem'
interface IProps {}

const CustomerSearchMenu: React.FC<IProps> = () => {
  return (
    <TopMenuListItem
      title={t.common.consumers}
      urls={['/helpdesk/users', '/transaction/search', 'merchant/consumer/add']}
    >
      <ul className="dropdown-menu">
        <li>
          <NavLink to={'/helpdesk/users'}>
            <i className={`fa fa-search dropdown-icon`} />
            {t.topMenu.consumers.helpdesk}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/transaction/search'}>
            <i className={`fa fa-search dropdown-icon`} />
            {t.transaction.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/merchant/consumer/add'}>
            <i className={`fa fa-user-plus dropdown-icon`} />
            {t.merchant.addConsumerUser.title}
          </NavLink>
        </li>
      </ul>
    </TopMenuListItem>
  )
}

export default CustomerSearchMenu
