export default {
  DATA_NO_RESULT: 'DATA_NO_RESULT',
  REGISTRATION_FINISHED: 'REGISTRATION_FINISHED',
  REGISTRATION_NOT_FOUND: 'REGISTRATION_NOT_FOUND',
  USER_LOCKED_LONG: 'USER_LOCKED_LONG',
  USER_LOCKED_SHORT: 'USER_LOCKED_SHORT',
  USER_LOGIN_EMAIL_NOT_FOUND: 'USER_LOGIN_EMAIL_NOT_FOUND',
  USER_PASSWORD_INVALID: 'USER_PASSWORD_INVALID',
  USER_RESET_LINK_NOT_FOUND: 'USER_RESET_LINK_NOT_FOUND',
  USER_EMAIL_EXISTS: 'USER_EMAIL_EXISTS',
  USER_NO_ROLE: 'USER_NO_ROLE',
  USER_NO_VALID_SUBSCRIPTION: 'USER_NO_VALID_SUBSCRIPTION',
  DATA_MORE_THEN_1_RESULT: 'DATA_MORE_THEN_1_RESULT', //Duplicate Key , insert role
}
