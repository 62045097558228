import { Location as ReactRouterLocation, useMatch } from 'react-router-dom'
export const getlocationState = <T>(location: ReactRouterLocation) => {
  return location.state as T
}

export interface IStaticUrlProp {
  Path: string
  GetUrl(...args: string[])
}

export const Json2Url = (obj, prefix?: string) => {
  const str = []
  let p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p
      const v = obj[p]
      const result =
        v !== null && typeof v === 'object'
          ? Json2Url(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)

      str.push(result)
      // str.push(
      //   v !== null && typeof v === 'object'
      //     ? Json2Url(v, k)
      //     : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      // )
    }
  }

  return str.join('&')
}

export const hasMatchedChildren = (...urls: string[]) => {
  for (const url of urls) {
    const match = useMatch(url)
    if (match !== null) {
      return true
    }
  }

  return false
}
