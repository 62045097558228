import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import { ICreateCurrencyRequest } from 'src/Models/Server/Request/Currency/ICreateCurrencyRequest'
import { useCreateCurrencyMutation } from 'src/Services/ToprCoreApi/RTK/Slices/CurrencySlice'
import CurrencyForm from './Components/CurrencyForm'
interface IProps {}

const CurrencyInsert: React.FC<IProps> = () => {
  const [createCurrency, { isLoading, isError, error }] =
    useCreateCurrencyMutation()

  const navigate = useNavigate()

  //currencycode error
  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.common.currency}
          previousPages={[
            {
              to: `/currencies`,
              title: t.common.currencies,
            },
          ]}
        />
        <CurrencyForm
          isSubmitting={isLoading}
          onSubmit={(data) => {
            const request: ICreateCurrencyRequest = {
              ...data,
              MerchantId: data.Merchant.value,
              CurrencyPayoutTypeID: data.CurrencyPayoutType.value,
              BalanceCheckTypeID: data.BalanceCheckType.value,
            }

            createCurrency(request)
              .unwrap()
              .then((currencyId) => {
                toast.success('Betaalmiddel toegevoegd.')
                navigate(`/currency/${currencyId}/details`)
              })
          }}
        />
      </MainPage>
    </>
  )
}

export default CurrencyInsert
