/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserCouponStatus } from 'src/Models/Server/Enums'
import { ILocalizedStringSto } from 'src/Models/Server/Response/LocalizedString/ILocalizedStringSto'
import { ILocalizedStringVTO } from 'src/Models/Server/Response/LocalizedString/ILocalizedStringVTO'
import { LocalizedStringDTO } from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import {
  ITokenSTO,
  getTokenStatus,
} from 'src/Models/Server/Response/Token/ITokenSTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import { GetLocalText } from './TextHelpers'

export const sortDate = (
  order: string,
  rowA: any,
  rowB: any,
  dateField: string
) => {
  const dateA = Date.parse(rowA[dateField] as string)
  const dateB = Date.parse(rowB[dateField] as string)
  if (order === 'asc') {
    return dateA - dateB
  } else {
    return dateB - dateA
  }
}

export const sortUserCouponStatus = (
  order: string,
  rowA: UserCouponStatus,
  rowB: UserCouponStatus
) => {
  if (order === 'asc') {
    return rowA - rowB
  } else {
    return rowB - rowA
  }
}

export const sortlocalizedString = (
  order: string,
  rowA: ILocalizedStringSto | ILocalizedStringVTO,
  rowB: ILocalizedStringSto | ILocalizedStringVTO
) => {
  const textA = GetLocalText(rowA)
  const textB = GetLocalText(rowB)
  if (order === 'asc') {
    return textA.localeCompare(textB)
  } else {
    return textB.localeCompare(textA)
  }
}

export const filterLocalizedString = (
  filterValue: string,
  localizedString: ILocalizedStringSto | ILocalizedStringVTO
) => {
  const lang = persistedStore.Language.toUpperCase()
  if (
    localizedString === undefined ||
    localizedString === null ||
    localizedString.Values === undefined ||
    localizedString.Values === null
  ) {
    return null
  }
  return (
    localizedString.Values.findIndex(
      (lsv) =>
        lsv.Language === lang &&
        lsv.Value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    ) > -1
  )
}

export const filterLocalizedStringDTO = (
  filterValue: string,
  localizedString: LocalizedStringDTO
) => {
  const lang = persistedStore.Language.toUpperCase()
  if (
    localizedString === undefined ||
    localizedString === null ||
    localizedString.LocalizedStringValues === undefined ||
    localizedString.LocalizedStringValues === null
  ) {
    return null
  }
  return (
    localizedString.LocalizedStringValues.findIndex(
      (lsv) =>
        lsv.LanguageId === lang &&
        lsv.Value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    ) > -1
  )
}

export const sortCurrencyAmount = (
  order: string,
  dataField: string,
  rowA: any,
  rowB: any
) => {
  const deFormat = (text: string) => {
    if (text === null || text === undefined) {
      return 0
    }

    //remove any $ or €
    const splitted = text.split(' ')
    const noCurrencyChar = splitted[splitted.length - 1]

    //remove any . or ,
    const removedComma = noCurrencyChar.replace(', ', '').replace('.', '')

    return parseInt(removedComma, 10)
    //remove any currency
  }

  const amountA = deFormat(eval(`rowA.${dataField}`) as string)
  const amountB = deFormat(eval(`rowB.${dataField}`) as string)

  if (order === 'asc') {
    return amountA - amountB
  } else {
    return amountB - amountA
  }
}

export const sortDefault = (
  order: string,
  dataField: string,
  rowA: any,
  rowB: any
) => {
  const textA = eval(`rowA.${dataField}`)
  const textB = eval(`rowB.${dataField}`)

  const sortNumber = () => {
    if (order === 'asc') {
      return textA - textB
    } else {
      return textB - textA
    }
  }

  const sortText = () => {
    if (order === 'asc') {
      return textA?.localeCompare(textB)
    } else {
      return textB?.localeCompare(textA)
    }
  }

  const sortBoolean = () => {
    const boolA = textA?.toString()
    const boolB = textB?.toString()

    if (order === 'asc') {
      return boolA.localeCompare(boolB)
    } else {
      return boolB.localeCompare(boolA)
    }
  }

  const type = typeof textA

  switch (type) {
    case 'number':
      return sortNumber()
    case 'string':
      return sortText()
    case 'boolean':
      return sortBoolean()
    default:
      return 0 //invalid type no sort
  }
}

export const sortTokenStatus = (
  order: string,
  rowA: ITokenSTO,
  rowB: ITokenSTO
  //dateField: string
) => {
  const a = getTokenStatus(rowA)
  const b = getTokenStatus(rowB)
  if (order === 'asc') {
    return a - b
  } else {
    return b - a
  }
}

export const sortNumber = (
  order: string,
  rowA: any,
  rowB: any,
  dateField: string
) => {
  const numberA = rowA[dateField]
  const numberB = rowB[dateField]
  if (order === 'asc') {
    return numberA - numberB
  } else {
    return numberB - numberA
  }
}
