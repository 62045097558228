import ICreateCurrencyPaymentRequest from 'src/Models/Server/Request/CurrencyPaymentMethod/ICreateCurrencyPaymentRequest'
import IEditCurrencyPaymentRequest from 'src/Models/Server/Request/CurrencyPaymentMethod/IEditCurrencyPaymentRequest'
import { ICurrencyPaymentMethod } from 'src/Models/Server/Response/Currency/ICurrencyPaymentVTO'
import { toprCoreApi } from '../ToprCoreApi'

const currencyPaymentMethodSlice = toprCoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyPaymentMethods: builder.query<ICurrencyPaymentMethod[], string>({
      query: (currencyId) => `/api/v1/currency/${currencyId}/paymentmethods`,
      providesTags: ['CurrencyPaymentMethod'],
    }),
    createCurrencyPaymentMethod: builder.mutation<
      void,
      { request: ICreateCurrencyPaymentRequest; currencyId: number }
    >({
      query: ({ request, currencyId }) => ({
        url: `/api/v1/currency/${currencyId}/paymentmethod/insert`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['CurrencyPaymentMethod'],
    }),
    deleteCurrencyPaymentMethod: builder.mutation<void, number>({
      query: (currencyPaymentMethodId) => ({
        url: `/api/v1/currency/paymentmethod/${currencyPaymentMethodId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CurrencyPaymentMethod'],
    }),
    editCurrencyPaymentMethod: builder.mutation<
      void,
      IEditCurrencyPaymentRequest
    >({
      query: (request) => ({
        url: `/api/v1/currency/paymentmethod/edit`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['CurrencyPaymentMethod'],
    }),
    //
  }),
})

export const {
  useGetCurrencyPaymentMethodsQuery,
  useCreateCurrencyPaymentMethodMutation,
  useEditCurrencyPaymentMethodMutation,
  useDeleteCurrencyPaymentMethodMutation,
} = currencyPaymentMethodSlice
