import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { GetLocalTextDTO } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { ICreateCurrencyRequest } from 'src/Models/Server/Request/Currency/ICreateCurrencyRequest'
import { IEditCurrencyRequest } from 'src/Models/Server/Request/Currency/IEditCurrencyRequest'
import {
  useCreateCurrencyMutation,
  useEditCurrencyMutation,
  useGetCurrencyQuery,
} from 'src/Services/ToprCoreApi/RTK/Slices/CurrencySlice'
import CurrencyForm from './Components/CurrencyForm'
interface IProps {}

const CurrencyEdit: React.FC<IProps> = () => {
  const params = useParams<'currencyId'>()

  const { data: currency, isLoading } = useGetCurrencyQuery(params.currencyId)

  const currencyName = `${GetLocalTextDTO(currency?.Description)} (${
    currency?.Code
  })`
  //console.log('cur', currency)

  //TODO editCurrency
  const [editCurrency, { isLoading: isSubmitting, isError, error }] =
    useEditCurrencyMutation()

  const navigate = useNavigate()

  //currencycode error
  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.common.edit}
          previousPages={[
            {
              to: `/currencies`,
              title: t.common.currencies,
            },
            {
              to: `/currency/${params.currencyId}/details`,
              title: currencyName,
            },
          ]}
        />
        <CurrencyForm
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          editItem={currency}
          onSubmit={(data) => {
            const request: IEditCurrencyRequest = {
              ...data,
              //MerchantId: data.Merchant.value,
              CurrencyPayoutTypeID: data.CurrencyPayoutType.value,
              BalanceCheckTypeID: data.BalanceCheckType.value,
            }
            editCurrency({ request, currencyId: params.currencyId })
              .unwrap()
              .then(() => {
                toast.success('Betaalmiddel gewijzigd.')
                navigate(`/currency/${params.currencyId}/details`)
              })
            //todo
            // .catch((error: IGenericError) => {
            //   appUiStore.ShowError(error)
            //   runInAction(() => {
            //     this.IsSubmitting = false
            //   })
            //   this.CloseTenantSelect()
            // })
          }}
        />
      </MainPage>
    </>
  )
}

export default CurrencyEdit
