import React, { Component, PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'

interface IMcButtonProps extends Button.ButtonProps {
  isLoading?: boolean
  icon?: string
  iconLeft?: string
  bsStyle?:
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'link'
    | string
  pullRight?: boolean
}
//<i className="fa fa-play-circle" />
export default class McButton extends Component<
  PropsWithChildren<IMcButtonProps>
> {
  public render() {
    const {
      isLoading,
      children,
      disabled,
      pullRight,
      iconLeft,
      ...otherProps
    } = this.props

    const disabledFromProps = isLoading || disabled

    const renderChildren = () => {
      if (isLoading) {
        return <i className="fa fa-sync fa-spin " />
      }

      return (
        <>
          {this.renderIconLeft()} {children} {this.renderIcon()}
        </>
      )
    }

    return (
      <Button
        {...otherProps}
        disabled={disabledFromProps}
        className={pullRight ? 'pull-right' : ''}
      >
        {renderChildren()}
      </Button>
    )
  }

  private renderIcon() {
    if (this.props.icon) {
      return <i className={`fa ${this.props.icon}`} />
    }

    return null
  }

  private renderIconLeft() {
    if (this.props.iconLeft) {
      return <i className={`fa ${this.props.iconLeft}`} />
    }

    return null
  }
}
