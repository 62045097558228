import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Card, LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable } from 'src/Components/My'
import { getItemTypeText, GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { GetFileUrl } from 'src/Images'
import { IColumn } from 'src/Models/Client'
import { IConsumerSubscriptionSto } from 'src/Models/Server/Response/ConsumerSubscription/IConsumerSubscriptionSto'
import {
  useDeleteConsumerSubscriptionItemMutation,
  useGetConsumerSubscriptionsQuery,
} from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import { appUiStore } from 'src/Stores/AppUiStore'

const SubscriptionsPortlet: React.FC = () => {
  const navigate = useNavigate()

  const {
    data: subscriptions,
    isLoading,
    refetch,
  } = useGetConsumerSubscriptionsQuery()

  const [deleteItem] = useDeleteConsumerSubscriptionItemMutation()

  if (isLoading || subscriptions === undefined) {
    return <LoaderIcon IsLoading={isLoading} />
  }

  const renderSubscriptionTable = () => {
    const columns: IColumn<IConsumerSubscriptionSto>[] = [
      {
        dataField: 'Name',
        text: t.common.name,
        formatter: (data: IConsumerSubscriptionSto) => {
          return (
            <>
              <Row>
                <Col md={6}>
                  <h3>
                    {GetLocalText(data.Name)}
                    <McButton
                      iconLeft="fa-edit"
                      bsStyle="link"
                      bsSize="lg"
                      title={t.common.edit}
                      onClick={() =>
                        navigate(
                          `/subscription/${data.ConsumerSubscriptionId}/edit`
                        )
                      }
                    ></McButton>
                  </h3>
                </Col>
                <Col md={6}>
                  <McButton
                    iconLeft="fa-plus"
                    bsStyle="primary"
                    bsSize="xs"
                    title={t.subscription.insertItem.title}
                    pullRight
                    onClick={() =>
                      navigate(
                        `/subscription/${data.ConsumerSubscriptionId}/insert-item/`
                      )
                    }
                  >
                    {t.subscription.insertItem.title}
                  </McButton>
                </Col>
              </Row>
              <Row>
                {data.ConsumerSubscriptionItems.map((csi, key) => {
                  const subText = getItemTypeText(csi)
                  const renderDeleteLink = () => {
                    if (csi.HasUserSubscriptions) {
                      return null
                    }

                    return (
                      <a
                        className="danger"
                        onClick={() => {
                          appUiStore.ShowConfirm({
                            message: t
                              .formatString(
                                t.subscription.index.confirmDelete,
                                {
                                  name: GetLocalText(csi.Name),
                                  subscriptionName: GetLocalText(data.Name),
                                }
                              )
                              .toString(),
                            confirmActionText: t.common.delete,
                            confirmAction: () => {
                              deleteItem(csi.ConsumerSubscriptionItemId)
                                .unwrap()
                                .then(() => refetch())
                                .catch((rejected) => console.error(rejected))
                            },
                          })
                        }}
                      >
                        {t.common.delete}
                      </a>
                    )
                  }
                  return (
                    <Col md={4} key={key}>
                      <Card>
                        <Card.CardImageHeader
                          imageUrl={GetFileUrl(csi.ImageFileGuid)}
                        />
                        <Card.FooterTitle>
                          <h4>{GetLocalText(csi.Name)}</h4>
                          <h5>{subText}</h5>
                        </Card.FooterTitle>
                        <Card.FooterActions>
                          <Link
                            to={`/subscription/edit-item/${csi.ConsumerSubscriptionItemId}`}
                          >
                            {t.common.edit}
                          </Link>
                          {renderDeleteLink()}
                        </Card.FooterActions>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </>
          )
        },
      },
    ]

    return (
      <MyDataTable
        data={subscriptions}
        columns={columns}
        tableClassName="card"
      />
    )
  }
  return (
    <Portlet
      title={t.subscription.index.title}
      headerContent={
        <McButton
          iconLeft="fa-plus"
          bsStyle="primary"
          bsSize="xs"
          title={t.subscription.insert.title}
          pullRight
          onClick={() => navigate(`/subscription/insert`)}
        >
          {t.subscription.insert.title}
        </McButton>
      }
    >
      {renderSubscriptionTable()}
    </Portlet>
  )
}

export default SubscriptionsPortlet
