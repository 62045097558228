import axios, { AxiosError, AxiosResponse } from 'axios'
import { getExceptionError, handleException } from 'src/Helpers/ResponseHelpers'
import { GetBaseUrl } from 'src/Helpers/ServiceHelpers'
import { getFullLanguage } from 'src/I18n'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IBaseUserVTO } from 'src/Models/Server/Response/User/IBaseUserVTO'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import { IUserConnectionVTO } from 'src/Models/Server/Response/User/IUserConnectionVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetUserByEmail(email: string) {
    const url = encodeURI(`/api/v1/user/email/${email}`)
    return new Promise<IBaseUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(url)
          .then((response: AxiosResponse<IBaseUserVTO>) => {
            resolve(response.data)
          })
          .catch((error) => {
            handleException(error, reject)
          })
      }
    )
  },
  Find(search: string) {
    return new Promise<IBaseUserVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/user/find/${encodeURIComponent(search)}`)
          .then((response: AxiosResponse<IBaseUserVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  FindCard(search: string) {
    return new Promise<IBaseUserVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(encodeURI(`/api/v1/user/findcard/${search}`))
          .then((response: AxiosResponse<IBaseUserVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  FindByTagId(tagId: string) {
    return new Promise<IBaseUserVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/user/find/tag/${tagId}`)
          .then((response: AxiosResponse<IBaseUserVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetHelpdeskUser(userGuid: string) {
    return new Promise<IHelpDeskUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/user/helpdesk/${userGuid}`)
          .then((response: AxiosResponse<IHelpDeskUserVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  RemoveMerchantConsumerUser(userId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/user/merchant/remove/${userId}`, null)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditUserName(id: string, username: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/user/username/edit`, {
            Id: id,
            UserName: username,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditName(id: string, name: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/user/name/edit`, {
            Id: id,
            Name: name,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditEmail(id: string, email: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/user/email/edit`, {
            Id: id,
            Email: email,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  HelpdeskResetPassword(id: string, password: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/user/password/helpdeskreset`, {
            Id: id,
            Password: password,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  ForgotPassword(email: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axios
          .post(
            encodeURI(GetBaseUrl() + `/api/v1/user/forgotpassword/${email}`),
            null,
            {
              headers: {
                'accept-language': getFullLanguage(),
                //Authorization: 'Bearer ' + accessToken,
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  ResetPassword(requestId: string, password: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axios
          .post(
            encodeURI(GetBaseUrl() + `/api/v1/user/resetpassword`),
            {
              RequestId: requestId,
              Password: password,
            },
            {
              headers: {
                'accept-language': getFullLanguage(),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  CreateAdminLogin(userGuid: string, tenantDomain: string) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(
            encodeURI(`/api/v1/user/createlogin/${userGuid}/${tenantDomain}`)
          )
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetUserConnections(userId: number) {
    const url = `/api/v1/user/userconnections/${userId}`
    return new Promise<IUserConnectionVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(url)
          .then((response: AxiosResponse<IUserConnectionVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
