import { action } from 'mobx'
import React, {
  InputHTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useState,
} from 'react'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import { convertTextToCent } from 'src/Helpers/CurrencyHelpers'
import MyLabel from './MyLabel/MyLabel'
import { InputMasks } from './MyTextbox'

interface IProps<T> {
  name: Path<T>
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  label?: string
  icon?: string
}

type ICombinedProps<T> = IProps<T> & InputHTMLAttributes<HTMLInputElement>

const MyEuroTextbox = <ObjectType,>({
  name,
  rules,
  label,

  icon,

  //onChange,
  className,
  useFormProps,
  type,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formObject,
  ...otherProps
}: PropsWithChildren<ICombinedProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps

  const [amountInput, setAmountInput] = useState('')

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const validateInputMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const valueAndKey = e.currentTarget.value + e.key
    //console.log(e.key, valueAndKey, inputMask.test(valueAndKey), inputMask)

    if (e.key !== 'Enter' && !InputMasks.Currency.test(valueAndKey)) {
      e.preventDefault()
    }
  }

  const renderTextInput = () => {
    return (
      <>
        <input
          type={type}
          value={amountInput}
          name={name.toString()}
          onKeyPress={(e) => {
            validateInputMask(e)
          }}
          maxLength={6}
          onChange={(e) => {
            const value = e.currentTarget.value
            setAmountInput(value)

            const amountCents = convertTextToCent(value)

            onChange(amountCents)
            //onChange(e)
          }}
          {...otherProps}
          className={`form-control ${className}` || 'form-control'}
        />
      </>
    )
  }

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const renderTextBox = () => {
    return (
      <>
        <div className="form-group">
          {label && (
            <MyLabel htmlFor={name} isRequired={required}>
              {label}
            </MyLabel>
          )}
          {renderTextInput()}
          {errorText()}
        </div>
      </>
    )
  }

  return <>{renderTextBox()}</>
}

export default MyEuroTextbox
