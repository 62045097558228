import jsPDF from 'jspdf'
import images from 'src/Images'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import { CreateTableQrCode } from './TextHelpers'
import qrcodegen from './QrCodeGen'

const borderMargin = 10

const green = '#C0D400'
const darkBlue = '#002B49'

const CreateQrCodeCanvas = (id: string, text: string) => {
  const qr: qrcodegen.QrCode = qrcodegen.QrCode.encodeText(
    text,
    qrcodegen.QrCode.Ecc.HIGH
  ) // Make the QR Code symbol

  const canvas = document.createElement('canvas')
  canvas.setAttribute('id', id)

  drawQrToCanvas(qr, 10, 0, '#FFFFFF', '#000000', canvas)

  return canvas
}

const drawQrToCanvas = (
  qr: qrcodegen.QrCode,
  scale: number,
  border: number,
  lightColor: string,
  darkColor: string,
  canvas: HTMLCanvasElement
): void => {
  if (scale <= 0 || border < 0) throw new RangeError('Value out of range')
  const width: number = (qr.size + border * 2) * scale

  canvas.width = width
  canvas.height = width
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  for (let y = -border; y < qr.size + border; y++) {
    for (let x = -border; x < qr.size + border; x++) {
      ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor
      ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale)
    }
  }
}

export const GetHbQrCodeCanvas = async (sam: ISamVTO) => {
  //

  const createBackground = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, layoutCanvas.width, layoutCanvas.height)
  }

  const createDownloadNow = (ctx: CanvasRenderingContext2D) => {
    ctx.strokeStyle = green
    ctx.lineWidth = 4

    const yOffset = 230
    const height = 45
    ctx.beginPath()

    ctx.fillStyle = green
    ctx.roundRect(borderMargin, yOffset, layoutCanvas.width / 2, height, 10)
    ctx.fill()

    ctx.beginPath()
    ctx.fillStyle = '#FFF'
    ctx.font = '28px FuturaExtraBold'
    ctx.textAlign = 'center'
    const xOffset = layoutCanvas.width * 0.25 + borderMargin
    ctx.fillText('DOWNLOAD NU DE APP', xOffset, yOffset + 32)
  }

  const addLogoToprAndBestelText = (
    logoImage: HTMLImageElement,
    ctx: CanvasRenderingContext2D
  ) => {
    const imageWidth = 320
    const imageHeight = (256 / 988) * imageWidth //recalc image height based on ratio original image
    const yOffset = 215 - imageHeight - 20

    const xOffsetImage =
      layoutCanvas.width * 0.25 + borderMargin - imageWidth / 2

    ctx.drawImage(logoImage, xOffsetImage, yOffset, imageWidth, imageHeight)

    //ctx.beginPath()
    ctx.fillStyle = darkBlue
    ctx.font = '32px FuturaBold'
    ctx.textAlign = 'center'
    const xOffsetText = layoutCanvas.width * 0.25 + borderMargin
    ctx.fillText('EENVOUDIG BESTELLEN', xOffsetText, 40)
    ctx.fillText('EN AFREKENEN MET', xOffsetText, 75)
  }

  const createBorder = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = darkBlue
    ctx.font = '28px FuturaExtraBold'
    ctx.textAlign = 'center'
    const xOffset = layoutCanvas.width * 0.25
    ctx.fillText('DEZE TAFEL IS', xOffset, 330)
    ctx.fillText('GERESERVEERD VOOR:', xOffset, 360)

    ctx.strokeStyle = green
    ctx.lineWidth = 4
    const borderMargin = 10
    ctx.roundRect(
      borderMargin,
      layoutCanvas.height / 2 - borderMargin,
      layoutCanvas.width / 2,
      layoutCanvas.height / 2,
      10
    )

    ctx.stroke()
  }

  const loadImage = (src: any) => {
    return new Promise<HTMLImageElement>((resolve) => {
      //const img = new Image(50, 100)
      const img = new Image()
      img.src = src
      img.onload = () => {
        resolve(img)
      }
    })
  }

  const addHbLogoAndScanText = (
    logoImage: HTMLImageElement,
    ctx: CanvasRenderingContext2D
  ) => {
    const imageWidth = 320
    const imageHeight = (321 / 560) * imageWidth //recalc image height based on ratio original image   HB

    const centerOffset = (layoutCanvas.width / 2 - imageWidth) / 2

    ctx.drawImage(
      logoImage,
      layoutCanvas.width / 2 + centerOffset,
      0,
      imageWidth,
      imageHeight
    )

    ctx.fillStyle = darkBlue
    ctx.font = '50px HaveHeartOne'
    ctx.textAlign = 'center'
    const xOffset = layoutCanvas.width * 0.75
    const yOffset = imageHeight + 60
    ctx.save()
    ctx.translate(xOffset, yOffset)
    ctx.rotate((-5 * Math.PI) / 180)
    //ctx.translate(-xOffset, -yOffset)
    //ctx.fillText('Scan de QR en bestel!', xOffset, yOffset)

    //place at translated x and y so 0,0
    ctx.fillText('Scan de QR en bestel!', 0, 0)
    ctx.restore()
  }

  const createTableNumberText = (
    qrSam: ISamVTO,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.fillStyle = darkBlue
    ctx.font = '60px FuturaBold'

    const offsetY = layoutCanvas.height - 10
    const centerOffset = layoutCanvas.width * 0.75
    ctx.textAlign = 'center'
    ctx.fillText(qrSam.TableNumber, centerOffset, offsetY)
  }

  const createQrCode = (qrSam: ISamVTO, ctx: CanvasRenderingContext2D) => {
    const size = 220
    const offsetX = layoutCanvas.width * 0.75 - size / 2
    const offsetY = layoutCanvas.height - size - 90

    const canvas = CreateQrCodeCanvas(
      qrSam.Uid,
      CreateTableQrCode(qrSam.Uid, qrSam.TableNumber)
    )

    ctx.drawImage(canvas, offsetX, offsetY, size, size)
  }

  const layoutCanvas = document.createElement('canvas')
  layoutCanvas.setAttribute('width', '850')
  layoutCanvas.setAttribute('height', '600')
  const ctx = layoutCanvas.getContext('2d')

  //const logoHbImage = await loadImage(images.HbTent) //HB TentFeest
  const logoHbImage = await loadImage(images.Hb)
  const logoToprWallet = await loadImage(images.LogoToprWallet)

  //create the content in the canvas
  createBackground(ctx)

  addHbLogoAndScanText(logoHbImage, ctx)
  addLogoToprAndBestelText(logoToprWallet, ctx)
  createQrCode(sam, ctx)
  createTableNumberText(sam, ctx)
  createDownloadNow(ctx)
  createBorder(ctx)

  return layoutCanvas
}

export const CreateHbQrCodesPdf = async (sams: Array<ISamVTO>) => {
  const pdf = new jsPDF('landscape')
  for (const qrSam of sams) {
    const layoutCanvas = await GetHbQrCodeCanvas(qrSam)
    const data = layoutCanvas.toDataURL('image/png')
    const imgProperties = pdf.getImageProperties(data)

    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)

    if (sams[sams.length - 1].Uid !== qrSam.Uid) {
      pdf.addPage()
    }

    // ctx.clearRect(0, 0, layoutCanvas.width, layoutCanvas.height)
  }
  pdf.save('QrCodes.pdf')
}
