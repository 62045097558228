import FileDTO from 'src/Models/Server/File/FileDTO'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { ICreateCurrencyRequest } from 'src/Models/Server/Request/Currency/ICreateCurrencyRequest'
import { IEditCurrencyRequest } from 'src/Models/Server/Request/Currency/IEditCurrencyRequest'
import { ICurrencyV2VTO } from 'src/Models/Server/Response/Currency/ICurrencyV2VTO'
import { toprCoreApi } from '../ToprCoreApi'

const currencySlice = toprCoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrenciesAll: builder.query<ICurrencyV2VTO[], void>({
      query: () => `/api/v1/currencies/all`,
      providesTags: ['Currency'],
    }),
    getCurrency: builder.query<ICurrencyV2VTO, string>({
      query: (currencyId) => `/api/v1/currency/${currencyId}`,
      providesTags: ['Currency'],
    }),
    getPaymentProviderConfgurationList: builder.query<
      INameIdVTO<number>[],
      void
    >({
      query: () => `/api/v1/paymentproviderconfiguration/list`,
    }),
    createCurrency: builder.mutation<number, ICreateCurrencyRequest>({
      query: (request) => ({
        url: `/api/v1/currency/insert`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Currency'],
    }),
    editCurrency: builder.mutation<
      void,
      { request: IEditCurrencyRequest; currencyId: string }
    >({
      query: ({ request, currencyId }) => ({
        url: `/api/v1/currency/${currencyId}/edit`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Currency'],
    }),
    editCurrencyImage: builder.mutation<
      void,
      { imageFile: FileDTO; currencyId: string }
    >({
      query: ({ imageFile, currencyId }) => ({
        url: `/api/v1/currency/${currencyId}/image/edit`,
        method: 'POST',
        body: imageFile,
      }),
      invalidatesTags: ['Currency'],
    }),
    //
  }),
})

export const {
  useGetCurrenciesAllQuery,
  useGetCurrencyQuery,
  useGetPaymentProviderConfgurationListQuery,
  useCreateCurrencyMutation,
  useEditCurrencyMutation,
  useEditCurrencyImageMutation,
} = currencySlice
