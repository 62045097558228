import { AdditionalModules } from '../../AdditionalModules'
import IMerchantNameId from '../../IMerchantId'
import INameIdVTO from '../../INameIdVTO'
import ReportGroupVTO from '../Report/ReportGroupVTO'

export const IsAdmin = (managerUserVTO: ManagerUserVTO) => {
  if (managerUserVTO) {
    return managerUserVTO.Roles.indexOf(1) > -1
  }

  return false
}

export const HasModule = (
  managerUserVTO: ManagerUserVTO,
  module: AdditionalModules
) => {
  return managerUserVTO.AdditionalModules.indexOf(module) > -1
}

export default class ManagerUserVTO {
  public UserGuid: string

  public Name: string

  public Email: string

  public DefaultLanguage: string

  public MerchantCurrencies: INameIdVTO<number>[] = []
  //public MerchantCurrencies: CurrencyVTO[] = []

  public ConsumerSubscriptionIds: number[] = []

  public HasSubMerchants: boolean

  public Reports: ReportGroupVTO[] = []
  public MerchantLanguages: string[] = []
  public Roles: number[] = []
  public AdditionalModules: number[] = []
  public Merchant: IMerchantNameId

  public HasValidSubscription: boolean
}
