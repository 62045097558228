import accounting from 'accounting'
import { ISelectOption } from 'src/Models/Client'

interface IFormatOptions {
  symbol?: string
  format?: string
}

export const formatCentsToEuro = (
  label: string | number,
  options?: IFormatOptions
): string => {
  let numberValue = 0

  if (label) {
    numberValue = parseInt(label.toString(), 10)
    numberValue = numberValue / 100
  }

  return accounting.formatMoney(numberValue, options)
}

export const CentsToEuroAmount = (cents: string | number): string => {
  return formatCentsToEuro(cents, {
    symbol: '',
    format: '%s%v',
  })
}

//when editing a cents amount in MyCurrencyInput 1575 Guthaben ==> 15,75  (without € and a space)
export const CentsToCurrencyAmount = (
  amount: number,
  isDecimal: boolean,
  stringFormat: string
): string => {
  return formatCurrency(amount, isDecimal, stringFormat, {
    symbol: '',
    format: '%s%v',
  })
}

export const formatCurrency = (
  centsAmount: number,
  isDecimal: boolean,
  stringFormat: string,
  options?: IFormatOptions
): string => {
  let numberValue = 0

  if (options === undefined) {
    options = {
      symbol: getCurrencySymbol(stringFormat),
    }
  }

  if (isDecimal) {
    numberValue = parseInt(centsAmount.toString(), 10)
    numberValue = numberValue / 100
    const result = accounting.formatMoney(numberValue, options)
    return result
  }

  return centsAmount.toString()
}

export const formatEuroToCurrency = (
  euroAmount: number,
  isDecimal: boolean,
  stringFormat: string
): string => {
  const options = {
    symbol: getCurrencySymbol(stringFormat),
  }

  if (isDecimal) {
    //numberValue = parseInt(amount.toString(), 10)
    //numberValue = numberValue / 100 //already an euro value
    const result = accounting.formatMoney(euroAmount, options)
    return result
  }

  return euroAmount.toString()
}

export const formatCentsAsEuro = (amount: number): string => {
  let numberValue = 0

  numberValue = parseInt(amount.toString(), 10)
  numberValue = numberValue / 100
  const result = accounting.formatMoney(numberValue, {
    format: '%v',
  })
  return result
}

export const convertTextToCent = (value: string) => {
  if (value) {
    if (value.indexOf(',') > 0) {
      const float = parseFloat(value.replace(',', '.'))

      //console.log('float', float, Math.round(float * 100)) //0.07 * 100 //https://javascript.plainenglish.io/what-is-the-floating-point-precision-error-20c84072eed6
      return Math.round(float * 100)
    }
    return parseInt(value, 10) * 100
  }
  return 0
}

export const getCurrencySymbol = (stringFormat: string) => {
  return stringFormat[0] === '{' ? '' : `${stringFormat[0]} `
}

export const decimalToEuroFormat = (value?: number) => {
  if (value === undefined || value === null) {
    return ''
  }
  return accounting.formatMoney(value)
}

export const BalanceCheckTypeOptions: ISelectOption[] = [
  {
    value: '0',
    label: 'None',
  },
  {
    value: '1',
    label: 'Not Defined',
  },
  {
    value: '2',
    label: 'Euro',
  },
  {
    value: '3',
    label: 'CurrencyValue',
  },
  {
    value: '4',
    label: 'Other',
  },
  {
    value: '5',
    label: 'Demo',
  },
]

export const PayoutTypeOptions: ISelectOption[] = [
  {
    value: '0',
    label: 'No payout',
  },
  {
    value: '1',
    label: 'Payout payments',
  },
  {
    value: '2',
    label: 'Only report',
  },
  {
    value: '3',
    label: 'Payout charge',
  },
]
