import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import SamController from 'src/Services/ToprCoreApi/Axios/Controllers/SamController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ISamVTO[]> {
  public ShowLinkSam: boolean = false

  public ShowInsertTableSamModal: boolean = false

  public TerminalId: string = ''

  public EditSamId: number = null
  public EditTableNumberSam?: ISamVTO = undefined
  public QrSam?: ISamVTO = undefined

  //public TableSams

  public get TableSams() {
    return this.ViewModel.filter(
      (x) => x.SamTypeId === 19 && x.TableNumber !== null
    )
  }

  constructor() {
    super()

    this.BaseInitialize([])

    makeObservable(this, {
      ShowLinkSam: observable,
      ShowInsertTableSamModal: observable,
      EditTableNumberSam: observable,
      TableSams: computed,
      QrSam: observable,
      TerminalId: observable,
      EditSamId: observable,
      GetSamsByTerminal: action,
      InsertSam: action,
      ToggleLinkSamModal: action,
      BlockSam: action,
      UnblockSam: action,
      Unlink: action,
      SetEditTableNumberSam: action,
      SetQrSam: action,
    })
  }

  public SetEditTableNumberSam(editTableNumberSam: ISamVTO) {
    this.EditTableNumberSam = editTableNumberSam
  }

  public SetQrSam(qrSam: ISamVTO) {
    this.QrSam = qrSam
  }

  public GetSamsByTerminal(terminalId: string) {
    this.IsLoading = true
    this.TerminalId = terminalId
    SamController.GetSamsByTerminal(terminalId)
      .then((sams) => {
        runInAction(() => {
          this.ViewModel = sams
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          appUiStore.ShowError(error)
          this.IsLoading = false
        })
      })
  }

  public InsertSam() {
    this.IsSubmitting = true
    SamController.Insert(this.TerminalId)
      .then((sam) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.GetSamsByTerminal(this.TerminalId)
          //this.AddElement(sam)
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        appUiStore.ShowError(error)
      })
  }

  public ToggleLinkSamModal(showLinkSamModal: boolean) {
    this.ShowLinkSam = showLinkSamModal
  }

  public ToggleInsertTableSamModal(showInsertTableSamModal: boolean) {
    this.ShowInsertTableSamModal = showInsertTableSamModal
  }

  public BlockSam(samId: number) {
    this.IsSubmitting = true
    this.EditSamId = samId
    SamController.Block(samId)
      .then((sam) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.EditSamId = null
          this.UpdateElement<ISamVTO>(sam, (x) => x.SamId === sam.SamId)
        })
        toast.success(t.formatString(t.sam.block.success, { SamId: samId }))
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsSubmitting = false
          this.EditSamId = null
        })
      })
  }

  public UnblockSam(samId: number) {
    this.IsSubmitting = true
    this.EditSamId = samId
    SamController.Unblock(samId)
      .then((sam) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.EditSamId = null
          toast.success(t.formatString(t.sam.unblock.success, { SamId: samId }))
          this.UpdateElement<ISamVTO>(sam, (x) => x.SamId === sam.SamId)
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsSubmitting = false
          this.EditSamId = null
        })
      })
  }

  public Unlink(samId: number): void {
    this.IsSubmitting = true
    this.EditSamId = samId
    SamController.Unlink(samId)
      .then(() => {
        runInAction(() => {
          this.EditSamId = null
          this.IsSubmitting = false

          const sam = this.ViewModel.find((x) => x.SamId === samId)
          toast.success(t.formatString(t.sam.unlink.success, { SamId: samId }))
          this.DeleteElement(sam)
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.EditSamId = null
          this.IsSubmitting = false
        })
      })
  }
}

export const samsGridStore = new Store()
