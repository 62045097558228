import React, { ReactNode } from 'react'
import { Col, Row } from 'react-bootstrap'
interface IProps {
  pageSize: number
  page: number
  totalRows: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}
const MAX_PAGE_BUTTONS = 5

const Pager: React.FC<IProps> = ({
  page,
  pageSize,
  totalRows,
  onPageChange,
  onPageSizeChange,
}) => {
  if (totalRows === 0) {
    return null
  }

  const maxPageCount = Math.ceil(totalRows / pageSize)

  const createPrevButtons = () => {
    return (
      <>
        <li className="page-item">
          <a className="page-link" onClick={() => onPageChange(1)}>
            {'<<'}
          </a>
        </li>
        <li className="page-item">
          <a
            className="page-link"
            onClick={() => {
              if (page > 1) {
                onPageChange(page - 1)
              }
            }}
          >
            {'<'}
          </a>
        </li>
      </>
    )
  }

  const createPageButtons = () => {
    let startIndex = 1
    let maxIndex = maxPageCount + 1
    if (maxPageCount > MAX_PAGE_BUTTONS) {
      const centerOffSet = Math.floor(MAX_PAGE_BUTTONS / 2) // count of buttons before and after center pagebutton
      const buttonOffsetRight = maxPageCount - centerOffSet // right side of the 5 buttons

      startIndex =
        page <= centerOffSet
          ? 1
          : page > buttonOffsetRight
          ? maxPageCount - MAX_PAGE_BUTTONS + 1
          : page - centerOffSet

      //keep a window of 5 buttons from start index
      maxIndex = MAX_PAGE_BUTTONS + startIndex
    }
    const elements: ReactNode[] = []
    for (let index = startIndex; index < maxIndex; index++) {
      const activeClassName = index === page ? 'active' : ''
      elements.push(
        <li key={index} className={`page-item ${activeClassName}`}>
          <a className="page-link" onClick={() => onPageChange(index)}>
            {index}
          </a>
        </li>
      )
    }

    return elements
  }

  const createNextButtons = () => {
    return (
      <>
        <li className="page-item">
          <a
            className="page-link"
            onClick={() => {
              if (page < maxPageCount) {
                onPageChange(page + 1)
              }
            }}
          >
            {'>'}
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" onClick={() => onPageChange(maxPageCount)}>
            {'>>'}
          </a>
        </li>
      </>
    )
  }
  const pageButtons = () => {
    //console.log('pager', maxPageCount, totalRows, pageSize)

    if (maxPageCount === 1) {
      return null
    }

    return (
      <ul className="pagination react-bootstrap-table-page-btns-ul">
        {createPrevButtons()}
        {createPageButtons()}
        {createNextButtons()}
      </ul>
    )
  }

  return (
    <Row>
      <Col xs={6}>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            {pageSize}&nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li>
              <a onClick={() => onPageSizeChange(10)}>10</a>
            </li>
            <li>
              <a onClick={() => onPageSizeChange(25)}>25</a>
            </li>
            <li>
              <a onClick={() => onPageSizeChange(50)}>50</a>
            </li>
            <li>
              <a onClick={() => onPageSizeChange(100)}>100</a>
            </li>
          </ul>
        </div>
      </Col>
      <Col xs={6}>{pageButtons()}</Col>
    </Row>
  )
}

export default Pager
