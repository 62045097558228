import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  MainPage,
  MyDataTable,
  MyMonthPeriodSelect,
  MySelect,
} from 'src/Components/My'
import { sortDate, sortNumber } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { IFormValues, paymentsReportStore } from './Store'

const PaymentsReport: React.FC = () => {
  useEffect(() => {
    paymentsReportStore.Initialize()
  }, [null])

  const {
    ViewModel,
    Currencies,
    CurrentPage,
    IsLoading,
    TotalDataSize,
    PageSize,
    Transactions,
    Merchants,
    LoadingTransactions,
  } = paymentsReportStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderTransactions = () => {
    const columns = [
      {
        dataField: 'DateFormatted',
        text: t.common.date,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Date')
        },
      },
      {
        dataField: 'AmountFormatted',
        text: t.common.amount,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortNumber(order, rowA, rowB, 'Amount')
        },
      },
      {
        dataField: 'UserName',
        text: t.common.consumerName,
        sort: true,
      },
      {
        dataField: 'PosName',
        text: t.common.pos,
        sort: true,
      },
      {
        dataField: 'Currency.Name',
        text: t.common.currencies,
        sort: true,
      },
    ]

    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return (
      <MyDataTable
        data={Transactions}
        columns={columns}
        serverPaging={{
          page: CurrentPage,
          pageSize: PageSize,
          totalRows: TotalDataSize,
          onPagination: (newPage) => {
            paymentsReportStore.SetPagination(newPage)

            handleSubmit((data) =>
              paymentsReportStore.GetTransactionsPaged(data)
            )()
          },
          onPageSizeChange: (newPageSize) => {
            paymentsReportStore.SetPageSize(newPageSize)

            handleSubmit((data) =>
              paymentsReportStore.GetTransactionsPaged(data)
            )()
          },
        }}
        isLoading={LoadingTransactions}
      />
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.paymentTransactions.title} />

      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet title={t.paymentTransactions.title}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyMonthPeriodSelect
                  onChange={(startDate, endDate) => {
                    setValue('StartDate', startDate)
                    setValue('EndDate', endDate)
                    handleSubmit((data) =>
                      paymentsReportStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedMerchants"
                  label={t.common.merchants}
                  options={Merchants}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedMerchants', options)
                    handleSubmit((data) =>
                      paymentsReportStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedCurrencies"
                  label={t.common.currencies}
                  options={Currencies}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedCurrencies', options)
                    handleSubmit((data) =>
                      paymentsReportStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            {renderTransactions()}
          </form>
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(PaymentsReport)
