import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import { IPosModuleVTO } from 'src/Models/Server/Response/PosModule/IPosModuleVTO'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { persistedStore } from 'src/Stores/PersistedStore'
import AddAppUserModal from './AddAppUserModal/AddAppUserModal'
import AddPosModuleModal from './AddPosModuleModal/AddPosModuleModal'
import EditAppUserModal from './EditAppUserModal/EditAppUserModal'
import { appUsersGridStore } from './Store'

interface IProps {
  merchantId: number
}

const AppUsersGrid: React.FC<IProps> = ({ merchantId }) => {
  useEffect(() => {
    appUsersGridStore.GetAppUsers(merchantId)
  }, [null])

  const {
    ViewModel,
    IsLoading,
    ShowAddUser,
    MerchantId,
    AddPosModuleAppUser,
    EditAppUser,
  } = appUsersGridStore

  const infoCellFormatter = (data: IAppUserVTO) => {
    return (
      <Fragment>
        <div>
          <label>{t.common.name}</label>
          <span>
            {data.Name} ({data.UniqueEmailAddress})
          </span>
        </div>
        <div>
          <label>{t.common.pincode}</label>
          <span>{data.TempPassword}</span>
        </div>
        <ButtonToolbar>
          <McButton
            iconLeft="fa-edit"
            bsSize="small"
            bsStyle="primary"
            onClick={() => {
              appUsersGridStore.ToggleEditUserModal(data)
            }}
          >
            {t.common.edit}
          </McButton>
          <McButton
            iconLeft="fa-ban"
            bsStyle="danger"
            bsSize="small"
            onClick={() => {
              appUiStore.ShowConfirm({
                message: t.formatString(
                  t.merchant.details.appUsers.deleteAppUserConfirm,
                  data.Name
                ),

                confirmAction: () =>
                  appUsersGridStore.DeleteAppUser(data.UserGuid),
                confirmActionText: t.common.delete,
              })
            }}
          >
            {t.common.delete}
          </McButton>
        </ButtonToolbar>
      </Fragment>
    )
  }

  const posModulesCellFormatter = (data: IAppUserVTO) => {
    if (data.PosModulesByCurrency) {
      return (
        <Row>
          {Object.keys(data.PosModulesByCurrency).map((key, index) => {
            const posModulen = data.PosModulesByCurrency[key] as IPosModuleVTO[]
            const labelText = key ? posModulen[0].CurrencyName : '' // t.common.other
            return (
              <Col md={6} key={index} className="form-group">
                <h6>{labelText}</h6>
                {posModulen.map((posModule, pmIndex) => (
                  <Row key={pmIndex} className="margin-bottom">
                    <Col md={6}>{t.modules[posModule.ModuleSystemName]}</Col>
                    <Col md={6}>
                      <McButton
                        iconLeft="fa-times"
                        bsSize="xsmall"
                        bsStyle="danger"
                        onClick={() => {
                          appUiStore.ShowConfirm({
                            message: t.formatString(
                              t.merchant.details.appUsers
                                .deletePosModuleConfirm,
                              {
                                moduleName:
                                  t.modules[posModule.ModuleSystemName],
                                currencyName: labelText,
                              }
                            ),

                            confirmAction: () =>
                              appUsersGridStore.DeletePosModule(
                                posModule.PosModuleGuid,
                                data.UserGuid
                              ),
                            confirmActionText: t.common.delete,
                          })
                        }}
                      >
                        {t.common.delete}
                      </McButton>
                    </Col>
                  </Row>
                ))}
              </Col>
            )
          })}
          <Col md={12}>
            <McButton
              iconLeft="fa-plus"
              bsSize="xsmall"
              bsStyle="primary"
              pullRight
              onClick={() => {
                appUsersGridStore.ToggleAddPosModuleModal(data)
              }}
            >
              {t.merchant.details.appUsers.addPosModule}
            </McButton>
          </Col>
        </Row>
      )
    }

    return null
  }

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      //filter: textFilter({ placeholder: t.common.grid.name.placeholder }),
      formatter: infoCellFormatter,
    },
    {
      dataField: 'posModules',
      text: 'Modulen',
      isDummy: true,
      formatter: posModulesCellFormatter,
    },
  ]

  return (
    <Fragment>
      <McButton
        bsStyle="primary"
        type="submit"
        onClick={() => appUsersGridStore.ToggleAddUserModal(true)}
      >
        {t.merchant.details.appUsers.addUser}
      </McButton>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}

      {ShowAddUser && (
        <AddAppUserModal
          merchantId={MerchantId}
          onClose={() => {
            appUsersGridStore.GetAppUsers(appUsersGridStore.MerchantId)
            appUsersGridStore.ToggleAddUserModal(false)
          }}
        />
      )}
      {EditAppUser && (
        <EditAppUserModal
          appUser={EditAppUser}
          onClose={() => {
            appUsersGridStore.GetAppUsers(appUsersGridStore.MerchantId)
            appUsersGridStore.ToggleEditUserModal(null)
          }}
        />
      )}
      {AddPosModuleAppUser && (
        <AddPosModuleModal
          merchantId={MerchantId}
          appUser={AddPosModuleAppUser}
          onClose={() => {
            appUsersGridStore.GetAppUsers(appUsersGridStore.MerchantId)
            appUsersGridStore.ToggleAddPosModuleModal(null)
          }}
        />
      )}
    </Fragment>
  )
}

export default observer(AppUsersGrid)
