import { observer } from 'mobx-react'
import React from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonRow, LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyCheckboxLabel, MyDataTable } from 'src/Components/My'
import { formatCurrency } from 'src/Helpers/CurrencyHelpers'
import { FormatDateNL, GetWeekDays } from 'src/Helpers/DateHelpers'
import { enumToText, GetLoyaltyDateRangeStatus } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import {
  LoyaltyDateRangeStatus,
  UserScheduleType,
} from 'src/Models/Server/Enums'
import { IUserScheduleBonusSTO } from 'src/Models/Server/Response/IUserScheduleBonusSTO'
import { userPlanningStore } from '../Store'
import UserBonusStatusBadge from './UserBonusStatusBadge'
interface IProps {
  onTurnOff: (userSchedulerId: number) => void
}

const UserBonusesPortlet: React.FC<IProps> = ({ onTurnOff }) => {
  const params = useParams<'id'>()
  const navigate = useNavigate()
  const { IsLoading, UserBonuses } = userPlanningStore

  if (IsLoading) {
    return <LoaderIcon IsLoading={IsLoading} />
  }

  const renderButtons = () => {
    return (
      <ButtonToolbar className="pull-right">
        <McButton
          iconLeft="fa-plus"
          bsStyle="primary"
          bsSize="xs"
          title={t.common.add}
          onClick={() => navigate(`/user/schedule/bonus/insert/${params.id}`)}
        >
          {t.common.add}
        </McButton>
      </ButtonToolbar>
    )
  }
  const renderUserBonuses = () => {
    const columns: IColumn<IUserScheduleBonusSTO>[] = [
      {
        dataField: 'Amount',
        text: 'Bonus',
        formatter: (data: IUserScheduleBonusSTO) => {
          let loyaltyText = '' // 2x coupon x // 20,00 bdo tegoed
          const scheduleTypeText = enumToText(
            data.ScheduleTypeId,
            UserScheduleType,
            t.userScheduleBonus.insert.fields.ScheduleType.enum
          )

          let text =
            data.ScheduleTypeId == UserScheduleType.Monthly
              ? `${scheduleTypeText}`
              : ''

          if (data.CouponId === null) {
            loyaltyText = `<b>${formatCurrency(
              data.Amount,
              data.Currency.IsDecimal,
              data.Currency.StringFormat
            )} ${data.Currency.Name}</b>`
          } else {
            loyaltyText = `<b>${data.Amount}x ${data.Coupon.FullTitle}</b> ${t.common.coupon}`
          }

          if (data.Replenish) {
            //aanvullen tot 2 bdo tegoed
            text += ` ${t.userScheduleBonus.insert.fields.ReplenishCurrency.label.toLowerCase()} ${loyaltyText}`
          } else {
            // 2 bdo te goed opwaarderen
            text += ` ${loyaltyText} ${t.common.charge.toLowerCase()}`
          }

          const renderWeekDays = () => {
            if (data.ScheduleTypeId === UserScheduleType.Monthly) {
              return null
            }
            const translatedDays = GetWeekDays()
            const getWeekDay = (value: string) => {
              return translatedDays.find((x) => x.value === value)?.label
            }

            return (
              <div className="inline-check">
                {translatedDays.map((day, key) => (
                  <MyCheckboxLabel
                    key={key}
                    checked={data[day.value]}
                    label={getWeekDay(day.value)}
                  />
                ))}
              </div>
            )
          }

          return (
            <>
              <span dangerouslySetInnerHTML={{ __html: text }}></span>
              {renderWeekDays()}
            </>
          )
        },
      },
      {
        dataField: 'ScheduleTypeId',
        text: 'Status',
        sort: true,
        sortFunc: (
          order,
          dataField,
          rowA: IUserScheduleBonusSTO,
          rowB: IUserScheduleBonusSTO
        ) => {
          const statusA = GetLoyaltyDateRangeStatus(
            rowA.StartDate,
            rowA.EndDate
          )
          const statusB = GetLoyaltyDateRangeStatus(
            rowB.StartDate,
            rowB.EndDate
          )

          if (order === 'asc') {
            return statusA - statusB
          } else {
            return statusB - statusA
          }
        },
        formatter: (data: IUserScheduleBonusSTO) => {
          return (
            <Row>
              <Col md={6}>
                <label>{t.common.period}</label>
                {FormatDateNL(data.StartDate)}
                {data.EndDate !== null && <> - {FormatDateNL(data.EndDate)}</>}
              </Col>
              <Col md={6}>
                {/* <label>{t.common.status}</label> */}
                <UserBonusStatusBadge
                  start={data.StartDate}
                  end={data.EndDate}
                />
              </Col>
            </Row>
          )
        },
      },
      {
        text: '',
        formatter: (data: IUserScheduleBonusSTO) => {
          const status = GetLoyaltyDateRangeStatus(data.StartDate, data.EndDate)

          const renderTurnOffButton = () => {
            if (status === LoyaltyDateRangeStatus.Active) {
              return (
                <>
                  <McButton
                    iconLeft="fa-power-off"
                    //bsStyle="outline"
                    bsSize="xs"
                    title={t.common.turnoff}
                    onClick={() => {
                      //todo
                      onTurnOff(data.UserScheduleBonusId)
                    }}
                  >
                    {t.common.turnoff}
                  </McButton>
                </>
              )
            }

            return null
          }

          return (
            <ButtonRow>
              <McButton
                iconLeft="fa-edit"
                bsStyle="primary"
                bsSize="xs"
                title={t.common.edit}
                onClick={() =>
                  navigate(
                    `/user/schedule/bonus/edit/${params.id}/${data.UserScheduleBonusId}`
                  )
                }
              >
                {t.common.edit}
              </McButton>
              {renderTurnOffButton()}
            </ButtonRow>
          )
        },
      },
    ]

    return (
      <MyDataTable
        data={UserBonuses}
        columns={columns}
        defaultSorting={{
          dataField: 'ScheduleTypeId',
          order: 'asc',
        }}
      />
    )
  }

  return (
    <Portlet title="User Bonus" headerContent={renderButtons()}>
      {renderUserBonuses()}
    </Portlet>
  )
}

export default observer(UserBonusesPortlet)
