import { toJS } from 'mobx'
import { sortDefault as defaultSortFunction } from 'src/Helpers/TableHelpers'
import { IColumn } from 'src/Models/Client'

export interface IState<T = {}> {
  page: number
  pageSize: number
  sorting?: Sorting
  allRows: any[]
  filteredRows: any[]
  columns: IColumn<T>[]
  pagedRows: any[]
  serverPaging?: ServerPaging
  totalRows: number
  //filter?: Filter
  selectIdDataColumn?: string
  selectedRowIds: any[]
}

export const initialState: IState = {
  page: 1,
  pageSize: 10,
  allRows: [],
  filteredRows: [],
  pagedRows: [],
  columns: [],
  totalRows: 1,
  selectedRowIds: [],
}

export type Sorting = {
  dataField: string
  order: 'asc' | 'desc'
}

// export type Filter = {
//   dataField: string
//   filter: string
// }

export type ServerPaging = {
  page: number
  pageSize: number
  totalRows: number
  onPagination: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

export type Actions<T> =
  | { type: 'setPage'; newPage: number }
  | { type: 'setPageSize'; newPageSize: number }
  | { type: 'setSorting'; newSorting: Sorting }
  | {
      type: 'initializePage'
      allRows: any[]
      columns: IColumn<T>[]
      serverPaging?: ServerPaging
      defaultSorting?: Sorting
      defaultPageSize: number
      selectIdDataColumn?: string
    }
  | {
      type: 'setFilter'
      filterText: string
      //newFilter: Filter;
      column: IColumn<T>
    }
  | { type: 'setSelectedRowIds'; selectedRowIds: any[] }

export function reducer<T>(state: IState<T>, action: Actions<T>): IState<T> {
  let newState: IState<T>
  //console.log('action', action.type)
  switch (action.type) {
    case 'initializePage':
      newState = {
        ...state,
        allRows: action.allRows,
        columns: action.columns,
        filteredRows: action.allRows,
        sorting: action.defaultSorting,
        totalRows: action.allRows.length,
        pageSize: action.defaultPageSize,
        serverPaging: action.serverPaging,
        selectIdDataColumn: action.selectIdDataColumn,
      }

      //get props from serverPaging
      if (action.serverPaging !== undefined) {
        newState = {
          ...newState,
          ...action.serverPaging,
        }
      }

      //default sort
      newState.pagedRows = sortAndPageRows(newState)
      break
    case 'setSelectedRowIds':
      return { ...state, selectedRowIds: action.selectedRowIds }
    case 'setPage':
      newState = {
        ...state,
        page: action.newPage,
      }

      newState.pagedRows = sortAndPageRows(newState)
      break
    case 'setPageSize':
      newState = {
        ...state,
        //page: 1,
        page: state.serverPaging !== undefined ? state.serverPaging.page : 1,
        pageSize: action.newPageSize,
      }
      newState.pagedRows = sortAndPageRows(newState)
      break
    case 'setSorting':
      newState = {
        ...state,
        page: 1,
        sorting: action.newSorting,
      }
      newState.pagedRows = sortAndPageRows(newState)
      break
    case 'setFilter': {
      newState = {
        ...state,
        page: 1,
      }

      const { allRows } = newState

      //default filter on datafield
      const defaultFilterFunc = (filterValue: string, allRows: any[]) => {
        if (filterValue.trim() === '') {
          return allRows
        }

        return allRows.filter((row) => {
          const dataField = action.column.dataField.toString() ?? ''
          const value = row[`${dataField}`] as string
          if (value !== undefined) {
            return value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
          }
          return false
        })
      }

      const filteredRows =
        action.column.filterFunc !== undefined
          ? action.column.filterFunc(action.filterText, allRows)
          : defaultFilterFunc(action.filterText, allRows)

      newState.filteredRows = filteredRows
      newState.totalRows = filteredRows.length
      newState.pagedRows = sortAndPageRows(newState)
      break
    }
    default:
      throw new Error('Invalid Reducer Action')
  }
  return newState
}

const sortAndPageRows = <T>(state: IState<T>) => {
  const {
    allRows,
    filteredRows,
    page,
    pageSize,
    sorting,
    columns,
    serverPaging,
  } = state

  if (serverPaging) {
    return allRows
  }

  //const filteredRows --> readonly because of reducer
  //work with a copy
  const result = filteredRows.slice()

  //clientside use filterRows
  if (sorting) {
    const sortedColumn = columns.find((x) => x.dataField === sorting.dataField)

    //custom sort
    if (sortedColumn !== undefined && sortedColumn.sortFunc !== undefined) {
      result.sort((a, b) =>
        sortedColumn.sortFunc(sorting.order, sorting.dataField as any, a, b)
      )
    } else {
      result.sort((a, b) =>
        defaultSortFunction(sorting.order, sorting.dataField, a, b)
      )
    }
  }

  //client paging
  return result.slice((page - 1) * pageSize, page * pageSize)
}

export const reducerActions = <T>(
  state: IState<T>,
  dispatch: (action: Actions<T>) => void
) => {
  return {
    initializePage: (
      data: T[],
      columns: IColumn<T>[],
      defaultPageSize: number,
      serverPaging?: ServerPaging,
      defaultSorting?: Sorting,
      selectIdDataColumn?: string
    ) => {
      dispatch({
        type: 'initializePage',
        allRows: data,
        columns,
        serverPaging,
        defaultSorting,
        defaultPageSize,
        selectIdDataColumn,
      })
    },
    setSorting: (newSorting: Sorting) => {
      dispatch({ type: 'setSorting', newSorting })
    },
    setFilter: (filterText: string, column: IColumn<T>) => {
      dispatch({
        type: 'setFilter',
        filterText,
        //newFilter: { filter, dataField: column.dataField.toString() },
        column,
      })
    },

    setPage: (newPage: number) => {
      dispatch({ type: 'setPage', newPage })
    },
    setPageSize: (newPageSize: number) => {
      dispatch({ type: 'setPageSize', newPageSize })
    },
    setRowSelection: (
      checked: boolean,
      row: any,
      onSelect?: (selectedIds: any[]) => void
    ) => {
      const rowId = row[state.selectIdDataColumn]

      if (checked) {
        //add
        const selectedRowIds = [...state.selectedRowIds, rowId]
        dispatch({ type: 'setSelectedRowIds', selectedRowIds })
        onSelect(selectedRowIds)
      } else {
        //remove
        const rowIndex = state.selectedRowIds.findIndex((x) => x === rowId)
        if (rowIndex > -1) {
          const selectedRowIds = [...state.selectedRowIds]
          selectedRowIds.splice(rowIndex, 1)
          dispatch({ type: 'setSelectedRowIds', selectedRowIds })
          onSelect(selectedRowIds)
        }
      }
    },

    setSelectAll: (
      checked: boolean,
      onSelect?: (selectedIds: any[]) => void
    ) => {
      const selectedRowIds = state.allRows.map(
        (row) => row[state.selectIdDataColumn]
      )
      if (checked) {
        dispatch({ type: 'setSelectedRowIds', selectedRowIds })
        onSelect(selectedRowIds)
      } else {
        //deselect all
        dispatch({ type: 'setSelectedRowIds', selectedRowIds: [] })
        onSelect([])
      }
    },
  }
}
