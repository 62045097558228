import React from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'src/I18n'
import { AdditionalModules } from 'src/Models/Server/AdditionalModules'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import TopMenuListItem from './TopMenuListItem'
interface IProps {}

const FinanceMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore

  return (
    <TopMenuListItem
      title={t.topMenu.finance.title}
      urls={[
        '/finance/merchantpayments',
        'merchantrefunds',
        '/finance/uploadstornos',
        '/finance/stornostatus',
        '/finance/sepafile/generate',
      ]}
    >
      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/finance/merchantpayments'}>
            <i className={`fa fa-table dropdown-icon`} />
            {t.topMenu.finance.merchantPayments}
          </NavLink>
        </li>
        {IsAdmin(SelectedManagerUser) && (
          <>
            <li>
              <NavLink to={'/merchantrefunds'}>
                <i className={`fa fa-download dropdown-icon`} />
                {t.merchantRefunds.title}
              </NavLink>
            </li>
            <li>
              <NavLink to={'/finance/uploadstornos'}>
                <i className={`fa fa-upload dropdown-icon`} />
                {t.stornos.title}
              </NavLink>
            </li>
          </>
        )}
        {(SelectedManagerUser.AdditionalModules.indexOf(
          AdditionalModules.RtoStornoStatus
        ) > -1 ||
          IsAdmin(SelectedManagerUser)) && (
          <li>
            <NavLink to={'/finance/stornostatus'}>
              <i className={`fa fa-table dropdown-icon`} />
              {t.stornoStatus.title}
            </NavLink>
          </li>
        )}
      </ul>
    </TopMenuListItem>
  )
}

export default FinanceMenu
