import React, { memo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonRow, LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { ConfirmModal } from 'src/Components/Modals'
import McFormModal from 'src/Components/Modals/McFormModal'
import { MyCheckboxLabel, MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import { IMerchantCurrencyVTO } from 'src/Models/Server/Response/Currency/IMerchantCurrencyVTO'

import {
  useCreateMerchantCurrencyMutation,
  useEditMerchantCurrencyMutation,
  useDeleteMerchantCurrencyMutation,
} from 'src/Services/ToprCoreApi/RTK/Slices/MerchantCurrencySlice'
import { appUiStore } from 'src/Stores/AppUiStore'
import MerchantCurrencyForm from './MerchantCurrencyForm'

interface IProps {
  merchantCurrencies: IMerchantCurrencyVTO[]
}

const MerchantCurrenciesTable = memo<IProps>(({ merchantCurrencies }) => {
  const params = useParams<'currencyId'>()
  const [insertModalIsVisible, setInsertModalIsVisible] = useState(false)
  const [editItem, setEditItem] = useState<undefined | IMerchantCurrencyVTO>(
    undefined
  )

  const [createMerchantCurrency, { isLoading: createIsSubmitting }] =
    useCreateMerchantCurrencyMutation()

  const [editMerchantCurrency, { isLoading: editIsSubmitting }] =
    useEditMerchantCurrencyMutation()

  const [deleteMerchantCurrency] = useDeleteMerchantCurrencyMutation()

  const [deletingMerchantCurrencyId, setDeletingMerchantCurrencyId] =
    useState(-1)

  const columns: IColumn<IMerchantCurrencyVTO>[] = [
    {
      text: 'Merchant',
      dataField: 'MerchantName',
    },
    {
      text: 'Instellingen',
      formatter: (data) => {
        return (
          <>
            <div>
              <MyCheckboxLabel label="AutoJoin" checked={data.AutoJoin} />
              <MyCheckboxLabel
                label="InnzziiOrderPayment"
                checked={data.InnzziiOrderPayment}
              />
            </div>
          </>
        )
      },
    },
    {
      text: '',
      formatter: (data: IMerchantCurrencyVTO) => {
        return (
          <ButtonRow>
            <McButton
              bsStyle="primary"
              bsSize="xs"
              type="button"
              onClick={() => {
                setEditItem(data)
              }}
            >
              {t.common.edit}
            </McButton>
            <McButton
              bsStyle="danger"
              bsSize="xs"
              type="button"
              pullRight
              isLoading={deletingMerchantCurrencyId === data.MerchantCurrencyID}
              onClick={() =>
                appUiStore.ShowConfirm({
                  message: `Weet je zeker dat je ${data.MerchantName} voor deze currency wilt verwijderen?`,
                  confirmActionText: t.common.delete,
                  confirmAction: () => {
                    setDeletingMerchantCurrencyId(data.MerchantCurrencyID)
                    deleteMerchantCurrency(data.MerchantCurrencyID)
                      .unwrap()
                      .then(() => {
                        setDeletingMerchantCurrencyId(-1)
                      })
                  },
                })
              }
            >
              {t.common.delete}
            </McButton>
          </ButtonRow>
        )
      },
    },
  ]

  const renderTable = () => {
    if (merchantCurrencies === undefined) {
      return <LoaderIcon IsLoading={true} />
    }

    return <MyDataTable data={merchantCurrencies} columns={columns} />
  }

  return (
    <Portlet
      title="MerchantCurrencies"
      headerContent={
        <>
          <McButton
            iconLeft="fa-plus"
            bsStyle="primary"
            bsSize="xs"
            title={t.common.add}
            pullRight
            onClick={() => setInsertModalIsVisible(true)}
          >
            {t.common.add}
          </McButton>
        </>
      }
    >
      {renderTable()}
      <McFormModal
        title={'MerchantCurrency toevoegen'}
        isVisible={insertModalIsVisible}
        closeClicked={() => setInsertModalIsVisible(false)}
      >
        <MerchantCurrencyForm
          closeClicked={() => setInsertModalIsVisible(false)}
          isLoading={createIsSubmitting}
          merchantCurrencies={merchantCurrencies}
          onSubmit={(data) => {
            createMerchantCurrency({
              request: {
                AutoJoin: data.AutoJoin,
                InnzziiOrderPayment: data.InnzziiOrderPayment,
                MerchantID: data.Merchant.value,
              },
              currencyId: params.currencyId,
            }).then(() => {
              setInsertModalIsVisible(false)
            })
          }}
        />
      </McFormModal>
      <McFormModal
        title={'MerchantCurrency wijzigen'}
        isVisible={editItem !== undefined}
        closeClicked={() => setEditItem(undefined)}
      >
        <MerchantCurrencyForm
          closeClicked={() => setEditItem(undefined)}
          isLoading={editIsSubmitting}
          merchantCurrencies={merchantCurrencies}
          editItem={editItem}
          onSubmit={(data) => {
            editMerchantCurrency({
              request: {
                AutoJoin: data.AutoJoin,
                InnzziiOrderPayment: data.InnzziiOrderPayment,
              },
              merchantCurrencyId: editItem.MerchantCurrencyID,
            }).then(() => {
              setEditItem(undefined)
            })
          }}
        />
      </McFormModal>

      <ConfirmModal
        confirmMessage={appUiStore.ConfirmMessage}
        isConfirming={appUiStore.IsConfirming}
        closeClicked={() => appUiStore.HideConfirm()}
      />
    </Portlet>
  )
})

export default MerchantCurrenciesTable
