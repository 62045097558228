import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar, Col, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { LoaderIcon, McButton, McLink } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import SelectTenantModal from 'src/Components/Modals/SelectTenantModal/SelectTenantModal'
import { selectTenantStore } from 'src/Components/Modals/SelectTenantModal/Store'
import { t } from 'src/I18n'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { persistedStore } from 'src/Stores/PersistedStore'
import EditEmailModal from './EditEmailModal/EditEmail'
import EditNameModal from './EditNameModal/EditName'
import EditPasswordModal from './EditPasswordModal/EditPassword'
import EditUserNameModal from './EditUserNameModal/EditUserName'
import { userInfoStore } from './Store'
import TokensPortlet from './TokensPortlet/TokensPortlet'
import UserTagsView from './UserTags/UserTagsView'
interface IProps {}

const InfoTab: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()
  useEffect(() => {
    userInfoStore.Initialize(params.id)
  }, [params.id])

  const {
    IsLoading,
    User,
    ShowEditEmailModal,
    ShowEditNameModal,
    ShowEditPasswordModal,
    ShowEditUserNameModal,
    ChildUserConnections,
    ParentUserConnections,
    RemovingConsumerUser,
    Tokens,
    UserFields,
    UserTags,
    IsUserTagsLoading,
  } = userInfoStore

  const { SelectedManagerUser } = persistedStore

  const renderChildUserConnections = () => {
    if (ChildUserConnections.length === 0) {
      return null
    }

    return (
      <FormGroup>
        <label>{t.userDetails.childUserConnections}</label>
        <ButtonToolbar>
          {ChildUserConnections.map((uc, key) => (
            <McLink
              key={key}
              to={`/user/details/${uc.Child.Id}`}
              style="buttonDefault"
              buttonSize="xs"
              icon="fa-user"
            >
              {uc.Child.Name}
            </McLink>
          ))}
        </ButtonToolbar>
      </FormGroup>
    )
  }

  const renderParentUserConnections = () => {
    if (ParentUserConnections.length === 0) {
      return null
    }

    return (
      <FormGroup>
        <label>{t.userDetails.parentUserConnections}</label>
        <ButtonToolbar>
          {ParentUserConnections.map((uc, key) => (
            <McLink
              key={key}
              to={`/user/details/${uc.Parent.Id}`}
              style="buttonDefault"
              buttonSize="xs"
              icon="fa-user"
            >
              {uc.Parent.Name}
            </McLink>
          ))}
        </ButtonToolbar>
      </FormGroup>
    )
  }

  const renderSiedlerRemove = () => {
    if (
      SelectedManagerUser.Merchant &&
      SelectedManagerUser.Merchant.Id !== 493
    ) {
      return null
    }

    return (
      <McButton
        bsSize="xs"
        iconLeft="fa-ban"
        bsStyle="danger"
        isLoading={RemovingConsumerUser}
        onClick={() =>
          appUiStore.ShowConfirm({
            message: t.formatString(t.siedlerRemove.confirmMessage, {
              name: User.Name,
            }),
            confirmAction: () =>
              userInfoStore.RemoveMerchantConsumer(User.UserId),
            confirmActionText: t.siedlerRemove.action,
          })
        }
      >
        {t.siedlerRemove.action}
      </McButton>
    )
  }

  const renderUserFieldsPortlet = () => {
    if (UserFields.length > 0) {
      return (
        <Portlet title={t.common.extraInformation}>
          <Row>
            {UserFields.map((userField, key) => {
              switch (userField.Field.FieldDataTypeName) {
                case 'String':
                  return (
                    <Col key={key} md={6}>
                      <FormGroup>
                        <label>{userField.Field.Title}</label>
                        <span>{userField.Value}</span>
                      </FormGroup>
                    </Col>
                  )
                case 'Boolean':
                  {
                    if (userField.Field.Name === 'UnderAdministration') {
                      return (
                        <Col key={key} md={6}>
                          <FormGroup>
                            <label>{userField.Field.Title}</label>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                userInfoStore.HandleUserFieldCheckBoxChange(
                                  userField,
                                  e
                                )
                              }}
                              checked={userField.Value.toLowerCase() === 'true'}
                            />
                          </FormGroup>
                        </Col>
                      )
                    }
                  }
                  return null
                default:
                  return null
              }
            })}
          </Row>
        </Portlet>
      )
    }

    return null
  }

  const renderChangePasswordButton = () => {
    if (!IsAdmin(SelectedManagerUser)) {
      return null
    }

    return (
      <McButton
        bsSize="xs"
        bsStyle="primary"
        pullRight
        onClick={() => {
          userInfoStore.ToggleEditPasswordModal()
        }}
      >
        <i className="fa fa-edit" /> {t.userDetails.resetPassword.action}
      </McButton>
    )
  }

  const renderAdminUserLogin = () => {
    if (!IsAdmin(SelectedManagerUser)) {
      return null
    }

    return (
      <McButton
        bsSize="xs"
        className="pull-right margin-left"
        onClick={() => {
          selectTenantStore.OpenTenantSelect(User.UserGuid)
        }}
      >
        {t.helpdesk.loginUserUser}
      </McButton>
    )
  }

  return (
    <>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && User !== null && (
        <>
          <div className="details">
            <Portlet title={t.common.details}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label>Id</label>
                    <span>{User.UserId}</span>
                  </FormGroup>
                  <FormGroup>
                    <label>{t.common.name}</label>
                    <span>{User.Name}</span>
                    {renderAdminUserLogin()}
                    <McButton
                      bsSize="xs"
                      bsStyle="primary"
                      pullRight
                      onClick={() => {
                        userInfoStore.ToggleEditNameModal()
                      }}
                    >
                      <i className="fa fa-edit" /> {t.common.edit}
                    </McButton>
                  </FormGroup>
                  <FormGroup>
                    <label>{t.common.username}</label>
                    <span>{User.UserName}</span>
                    <McButton
                      bsSize="xs"
                      bsStyle="primary"
                      pullRight
                      onClick={() => {
                        userInfoStore.ToggleEditUserNameModal()
                      }}
                    >
                      <i className="fa fa-edit" /> {t.common.edit}
                    </McButton>
                  </FormGroup>
                  <FormGroup>
                    <label>{t.common.email}</label>
                    <span>{User.UniqueEmailAddress}</span>
                    <McButton
                      bsSize="xs"
                      bsStyle="primary"
                      pullRight
                      onClick={() => {
                        userInfoStore.ToggleEditEmailModal()
                      }}
                    >
                      <i className="fa fa-edit" /> {t.common.edit}
                    </McButton>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label>{t.common.password}</label>
                    <span>*****</span>
                    {renderChangePasswordButton()}
                  </FormGroup>
                  {renderParentUserConnections()}
                  {renderChildUserConnections()}
                  <UserTagsView
                    userTags={UserTags}
                    isUserTagsLoading={IsUserTagsLoading}
                  />
                  {renderSiedlerRemove()}
                </Col>
              </Row>
            </Portlet>
            {renderUserFieldsPortlet()}
            <TokensPortlet userGuid={User.UserGuid} tokens={Tokens} />
          </div>
          <SelectTenantModal />
          {ShowEditUserNameModal && (
            <EditUserNameModal
              user={User}
              onClose={() => userInfoStore.ToggleEditUserNameModal()}
              onSubmitSuccess={(user) => {
                userInfoStore.SetUser(user)
              }}
            />
          )}
          {ShowEditNameModal && (
            <EditNameModal
              user={User}
              onClose={() => userInfoStore.ToggleEditNameModal()}
              onSubmitSuccess={(user) => {
                userInfoStore.SetUser(user)
              }}
            />
          )}
          {ShowEditEmailModal && (
            <EditEmailModal
              user={User}
              onClose={() => userInfoStore.ToggleEditEmailModal()}
              onSubmitSuccess={(user) => {
                userInfoStore.SetUser(user)
              }}
            />
          )}
          {ShowEditPasswordModal && (
            <EditPasswordModal
              user={User}
              onClose={() => userInfoStore.ToggleEditPasswordModal()}
              onSubmitSuccess={(user) => {
                userInfoStore.SetUser(user)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default observer(InfoTab)
