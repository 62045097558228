import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyChart, MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { currencyChargeMonthReportStore, IFormValues } from './Store'
interface IProps {}

const CurrencyChargeMonthReport: React.FC<IProps> = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    currencyChargeMonthReportStore.Initialize()
  }, [null])
  const {
    ViewModel,
    IsLoading,
    ChartResponse,
    Currencies,
    Merchants,
    MonthOptions,
  } = currencyChargeMonthReportStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue, getValues } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderFilter = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading />
    }

    return (
      <Row>
        <Col md={6}>
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMonth"
            label={t.common.month}
            options={MonthOptions}
            onSelectionChange={(option: ISelectOption) => {
              setValue('SelectedMonth', option)
              handleSubmit((data) =>
                currencyChargeMonthReportStore.GetCurrencyChargeReportMonth(
                  data
                )
              )()
            }}
          />

          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedCurrency"
            label={t.common.currencies}
            options={Currencies}
            onSelectionChange={(options: ISelectOption) => {
              setValue('SelectedCurrency', options)
              handleSubmit((data) =>
                currencyChargeMonthReportStore.GetCurrencyChargeReportMonth(
                  data
                )
              )()
            }}
          />
        </Col>
        <Col md={6}>
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMerchants"
            label={t.common.merchants}
            options={Merchants}
            isMulti
            onSelectionChange={(options: ISelectOption[]) => {
              setValue('SelectedMerchants', options)
              handleSubmit((data) =>
                currencyChargeMonthReportStore.GetCurrencyChargeReportMonth(
                  data
                )
              )()
            }}
          />
        </Col>
      </Row>
    )
  }
  //Verbruik (Currency) dag
  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t
          .formatString(t.currencyCharge.title, t.common.month)
          .toString()}
      />
      <Portlet title={t.common.filter}>
        <form className="parsley-form">{renderFilter()}</form>
      </Portlet>
      <MyChart
        chartResponse={ChartResponse}
        // onDetailsClick={(id) => {
        //   navigate(
        //     `/report/payment/day/merchant/${id}/${data.SelectedCurrency.value}/${data.SelectedMonth.value}`
        //   )
        // }}
      />
    </MainPage>
  )
}

export default observer(CurrencyChargeMonthReport)
