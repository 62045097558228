import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable, MySelect, MyTextbox } from 'src/Components/My'
import { FormatDateTimeLocalized } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import { IFormValues, transactionStore } from './Store'
interface IProps {}

const Transaction: React.FC<IProps> = () => {
  useEffect(() => {
    transactionStore.Initialize()
  }, [null])

  const { IsSubmitting, ViewModel, Currencies, SearchTypes, Transactions } =
    transactionStore
  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Search: '',
    },
  })
  const { handleSubmit, reset } = useFormProps

  const onSubmitSearch = (data: IFormValues) => {
    transactionStore.FindTransaction(
      data.Search,
      data.SearchType.value,
      data.SelectedCurrency.value
    )
  }

  const renderTransactions = () => {
    const columns = [
      {
        dataField: 'RequestDateFormatted',
        text: t.common.date,
        formatter: (transaction: ITransactionExtendedSTO) => {
          return FormatDateTimeLocalized(transaction.RequestDate)
        },
      },
      {
        dataField: 'AmountFormatted',
        text: t.common.amount,
      },
      {
        dataField: 'TransactionType',
        text: t.common.type,
      },
      {
        dataField: 'PosName',
        text: t.common.pos,
      },
      {
        dataField: 'UserName',
        text: t.common.user,
        formatter: (transaction: ITransactionExtendedSTO) => {
          const userName =
            transaction.UserName === null || '' ? `User` : transaction.UserName

          return (
            <Link to={`/user/details/${transaction.UserGuid}`}>{userName}</Link>
          )
        },
      },
    ]

    if (Transactions.length === 0) {
      return null
    }

    return <MyDataTable data={Transactions} columns={columns} />
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.transaction.title} />
        <Portlet title={t.transaction.title}>
          <form
            className="parsley-form"
            onSubmit={handleSubmit(onSubmitSearch)}
          >
            <Row>
              <Col md={4}>
                <MyTextbox
                  formObject={ViewModel}
                  label={'Search'}
                  name="Search"
                  useFormProps={useFormProps}
                  maxLength={150}
                  icon="fa-search"
                  tabIndex={1}
                />
              </Col>
              <Col md={4}>
                <MySelect
                  formObject={ViewModel}
                  useFormProps={useFormProps}
                  label={t.common.currency}
                  name="SelectedCurrency"
                  options={Currencies}
                />
              </Col>
              <Col md={4}>
                <MySelect
                  formObject={ViewModel}
                  useFormProps={useFormProps}
                  label={t.common.type}
                  name="SearchType"
                  options={SearchTypes}
                />
              </Col>
            </Row>
            <Row>
              <Col mdOffset={8} md={4}>
                <McButton
                  block
                  bsStyle="primary"
                  //bsSize="large"
                  type="submit"
                  isLoading={IsSubmitting}
                >
                  {t.common.search}
                </McButton>
              </Col>
            </Row>
          </form>
          <br />
          {renderTransactions()}
        </Portlet>
      </MainPage>
    </>
  )
}

export default observer(Transaction)
