import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import { t } from '../../../../I18n'
import AddTagModal from './Components/AddTag/AddTagModal'
import EditTagModal from './Components/EditTag/EditTagModal'
import { initialState, reducer, reducerActions } from './Reducer'

const TagIndex: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isLoading, tags, isAddTagModalVisible, editTag } = state
  useEffect(() => {
    actions.getTags()
  }, [null])

  const renderContent = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    const renderTagTable = () => {
      return (
        <MyDataTable
          data={tags}
          defaultSorting={{
            dataField: 'Name',
            order: 'asc',
          }}
          defaultPageSize={50}
          columns={[
            {
              dataField: 'Name',
              text: t.common.name,
              sort: true,
            },
            {
              dataField: 'Dummy',
              text: '',
              formatter: (data: ITagVTO) => {
                return (
                  <>
                    <McButton
                      iconLeft="fa-edit"
                      bsStyle="primary"
                      bsSize="xs"
                      title={t.common.edit}
                      onClick={() => {
                        actions.toggleEditTagModal(data)
                      }}
                    >
                      {t.common.edit}
                    </McButton>
                  </>
                )
              },
            },
          ]}
        />
      )
    }

    const renderAddTagModal = () => {
      if (!isAddTagModalVisible) {
        return null
      }

      return (
        <AddTagModal
          onSave={() => {
            actions.toggleAddTagModal(false)
            actions.getTags()
          }}
          onClose={() => {
            actions.toggleAddTagModal(false)
          }}
        />
      )
    }

    const renderEditTagModal = () => {
      if (editTag === undefined) {
        return null
      }

      return (
        <EditTagModal
          editTag={editTag}
          onSave={() => {
            actions.toggleEditTagModal(undefined)
            actions.getTags()
          }}
          onClose={() => {
            actions.toggleEditTagModal(undefined)
          }}
        />
      )
    }

    return (
      <Portlet
        title={t.tags.index.title}
        headerContent={
          <ButtonToolbar className="pull-right">
            <McButton
              iconLeft="fa-plus"
              bsStyle="primary"
              bsSize="xs"
              title={t.tags.insert.title}
              onClick={() => {
                actions.toggleAddTagModal(true)
              }}
            >
              {t.tags.insert.title}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderTagTable()}
        {renderAddTagModal()}
        {renderEditTagModal()}
      </Portlet>
    )
  }
  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.tags.index.title} />
      {renderContent()}
    </MainPage>
  )
}

export default TagIndex
