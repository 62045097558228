import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import TopMenuListItem from './TopMenuListItem'
interface IProps {}

const AdminMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore
  if (!IsAdmin(SelectedManagerUser)) {
    return null
  }

  const fileHasActiveChildrenClassName = hasMatchedChildren('/file/insert')
    ? 'dropdown-submenu active'
    : 'dropdown-submenu'

  return (
    <TopMenuListItem title={'Admin'} urls={['/merchants', '/file/insert']}>
      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/merchants'}>
            <i className="fa fa-building dropdown-icon " />
            Merchants
          </NavLink>
        </li>
        <li className={fileHasActiveChildrenClassName}>
          <a>Files</a>
          <ul className="dropdown-menu" role="menu">
            <li>
              <NavLink to={'/file/insert'}>
                <i className="fa fa-building dropdown-icon " />
                Insert
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </TopMenuListItem>
  )
}

export default AdminMenu
