import React, { useEffect, PropsWithChildren } from 'react'

interface IMyListProps extends PropsWithChildren {}

interface IListItemProps extends PropsWithChildren {
  label?: string
}

const MyList: React.FC<IMyListProps> & {
  ListItem: React.FC<IListItemProps>
} = ({ children }) => {
  return <ul className="list">{children}</ul>
}

const ListItem: React.FC<IListItemProps> = ({ children, label }) => {
  const renderLabel = () => {
    if (label) {
      return <h6>{label}</h6>
    }

    return null
  }

  return (
    <li>
      {renderLabel()}
      {children}
    </li>
  )
}

MyList.ListItem = ListItem

export default MyList
