import { action, makeObservable, observable, runInAction } from 'mobx'
import { GetCurrentMonthOption, GetToday } from 'src/Helpers/DateHelpers'
import { ISelectOption } from 'src/Models/Client'
import { IChartResponse } from 'src/Models/Server/Chart/IChartData'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedCurrency: ISelectOption
  SelectedMerchants: ISelectOption[]
  SelectedDate: Date
}

export class Store extends BaseStore<IFormValues> {
  public Currencies: ISelectOption[] = []
  public ChartResponse?: IChartResponse = undefined
  public Merchants: ISelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      Currencies: observable,
      ChartResponse: observable,
      Merchants: observable,
      Initialize: action,
      GetCurrencyChargeDayReport: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    //keep state when back clicked

    if (this.ViewModel === undefined) {
      this.ChartResponse = undefined
      ReportController.GetDefaultFilterV2()
        .then((response) => {
          runInAction(() => {
            const currencyOptions = ToSelectOptions<number>(response.Currencies)
            const merchantOptions = ToSelectOptions<number>(response.Merchants)

            this.Currencies = currencyOptions
            this.Merchants = merchantOptions

            this.ViewModel = {
              SelectedCurrency: currencyOptions[0],
              SelectedMerchants: merchantOptions,
              SelectedDate: GetToday(),
            }

            this.GetCurrencyChargeDayReport(this.ViewModel)
            this.IsLoading = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }

  public GetCurrencyChargeDayReport(data: IFormValues) {
    const currencyId = data.SelectedCurrency.value
    //const merchantIds = data.SelectedMerchants.map((x) => x.value)
    ReportController.GetCurrencyChargeDayReport(
      currencyId,
      data.SelectedMerchants.map((x) => x.value),
      data.SelectedDate
    )
      .then((result) => {
        runInAction(() => {
          this.ChartResponse = result
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const currencyChargeDayReportStore = new Store()
