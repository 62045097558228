import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import { MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { MyRegExps } from 'src/Models/Client'
import IAddManagerUserRequest from 'src/Models/Server/Request/Merchant/IAddManagerUserRequest'
import { appUiStore } from 'src/Stores/AppUiStore'
import { addManagerUserStore, FormModes } from './Store'

interface IProps {
  onClose: () => void
  onSuccess: () => void
}

//IAddManagerUserRequest

const AddManagerUserForm: React.FC<IProps> = ({ onClose, onSuccess }) => {
  const { ViewModel, IsSubmitting, PageAlert } = addManagerUserStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IAddManagerUserRequest> =
    useForm<IAddManagerUserRequest>()
  const { handleSubmit, reset } = useFormProps

  if (ViewModel === undefined) {
    return null
  }

  const onSubmit = (data: IAddManagerUserRequest) => {
    addManagerUserStore
      .AddManagerUser(data)
      .then(() => {
        onClose()
        onSuccess()
        appUiStore.ShowSuccesToast('Manager login aangemaakt.')
      })
      .catch()
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <McButton
          bsSize="xs"
          onClick={() => {
            addManagerUserStore.SetFormMode(FormModes.FindUser)
          }}
        >
          &lt; {t.common.email}
        </McButton>
        <MyTextbox
          formObject={ViewModel}
          label={t.addManagerUserModal.fields.Name.label}
          name="Name"
          useFormProps={useFormProps}
          rules={{
            required: t.addManagerUserModal.fields.Name.errors.required,
          }}
          maxLength={50}
          placeholder="Jan Jansen"
          tabIndex={1}
        />
        <MyTextbox
          formObject={ViewModel}
          label={t.addManagerUserModal.fields.Email.label}
          name="Email"
          useFormProps={useFormProps}
          readOnly={true}
          rules={{
            required: t.addManagerUserModal.fields.Email.errors.required,
            pattern: {
              value: MyRegExps.EmailAdress,
              message: t.addManagerUserModal.fields.Email.errors.regex,
            },
          }}
          maxLength={100}
          placeholder="jj@multicard.nl"
        />
        <MyTextbox
          formObject={ViewModel}
          label={t.addManagerUserModal.fields.Password.label}
          name="CreatePassword"
          useFormProps={useFormProps}
          rules={{
            required: t.addManagerUserModal.fields.Password.errors.required,
          }}
          maxLength={4}
          placeholder="abc123"
        />
        <McPageAlert pageAlert={PageAlert} />
      </div>
      <div className="modal-footer">
        <ButtonToolbar className="pull-right">
          <McButton onClick={onClose}>{t.common.cancel}</McButton>
          <McButton bsStyle="primary" type="submit" isLoading={IsSubmitting}>
            {t.common.save}
          </McButton>
        </ButtonToolbar>
      </div>
    </form>
  )
}

export default observer(AddManagerUserForm)
