export const GetBaseUrl = () => {
  //development

  //return ''
  if (
    window.location.port === '3000' ||
    window.location.port === '3001' ||
    window.location.href.indexOf('.app') > -1
  ) {
    return 'https://localhost:44382'
    //return 'https://manager.mybility.nl'
  } else {
    return ''
  }
}
