import React, { useEffect, useReducer } from 'react'
import { Row, Col, FormGroup, ButtonToolbar } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyCheckboxLabel, MyDataTable } from 'src/Components/My'
import { formatCentsAsEuro } from 'src/Helpers/CurrencyHelpers'
import { FormatDateTimeLocalized } from 'src/Helpers/DateHelpers'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { GetFileIdUrl } from 'src/Images'
import { IEventTicketVTO } from 'src/Models/Server/Response/Event/IEventTicketVTO'
import { initialState, reducer, reducerActions } from './Reducer'

const EventDetails: React.FC = () => {
  const params = useParams<'id'>()
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const navigate = useNavigate()
  useEffect(() => {
    actions.initialize(params.id)
  }, [null])

  const { event, eventTickets } = state
  const name = event !== undefined ? GetLocalText(event.Name) : ''
  const renderContent = () => {
    if (event === undefined) {
      return <LoaderIcon IsLoading={true} />
    }

    const renderEventTicketPortlet = () => {
      return (
        <Portlet
          title={t.common.tickets}
          headerContent={
            <ButtonToolbar className="pull-right">
              <McButton
                iconLeft="fa-plus"
                bsStyle="primary"
                bsSize="xs"
                title={t.eventTicket.insert.title}
                onClick={() => navigate(`/eventTicket/${event.EventId}/insert`)}
              >
                {t.common.add}
              </McButton>
            </ButtonToolbar>
          }
        >
          <MyDataTable
            data={eventTickets}
            columns={[
              {
                dataField: 'Name',
                text: t.common.name,
                //sort: true,
                formatter: (data: IEventTicketVTO) => {
                  return GetLocalText(data.Name)
                },
              },
              {
                dataField: 'Price',
                text: t.eventTicket.insert.fields.price.title,
                sort: true,
                formatter: (data: IEventTicketVTO) => {
                  return formatCentsAsEuro(data.Price)
                },
              },
              {
                dataField: 'OnSale',
                text: t.eventTicket.insert.fields.onSale.title,
                sort: true,
                formatter: (data: IEventTicketVTO) => {
                  return (
                    <MyCheckboxLabel
                      checked={data.OnSale}
                      label={t.eventTicket.insert.fields.onSale.title}
                    />
                  )
                },
              },
              {
                dataField: 'button',
                text: '',
                formatter: (data: IEventTicketVTO) => {
                  return (
                    <McButton
                      iconLeft="fa-edit"
                      bsStyle="primary"
                      bsSize="xs"
                      title={t.common.edit}
                      onClick={() =>
                        navigate(
                          `/eventTicket/${event.EventId}/edit/${data.EventTicketId}`
                        )
                      }
                    >
                      {t.common.edit}
                    </McButton>
                  )
                },
              },
            ]}
          />
        </Portlet>
      )
    }

    return (
      <div className="details">
        <Portlet title={t.common.details}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>{t.common.name}</label>
                <span>{name}</span>
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label>{t.event.insert.fields.start.title}</label>
                    <span>{FormatDateTimeLocalized(event.Start)}</span>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label>{t.event.insert.fields.end.title}</label>
                    <span>{FormatDateTimeLocalized(event.End)}</span>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <label>{t.common.description}</label>
                <span>{GetLocalText(event.Description)}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <img src={GetFileIdUrl(event.ImageFileId)} />
            </Col>
          </Row>
        </Portlet>
        {renderEventTicketPortlet()}
      </div>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={name}
          previousPages={[
            {
              to: `/events`,
              title: t.event.index.title,
            },
          ]}
        />
        {renderContent()}
      </MainPage>
    </>
  )
}

export default EventDetails
