import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ButtonRow } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage, MyDataTable } from 'src/Components/My'
import { formatCurrency } from 'src/Helpers/CurrencyHelpers'
import { FormatDateLocalized } from 'src/Helpers/DateHelpers'
import {
  enumToText,
  GetLocalText,
  GetLocalTextDTO,
} from 'src/Helpers/TextHelpers'
import { IColumn } from 'src/Models/Client'
import { LoyaltyRewardTypes, LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import { ILoyaltyRewardVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyRewardVTO'
import { ILoyaltyRuleVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyRuleVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { t } from '../../../../I18n'
import EditArticleModal from './EditArticleModal/EditArticleModal'
import EditLoyaltyProgramModal from './EditLoyaltyProgram/EditLoyaltyProgramModal'
import EditRewardModal from './EditRewardModal/EditRewardModal'
import { loyaltyProgramDetailsStore } from './Store'

const Details: React.FC = () => {
  const params = useParams<'id'>()
  useEffect(() => {
    loyaltyProgramDetailsStore.Initialize(params.id)
  }, [null])

  const {
    ViewModel,
    IsLoading,
    EditLoyaltyProgramRule,
    EditLoyaltyReward,
    EditLoyaltyProgram,
  } = loyaltyProgramDetailsStore

  const renderEditArticleModal = () => {
    if (EditLoyaltyProgramRule === undefined) {
      return null
    }

    return (
      <EditArticleModal
        editLoyaltyProgramRule={EditLoyaltyProgramRule}
        onClose={() => {
          loyaltyProgramDetailsStore.ToggleIsEditArticleRuleVisible(undefined)
        }}
      />
    )
  }

  const renderEditLoyaltyProgramModal = () => {
    if (EditLoyaltyProgram === undefined) {
      return null
    }

    return (
      <EditLoyaltyProgramModal
        editLoyaltyProgram={EditLoyaltyProgram}
        onClose={() => {
          loyaltyProgramDetailsStore.ToggleIsEditLoyaltyProgramVisible(
            undefined
          )
        }}
      />
    )
  }

  const renderEditRewardModal = () => {
    if (EditLoyaltyReward === undefined) {
      return null
    }

    return (
      <EditRewardModal
        editLoyaltyReward={EditLoyaltyReward}
        onClose={() => {
          loyaltyProgramDetailsStore.ToggleIsEditRewardVisible(undefined)
        }}
      />
    )
  }

  const renderRules = () => {
    const columns: IColumn<ILoyaltyRuleVTO>[] = [
      {
        text: t.common.type,
        formatter: (rule: ILoyaltyRuleVTO) => {
          return (
            <>
              {enumToText(
                rule.LoyaltyRuleTypeId,
                LoyaltyRuleTypes,
                t.enums.LoyaltyRuleTypes
              )}
            </>
          )
        },
      },
      {
        text: t.loyaltyprogram.details.runWhen,
        formatter: (rule: ILoyaltyRuleVTO) => {
          let text = ''
          let showEditArticle = false
          switch (rule.LoyaltyRuleTypeId) {
            case LoyaltyRuleTypes.Article:
              showEditArticle = true //edit articleGroup
              text = t
                .formatString(t.loyaltyprogram.details.ruleRunOn.Article, {
                  name: ViewModel.Name,
                  articleCode: rule.Article,
                })
                .toString()
              break

            case LoyaltyRuleTypes.ArticleGroup:
              showEditArticle = true //edit articleGroup
              text = t
                .formatString(t.loyaltyprogram.details.ruleRunOn.ArticleGroup, {
                  name: ViewModel.Name,
                  articleGroup: rule.ArticleGroup,
                })
                .toString()
              break
            case LoyaltyRuleTypes.TransactionAmountNegative:
              text = t
                .formatString(
                  t.loyaltyprogram.details.ruleRunOn.TransactionAmountNegative,
                  {
                    currencyName: GetLocalTextDTO(rule.Currency.Description),
                  }
                )
                .toString()
              break
            case LoyaltyRuleTypes.RedeemCoupon:
              text = t
                .formatString(t.loyaltyprogram.details.ruleRunOn.RedeemCoupon, {
                  couponTitle: GetLocalText(rule.Coupon.TitleLocalizedString),
                })
                .toString()
              break
          }

          const renderEditButton = () => {
            if (!showEditArticle) {
              return null
            }

            return (
              <McButton
                iconLeft="fa-edit"
                bsStyle="primary"
                bsSize="xs"
                title={t.common.edit}
                pullRight
                onClick={() => {
                  loyaltyProgramDetailsStore.ToggleIsEditArticleRuleVisible({
                    loyaltyProgram: ViewModel,
                    loyaltyRuleId: rule.LoyaltyRuleId,
                  })
                }}
              >
                {t.common.edit}
              </McButton>
            )
          }

          return (
            <>
              {text} {renderEditButton()}
            </>
          )
        },
      },
    ]

    return <MyDataTable data={ViewModel.LoyaltyRules} columns={columns} />
  }

  const renderRewards = () => {
    const renderEditButton = (reward: ILoyaltyRewardVTO) => {
      return (
        <McButton
          iconLeft="fa-edit"
          bsStyle="primary"
          bsSize="xs"
          title={t.common.edit}
          pullRight
          onClick={() => {
            loyaltyProgramDetailsStore.ToggleIsEditRewardVisible({
              loyaltyProgramId: ViewModel.LoyaltyProgramId,
              loyaltyReward: reward,
            })
          }}
        >
          {t.common.edit}
        </McButton>
      )
    }

    const columns: IColumn<ILoyaltyRewardVTO>[] = [
      {
        text: t.common.reward,
        formatter: (reward: ILoyaltyRewardVTO) => {
          switch (reward.LoyaltyRewardTypeId) {
            case LoyaltyRewardTypes.CouponReward:
              return (
                <>
                  <b>{reward.Amount}</b>{' '}
                  {GetLocalText(reward.Coupon.TitleLocalizedString)}
                  {renderEditButton(reward)}
                </>
              )

            case LoyaltyRewardTypes.CurrencyOrderPercentageReward: {
              const percentage = reward.Percentage * 100
              return (
                <>
                  <b>{percentage}%</b>{' '}
                  {GetLocalTextDTO(reward.Currency.Description)}
                  {renderEditButton(reward)}
                </>
              )
            }
            case LoyaltyRewardTypes.CurrencyStaticReward: {
              return (
                <>
                  <b>
                    {formatCurrency(
                      reward.Amount,
                      reward.Currency.IsDecimal,
                      reward.Currency.StringFormat
                    )}
                  </b>{' '}
                  {GetLocalTextDTO(reward.Currency.Description)}
                  {renderEditButton(reward)}
                </>
              )
            }
            default:
              return <></>
          }
        },
      },
    ]

    return <MyDataTable data={ViewModel.LoyaltyRewards} columns={columns} />
  }

  const renderDeleteButton = () => {
    const rule = ViewModel.LoyaltyRules.find(
      (x) =>
        x.LoyaltyRuleTypeId === LoyaltyRuleTypes.Article ||
        x.LoyaltyRuleTypeId === LoyaltyRuleTypes.ArticleGroup
    )

    if (rule === undefined) {
      return null
    }

    return (
      <McButton
        iconLeft="fa-times"
        bsStyle="danger"
        bsSize="xs"
        title={t.common.delete}
        onClick={() => {
          appUiStore.ShowConfirm({
            message: t.loyaltyprogram.details.confirmDelete,
            confirmActionText: t.common.delete,
            confirmAction: () => {
              loyaltyProgramDetailsStore.DeleteLoyaltyProgram()
            },
          })
        }}
      >
        {t.common.delete}
      </McButton>
    )
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />
      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={ViewModel.Name}
            previousPages={{
              to: '/loyaltyprograms',
              title: t.common.loyaltyPrograms,
            }}
          />

          <Portlet title={t.common.loyaltyProgram}>
            <Row className="details">
              <Col md={6}>
                <FormGroup>
                  <label>{t.common.name}</label>
                  <span>{ViewModel.Name}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="details">
              <Col md={6}>
                <FormGroup>
                  <label>{t.common.validFrom}</label>
                  <span>{FormatDateLocalized(ViewModel.StartDate)}</span>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label>{t.common.validTill}</label>
                  <span>{FormatDateLocalized(ViewModel.EndDate)}</span>
                </FormGroup>
              </Col>
            </Row>
            <ButtonRow align="right">
              <McButton
                iconLeft="fa-edit"
                bsStyle="primary"
                bsSize="xs"
                title={t.common.edit}
                onClick={() => {
                  loyaltyProgramDetailsStore.ToggleIsEditLoyaltyProgramVisible(
                    ViewModel
                  )
                }}
              >
                {t.common.edit}
              </McButton>
              {renderDeleteButton()}
            </ButtonRow>
          </Portlet>
          <Portlet title={t.loyaltyprogram.details.rules}>
            {renderRules()}
          </Portlet>
          <Portlet title={t.common.reward}>{renderRewards()}</Portlet>
        </Fragment>
      )}
      {renderEditArticleModal()}
      {renderEditRewardModal()}
      {renderEditLoyaltyProgramModal()}
    </MainPage>
  )
}

export default observer(Details)
