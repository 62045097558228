import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { IConfirmMessage } from 'src/Components/Modals'
import IGenericError from 'src/Models/Server/IGenericError'

export class Store {
  public Error: IGenericError = null
  public ConfirmMessage: IConfirmMessage = null
  public IsConfirming = null
  public OpenTopMenu: string = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public ShowError(error: IGenericError) {
    this.Error = error
  }

  public HideError() {
    this.Error = null
  }

  public ShowConfirm(confirmMessage: IConfirmMessage) {
    this.ConfirmMessage = confirmMessage

    const originalConfirmAction = this.ConfirmMessage.confirmAction
    this.ConfirmMessage.confirmAction = () => {
      originalConfirmAction()
      this.HideConfirm()
    }
  }

  public HideConfirm() {
    this.ConfirmMessage = null
    this.IsConfirming = false
  }

  public ShowSuccesToast(text: string) {
    toast.success(text)
  }

  public ToggleOpenTopMenu(key: string) {
    this.OpenTopMenu = key
  }
}

export const appUiStore = new Store()
