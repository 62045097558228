import React, { memo, useEffect, useState } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton, McPageAlert } from 'src/Components'
import { MyTextbox } from 'src/Components/My'

import { runInAction } from 'mobx'
import { t } from 'src/I18n'
import { MyRegExps } from 'src/Models/Client'
import ErrorCodes from 'src/Models/Server/ErrorCodes'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import { addManagerUserStore, FormModes } from './Store'
import ManagerUserController from 'src/Services/ToprCoreApi/Axios/Controllers/ManagerUserController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { observer } from 'mobx-react'
import {
  AlertType,
  createPageAlert,
  IPageAlert,
} from 'src/Components/McPageAlert'

interface IProps {
  onClose: () => void
  onSuccess: () => void
  merchantGuid: string
}

interface IFormViewModel {
  Email: string
}

const FindUserForm: React.FC<IProps> = ({
  onClose,
  merchantGuid,
  onSuccess,
}) => {
  const useFormProps: UseFormReturn<IFormViewModel> = useForm<IFormViewModel>({
    // defaultValues: {
    //   Email: 'r.de.ruijter2@mybility.nl',
    // },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [pageAlert, setPageAlert] = useState<IPageAlert | undefined>(undefined)

  const { handleSubmit } = useFormProps

  //  const { PageAlert } = addManagerUserStore

  const insertManagerRole = (email: string) => {
    setIsLoading(true)
    ManagerUserController.InsertRole({
      Email: email,
      MerchantGuid: merchantGuid,
      CreatePassword: '',
      Name: '',
    })
      .then(() => {
        onSuccess()
        onClose()
        appUiStore.ShowSuccesToast('Manager login aangemaakt.')
      })
      .catch((error: IExceptionError) => {
        runInAction(() => {
          if (error.Code === ErrorCodes.DATA_MORE_THEN_1_RESULT) {
            addManagerUserStore.SetPageAlert({
              type: AlertType.Danger,
              message: 'Er bestaat al een Manager Role voor deze user.',
            })
          } else {
            appUiStore.ShowError(error)
          }
        })
      })
      .finally(() => setIsLoading(false))
  }

  const onSubmit = (data: IFormViewModel) => {
    setIsLoading(true)
    UserController.GetUserByEmail(data.Email)
      .then((baseUser) => {
        //bestaande user add manager role
        insertManagerRole(data.Email)
      })
      .catch((error: IExceptionError) => {
        runInAction(() => {
          if (error.Code === ErrorCodes.DATA_NO_RESULT) {
            //geen user gevonden, maak nieuwe user aan met manager role
            addManagerUserStore.SetFormMode(FormModes.AddNewManagerUser)
            addManagerUserStore.InitializeAddUserForm(merchantGuid, data.Email)
          } else {
            setPageAlert(createPageAlert(error))
          }
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <MyTextbox
            formObject={{} as IFormViewModel}
            label={t.addManagerUserModal.fields.Email.label}
            name="Email"
            useFormProps={useFormProps}
            rules={{
              required: t.addManagerUserModal.fields.Email.errors.required,
              pattern: {
                value: MyRegExps.EmailAdress,
                message: t.addManagerUserModal.fields.Email.errors.regex,
              },
            }}
            maxLength={100}
            placeholder="jj@mybility.nl"
          />
          <McPageAlert pageAlert={pageAlert} />
        </div>
        <div className="modal-footer">
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isLoading}>
              {/* {t.addAppUserModal.checkEmail} */}
              {t.common.save}
            </McButton>
          </ButtonToolbar>
        </div>
      </form>
    </>
  )
}

export default observer(FindUserForm)
