import { observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Portlet from 'src/Components/Authenticated/Portlet'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'

import { FormatDateTimeNL } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { GetFileUrl } from 'src/Images'
import { IAutomaticChargeVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeVTO'
import ManagerUserVTO, {
  IsAdmin,
} from 'src/Models/Server/Response/User/ManagerUserVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import colors from 'src/Style/colors'
import { automaticChargesStore } from './Store'

interface IProps {
  userGuid: string
  email: string
  selectedManagerUser: ManagerUserVTO
  automaticCharges: IAutomaticChargeVTO[]
}

const AutomaticChargesPortlet: React.FC<IProps> = ({
  userGuid,
  email,
  selectedManagerUser,
  automaticCharges,
}) => {
  const navigate = useNavigate()

  const getStatusColor = (statusId: number) => {
    switch (statusId) {
      // case 1: // off
      // default:
      //   return '#000'
      case 2: //pending
        return colors.yellow
      case 1: //off
      case 3: //Denied
      case 4: //Blocked
      default:
        return colors.red
      case 5: //EnabledWithVerification
      case 6: //EnabledWithoutVerification
        return colors.green
    }
  }

  const renderAutomaticCharges = () => {
    const columns = [
      {
        dataField: 'CurrencyName',
        text: t.common.currency,
        sort: true,
        formatter: (data: IAutomaticChargeVTO) => {
          return (
            <Fragment>
              <div>
                {data.CurrencyName}
                <span
                  style={{
                    color: getStatusColor(data.AutomaticChargeStatusId),
                  }}
                >
                  {data.AutomaticChargeStatus}
                </span>
              </div>
              <div>
                <label>{t.common.created}: </label>
                {FormatDateTimeNL(data.Created)}
              </div>
              {data.Modified && (
                <div>
                  <label>{t.common.modified}: </label>
                  {FormatDateTimeNL(data.Modified)}
                </div>
              )}
            </Fragment>
          )
        },
      },
      {
        dataField: 'infoTab',
        text: t.automaticChargesPortlet.chargeSettings,
        isDummyField: true,
        formatter: (data: IAutomaticChargeVTO) => {
          return (
            <Fragment>
              <div>
                <label>{t.automaticChargesPortlet.stepAmount}: </label>
                {data.StepAmountFormatted}
              </div>
              <div>
                <label>{t.automaticChargesPortlet.stepLimit}: </label>
                {data.StepLimit}
              </div>
            </Fragment>
          )
        },
      },
      {
        dataField: 'userData',
        text: t.common.info,
        isDummyField: true,
        formatter: (data: IAutomaticChargeVTO) => {
          return (
            <Fragment>
              <div>
                <label>{t.common.lastName}: </label>
                {data.LastName}
              </div>
              <div>
                <label>{t.common.email}: </label>
                {data.EmailAddress}
              </div>
              <div>
                <label>{t.common.address}: </label>
                {data.StreetName} {data.StreetNumber} {data.Zipcode} {data.City}
              </div>
            </Fragment>
          )
        },
      },
      {
        dataField: 'bankData',
        text: t.common.bank,
        isDummyField: true,
        formatter: (data: IAutomaticChargeVTO) => {
          return (
            <Fragment>
              <div>
                <label>{t.common.name}: </label>
                {data.AccountHolder}
              </div>
              <div>
                <label>{t.common.accountNumber}: </label>
                {data.AccountNumber}
              </div>
              {data.SepaFileGuid && (
                <Fragment>
                  {IsAdmin(selectedManagerUser) &&
                    (data.AutomaticChargeStatusId === 5 ||
                      data.AutomaticChargeStatusId === 6) && (
                      <Fragment>
                        <ButtonToolbar>
                          <McButton
                            bsSize="xs"
                            iconLeft="fa-edit"
                            bsStyle="primary"
                            onClick={() => {
                              navigate(
                                `/automaticcharge/edit/${data.AutomaticChargeId}/${userGuid}`
                              )
                            }}
                          >
                            {t.common.edit}
                          </McButton>
                          <McButton
                            bsSize="xs"
                            iconLeft="fa-ban"
                            bsStyle="danger"
                            onClick={() =>
                              appUiStore.ShowConfirm({
                                message: t.formatString(
                                  t.automaticChargesPortlet.turnOff
                                    .confirmMessage,
                                  { currencyName: data.CurrencyName }
                                ),
                                confirmAction: () =>
                                  automaticChargesStore.TurnOff(
                                    data.AutomaticChargeId,
                                    userGuid,
                                    data.CurrencyGuid
                                  ),
                                confirmActionText:
                                  t.automaticChargesPortlet.turnOff.action,
                              })
                            }
                          >
                            {t.automaticChargesPortlet.turnOff.action}
                          </McButton>
                        </ButtonToolbar>
                        <hr />
                        <ButtonToolbar>
                          <a
                            href={GetFileUrl(data.SepaFileGuid)}
                            className="btn btn-xs btn-default"
                            target="_blank"
                            download
                          >
                            <i className={`fa fa-file-contract`} /> SEPA
                            Contract
                          </a>
                          {data.LastAutomaticChargeFile && (
                            <a
                              href={GetFileUrl(
                                data.LastAutomaticChargeFile.FileGuid
                              )}
                              download
                              className="btn btn-xs btn-default"
                              target="_blank"
                            >
                              <i className={`fa fa-file-contract`} />{' '}
                              {t.automaticChargeEdit.sepaChangeForm}
                            </a>
                          )}
                        </ButtonToolbar>
                      </Fragment>
                    )}
                </Fragment>
              )}
            </Fragment>
          )
        },
      },
    ]

    return <MyDataTable data={automaticCharges} columns={columns} />
  }

  const renderInsertSepaButton = () => {
    if (IsAdmin(selectedManagerUser)) {
      return (
        <Link
          className="btn btn-xs btn-primary pull-right"
          to={`/automaticcharge/insert/${userGuid}/${email}`}
        >
          {t.automaticChargeInsert.title}
        </Link>
      )

      /*
        <ButtonToolbar className="pull-right">
        <McButton
          iconLeft="fa-plus"
          bsStyle="primary"
          bsSize="xs"
          title={t.common.add}
          onClick={() => navigate(`/user/schedule/bonus/insert/${params.id}`)}
        >
          {t.common.add}
        </McButton>
      </ButtonToolbar>
      */
    }

    return null
  }

  return (
    <Portlet title="AutomaticCharge" headerContent={renderInsertSepaButton()}>
      {renderAutomaticCharges()}
    </Portlet>
  )
}

export default observer(AutomaticChargesPortlet)
