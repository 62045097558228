import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyChart, MyDatePicker, MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { currencyChargeDayReportStore, IFormValues } from './Store'
interface IProps {}

const CurrencyChargeDayReport: React.FC<IProps> = (props) => {
  useEffect(() => {
    currencyChargeDayReportStore.Initialize()
  }, [null])
  const { ViewModel, IsLoading, ChartResponse, Currencies, Merchants } =
    currencyChargeDayReportStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderFilter = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading />
    }

    return (
      <Row>
        <Col md={6}>
          <MyDatePicker
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedDate"
            label={t.common.day}
            onDateChange={(date) => {
              setValue('SelectedDate', date)
              handleSubmit((data) =>
                currencyChargeDayReportStore.GetCurrencyChargeDayReport(data)
              )()
            }}
          />
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedCurrency"
            label={t.common.currencies}
            options={Currencies}
            onSelectionChange={(options: ISelectOption) => {
              setValue('SelectedCurrency', options)
              handleSubmit((data) =>
                currencyChargeDayReportStore.GetCurrencyChargeDayReport(data)
              )()
            }}
          />
        </Col>
        <Col md={6}>
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMerchants"
            label={t.common.merchants}
            options={Merchants}
            isMulti
            onSelectionChange={(options: ISelectOption[]) => {
              setValue('SelectedMerchants', options)
              handleSubmit((data) =>
                currencyChargeDayReportStore.GetCurrencyChargeDayReport(data)
              )()
            }}
          />
        </Col>
      </Row>
    )
  }

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t
          .formatString(t.currencyCharge.title, t.common.day)
          .toString()}
      />
      <Portlet title={t.common.filter}>
        <form className="parsley-form">{renderFilter()}</form>
      </Portlet>
      <MyChart
        chartResponse={ChartResponse}
        // onDetailsClick={(id) => {
        //   navigate(
        //     `/report/payment/day/merchant/${id}/${data.SelectedCurrency.value}/${data.SelectedMonth.value}`
        //   )
        // }}
      />
    </MainPage>
  )
}

export default observer(CurrencyChargeDayReport)
