import React, { useState } from 'react'
import { IColumn } from 'src/Models/Client'
import { Sorting } from './Reducer'
interface IProps {
  column: IColumn<any>
  onSortChanged: (newSorting: Sorting) => void
  sorting: Sorting
  onFilterChanged: (filter: string) => void
}

const MyColumn: React.FC<IProps> = ({
  column,
  onSortChanged,
  sorting,
  onFilterChanged,
}) => {
  const [filter, setFilter] = useState('')
  const getSortingBoolean = (): boolean | undefined => {
    let result: boolean = undefined

    if (sorting !== undefined && sorting.dataField == column.dataField) {
      result = sorting.order === 'asc'
    }

    return result
  }

  //toggle sort bool
  const sortingBool = getSortingBoolean()

  const renderSortingIcons = () => {
    if (column.sort === false || column.sort === undefined) {
      return null
    }
    switch (sortingBool) {
      case true:
        return (
          <span className="react-bootstrap-table-sort-order dropup">
            <span className="caret"></span>
          </span>
        )
      case false:
        return (
          <span className="react-bootstrap-table-sort-order">
            <span className="caret"></span>
          </span>
        )

      default:
        return (
          <span className="order">
            <span className="dropdown">
              <span className="caret" />
            </span>
            <span className="dropup">
              <span className="caret" />
            </span>
          </span>
        )
        break
    }
  }
  const sortableClassName = column.sort === true ? 'sortable' : ''
  const renderFilterInput = () => {
    if (!column.filter) {
      return null
    }

    return (
      <input
        type="text"
        value={filter}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.currentTarget.value)
          onFilterChanged(e.currentTarget.value)
        }}
        placeholder={column.text}
        className="form-control"
      />
    )
  }
  return (
    <th
      className={`${sortableClassName}`}
      onClick={() => {
        if (column.sort === true) {
          //default to true else togggle sort
          const newSort = sortingBool === undefined ? true : !sortingBool
          onSortChanged({
            dataField: column.dataField as string,
            order: newSort ? 'asc' : 'desc',
          })
        }
      }}
    >
      {column.text}
      {renderSortingIcons()}
      {renderFilterInput()}
    </th>
  )
}

export default MyColumn
