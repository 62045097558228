import React, { memo } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton } from 'src/Components'
import { MyCheckbox, MySelect } from 'src/Components/My'

import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { IMerchantCurrencyVTO } from 'src/Models/Server/Response/Currency/IMerchantCurrencyVTO'
import { useGetActiveMerchantsQuery } from 'src/Services/ToprCoreApi/RTK/Slices/MerchantSlice'
interface IProps {
  closeClicked: () => void
  onSubmit: (data: IFormValues) => void
  isLoading: boolean
  editItem?: IMerchantCurrencyVTO
  merchantCurrencies: IMerchantCurrencyVTO[]
}

interface IFormValues {
  Merchant: ISelectOption
  AutoJoin: boolean
  InnzziiOrderPayment: boolean
}

const MerchantCurrencyForm: React.FC<IProps> = ({
  closeClicked,
  onSubmit,
  isLoading,
  editItem,
  merchantCurrencies,
}) => {
  const isEdit = editItem !== undefined

  const { data: merchants, isLoading: isLoadingMerchants } =
    useGetActiveMerchantsQuery()

  const getMerchantOptions = (): ISelectOption[] => {
    if (merchants === undefined) {
      return []
    }

    return merchants
      .filter(
        (x) =>
          merchantCurrencies.findIndex((mc) => mc.MerchantID == x.Id) === -1
      )
      .map<ISelectOption>((m) => ({
        label: m.Name,
        value: m.Id.toString(),
      }))
  }

  let myDefaultValues: Partial<IFormValues> = {
    AutoJoin: true,
    InnzziiOrderPayment: false,
  }

  if (isEdit) {
    myDefaultValues = {
      AutoJoin: editItem.AutoJoin,
      InnzziiOrderPayment: editItem.InnzziiOrderPayment,
      //no merchant on edit
    }
  }
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: myDefaultValues,
  })
  const { handleSubmit } = useFormProps

  //get merchant options, filter editItem.MerchantId eruit

  const renderMerchantsSelect = () => {
    if (isEdit) {
      return null
    }
    return (
      <MySelect
        formObject={{} as IFormValues}
        useFormProps={useFormProps}
        label={'Merchant'}
        name="Merchant"
        rules={{
          required: 'Selecteer een merchant',
        }}
        options={getMerchantOptions()}
      />
    )
  }

  const buttonText = isEdit ? t.common.edit : t.common.add

  if (isLoadingMerchants) {
    return <LoaderIcon IsLoading={true} />
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        {renderMerchantsSelect()}
        <MyCheckbox
          formObject={{} as IFormValues}
          label={
            'AutoJoin (voegt currency toe wanneer de merchant wordt toegevoegd in ToprWallet)'
          }
          name="AutoJoin"
          useFormProps={useFormProps}
        />
        <MyCheckbox
          formObject={{} as IFormValues}
          label={'Inzzii (Er kan met deze currency betaald worden in inzzii)'}
          name="InnzziiOrderPayment"
          useFormProps={useFormProps}
        />
      </div>
      <div className="modal-footer">
        <ButtonToolbar className="pull-right">
          <McButton onClick={closeClicked}>{t.common.cancel}</McButton>
          <McButton bsStyle="primary" type="submit" isLoading={isLoading}>
            {buttonText}
          </McButton>
        </ButtonToolbar>
      </div>
    </form>
  )
}

export default memo<IProps>(MerchantCurrencyForm)
