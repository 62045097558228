//import IErrorMessage from 'src/Models/IErrorMessage'
import { AxiosError, AxiosResponse } from 'axios'
import IGenericError from 'src/Models/Server/IGenericError'
import IGenericResult from 'src/Models/Server/IGenericResult'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { persistedStore } from 'src/Stores/PersistedStore'
import { t } from '../I18n'
import ErrorCodes from 'src/Models/Server/ErrorCodes'

export const handleResponse = <T = {}>(
  response: AxiosResponse<IGenericResult<T>>,
  resolve: (result?: T) => void,
  reject: (error: IGenericError) => void
) => {
  if (response.data.Error === null) {
    resolve(response.data.Model)
  } else {
    reject(response.data.Error)
    console.log(response.data.Error, response.data.Error.Code)
  }
}

export const getExceptionError = (ex: AxiosError): IExceptionError => {
  //console.log(ex)
  if (ex.response) {
    switch (ex.response.status) {
      case 401:
        persistedStore.Logoff()
        break
      case 403:
        break
      case 404:
        return { Code: ErrorCodes.DATA_NO_RESULT, Message: ex.message }

      default:
        break
    }

    if (ex.response.data) {
      return ex.response.data
    }
  }

  if (ex.message) {
    return { Code: 'Unhandled_Exception', Message: ex.message }
  }

  return t.common.serverErrors.defaultError
}

//when authenticated
export const handleException = (
  ex: AxiosError,
  reject: (error: IExceptionError) => void
) => {
  reject(getExceptionError(ex))
}
