import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'

import ActiveIcon from 'src/Components/Icons/ActiveIcon'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'

import { toast } from 'react-toastify'
import { MyDataTable } from 'src/Components/My'
import { CreateHbQrCodesPdf } from 'src/Helpers/CanvasHelpers'
import { t } from 'src/I18n'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import EditTableSamModal from './EditTableSamModal/EditTableSamModal'
import InsertTableSamModal from './InsertTableSamModal/InsertTableSamModal'
import LinkSamModal from './LinkSamModal/LinkSamModal'
import { samsGridStore } from './Store'
import TableQrCodeModal from './TableQrCodeModal/TableQrCodeModal'
interface IProps {
  terminalId: string
}

const SamsGrid: React.FC<IProps> = ({ terminalId }) => {
  useEffect(() => {
    samsGridStore.GetSamsByTerminal(terminalId)
  }, [null])

  const {
    ViewModel,
    IsLoading,
    TerminalId,
    ShowLinkSam,
    ShowInsertTableSamModal,
    EditTableNumberSam,
    QrSam,
    TableSams,
  } = samsGridStore

  const actionsCellFormatter = (data: ISamVTO) => {
    const isSubmitting =
      samsGridStore.EditSamId === data.SamId && samsGridStore.IsSubmitting
    return (
      <ButtonToolbar>
        {data.IsBlocked && (
          <McButton
            iconLeft="fa-check"
            bsSize="small"
            bsStyle="default"
            isLoading={isSubmitting}
            onClick={() => {
              samsGridStore.UnblockSam(data.SamId)
            }}
          >
            {t.sam.unblock.title}
          </McButton>
        )}
        {!data.IsBlocked && (
          <McButton
            iconLeft="fa-ban"
            bsSize="small"
            bsStyle="danger"
            isLoading={isSubmitting}
            onClick={() => {
              samsGridStore.BlockSam(data.SamId)
            }}
          >
            {t.sam.block.title}
          </McButton>
        )}
        <McButton
          iconLeft="fa-ban"
          bsSize="small"
          bsStyle="danger"
          isLoading={isSubmitting}
          onClick={() => {
            appUiStore.ShowConfirm({
              message: t.formatString(t.sam.unlink.confirmMessage, {
                SamId: data.SamId,
              }) as string,

              confirmAction: () => samsGridStore.Unlink(data.SamId),
              confirmActionText: t.sam.unlink.action,
            })
          }}
        >
          {t.sam.unlink.action}
        </McButton>
      </ButtonToolbar>
    )
  }

  const tableCellFormatter = (data: ISamVTO) => {
    if (data.TableNumber === null) {
      return <></>
    }

    return (
      <>
        <ButtonToolbar>
          <span className="pull-left">{data.TableNumber}</span>
          <McButton
            iconLeft="fa-edit"
            bsSize="xsmall"
            bsStyle="default"
            onClick={() => {
              samsGridStore.SetEditTableNumberSam(data)
            }}
          >
            {t.common.edit}
          </McButton>
          <McButton
            iconLeft="fa-qrcode"
            bsSize="xsmall"
            bsStyle="default"
            onClick={() => {
              //CreateHbQrCodes(TableSams.filter((x) => x.Uid === data.Uid))
              samsGridStore.SetQrSam(data)
            }}
          >
            QR
          </McButton>
        </ButtonToolbar>
      </>
    )
  }

  const samCellFormatter = (data: ISamVTO) => {
    return <ActiveIcon active={!data.IsBlocked} text={data.SamId.toString()} />
  }

  const columns = [
    {
      dataField: 'SamId',
      text: 'SamId',
      formatter: samCellFormatter,
    },
    {
      dataField: 'Uid',
      text: 'Uid',
    },
    {
      dataField: 'SamTypeName',
      text: t.sam.insert.fields.SamTypeID.label,
    },
    {
      dataField: 'TableNumber',
      text: t.sam.insertTableSam.fields.TableNumber.label,
      formatter: tableCellFormatter,
    },

    {
      dataField: 'actions',
      csvExport: false,
      text: '',
      formatter: actionsCellFormatter,
    },
  ]

  return (
    <>
      {/* <div className="myStyle">Scan de QR en bestel!</div>
      <div className="line1">EENVOUDIG BESTELLEN EN AFREKENEN MET</div>
      <div className="line2">DEZE TAFEL IS GERESERVEERD VOOR:</div> */}
      <ButtonToolbar className="pull-left">
        <McButton
          bsStyle="primary"
          type="submit"
          onClick={() => samsGridStore.InsertSam()}
          isLoading={samsGridStore.IsSubmitting}
        >
          {t.sam.insert.title}
        </McButton>
        <McButton
          bsStyle="default"
          type="submit"
          onClick={() => samsGridStore.ToggleLinkSamModal(true)}
        >
          {t.sam.link.title}
        </McButton>
        <McButton
          bsStyle="default"
          type="submit"
          onClick={() => samsGridStore.ToggleInsertTableSamModal(true)}
        >
          {t.sam.insertTableSam.title}
        </McButton>

        {TableSams.length > 0 && (
          <McButton
            bsStyle="default"
            type="submit"
            className="pull-right"
            onClick={() => {
              if (TableSams.length === 0) {
                toast.info('Er zijn geen Tafel Qr codes')
                return
              }

              // const canvas = CreateQrCodeCanvas(
              //   '123',
              //   CreateTableQrCode('123', '12')
              // )

              // const output = document.getElementById('ouput')
              // output.appendChild(canvas)

              CreateHbQrCodesPdf(TableSams)
            }}
          >
            Export table qr codes
          </McButton>
        )}
      </ButtonToolbar>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}
      {ShowLinkSam && (
        <LinkSamModal
          terminalId={TerminalId}
          onClose={() => samsGridStore.ToggleLinkSamModal(false)}
          onSubmitSuccess={() => samsGridStore.GetSamsByTerminal(TerminalId)}
        />
      )}
      {ShowInsertTableSamModal && (
        <InsertTableSamModal
          terminalId={TerminalId}
          onClose={() => samsGridStore.ToggleInsertTableSamModal(false)}
          onSubmitSuccess={() => samsGridStore.GetSamsByTerminal(TerminalId)}
        />
      )}

      {EditTableNumberSam !== undefined && (
        <EditTableSamModal
          editSam={EditTableNumberSam}
          onClose={() => samsGridStore.SetEditTableNumberSam(undefined)}
          onSubmitSuccess={() => samsGridStore.GetSamsByTerminal(TerminalId)}
        />
      )}

      {QrSam !== undefined && (
        <TableQrCodeModal
          qrSam={QrSam}
          onClose={() => samsGridStore.SetQrSam(undefined)}
        />
      )}
    </>
  )
}

export default observer(SamsGrid)
