import { ISelectOption } from 'src/Models/Client'
import {
  ChargePaymentMethodTypes,
  PaymentMethodTypes,
} from 'src/Models/Server/Enums'
import { ICurrencyPaymentMethod } from 'src/Models/Server/Response/Currency/ICurrencyPaymentVTO'
import { ICurrencyV2VTO } from 'src/Models/Server/Response/Currency/ICurrencyV2VTO'

export const getDebtorMerchantOptions = (currency: ICurrencyV2VTO) => {
  if (currency === undefined) {
    return []
  }

  return [
    {
      label: 'Klant',
      value: '',
    },
    {
      label: currency.OwnerMerchant,
      value: currency.MerchantId.toString(),
    },
    {
      label: 'Mybility',
      value: '1',
    },
  ]
}

export const getDebtorName = (options: ISelectOption[], debtorId?: number) => {
  if (debtorId === null || debtorId === undefined) {
    return 'Klant'
  }

  const option = options.find((x) => x.value === debtorId.toString())
  return option === undefined ? debtorId.toString() : option.label
}

export const getPaymentMethodTypesOptions = (): ISelectOption[] => {
  const options: ISelectOption[] = [
    {
      label: `Afwaarderen (Withdraw)`,
      value: PaymentMethodTypes.Withdraw.toString(),
    },
    {
      label: `Automatisch opwaarderen (AutomaticChargeCollection)`,
      value: PaymentMethodTypes.AutomaticChargeCollection.toString(),
    },
  ]

  const chargeOptions = ChargePaymentMethodTypes.map<ISelectOption>((cpm) => ({
    value: cpm.toString(),
    label: `Opwaarderen (${PaymentMethodTypes[cpm]})`,
  }))

  options.push(...chargeOptions)

  return options
}

export const transactionCostsToText = (transactionCosts?: number) => {
  if (transactionCosts !== null) {
    return transactionCosts.toString().replace('.', ',')
  }

  return ''
}

export const HasPaymentMethodType = (
  currencyPaymentMethods: ICurrencyPaymentMethod[],
  paymentMethodTypes: PaymentMethodTypes[]
) => {
  if (currencyPaymentMethods === undefined) {
    return false
  }
  return currencyPaymentMethods.some(
    (cpm) => paymentMethodTypes.indexOf(cpm.PaymentMethodTypeID) > -1
  )

  //return paymentMethodTypes.indexOf(paymentMethodTypeId) > -1
}
