import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import { IFormValues, editTableSamStore } from './Store'

interface IProps {
  onClose: () => void
  onSubmitSuccess: () => void
  editSam: ISamVTO
}

interface IProps {}
const EditTableSamModal: React.FC<IProps> = ({
  onClose,
  onSubmitSuccess,
  editSam,
}) => {
  useEffect(() => {
    editTableSamStore.Initialize()
  }, [null])

  const { IsSubmitting, PageAlert } = editTableSamStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      TableNumber: editSam.TableNumber,
    },
  })
  const { handleSubmit } = useFormProps

  const close = () => {
    if (!editTableSamStore.IsSubmitting) {
      onClose()
    }
  }

  const onSubmit = (data: IFormValues) => {
    const sam: ISamVTO = { ...editSam, TableNumber: data.TableNumber }
    editTableSamStore.EditTableSam(sam).then(() => {
      onSubmitSuccess()
      close()
    })
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.common.edit}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => close()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => close()}
      >
        <MyTextbox
          formObject={{} as IFormValues}
          label={t.sam.insertTableSam.fields.TableNumber.label}
          name="TableNumber"
          useFormProps={useFormProps}
          rules={{
            required: t.sam.insertTableSam.fields.TableNumber.errors.required,
          }}
          tabIndex={1}
        />
      </McModal>
    </form>
  )
}

export default observer(EditTableSamModal)
