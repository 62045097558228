import React, { memo, useState } from 'react'
import { ButtonRow, LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { ConfirmModal } from 'src/Components/Modals'
import McFormModal from 'src/Components/Modals/McFormModal'
import { MyDataTable } from 'src/Components/My'
import { decimalToEuroFormat } from 'src/Helpers/CurrencyHelpers'
import { t } from 'src/I18n'
import { IColumn, ISelectOption } from 'src/Models/Client'
import {
  ChargePaymentMethodTypes,
  PaymentMethodTypes,
} from 'src/Models/Server/Enums'
import { ICurrencyPaymentMethod } from 'src/Models/Server/Response/Currency/ICurrencyPaymentVTO'
import { ICurrencyV2VTO } from 'src/Models/Server/Response/Currency/ICurrencyV2VTO'
import {
  useCreateCurrencyPaymentMethodMutation,
  useDeleteCurrencyPaymentMethodMutation,
  useEditCurrencyPaymentMethodMutation,
} from 'src/Services/ToprCoreApi/RTK/Slices/CurrencyPaymentMethodSlice'
import { useGetPaymentProviderConfgurationListQuery } from 'src/Services/ToprCoreApi/RTK/Slices/CurrencySlice'
import { appUiStore } from 'src/Stores/AppUiStore'
import CpmForm from './CpmForm'
import { getDebtorMerchantOptions, getDebtorName } from './CpmHelpers'

interface IProps {
  currency: ICurrencyV2VTO
  currencyPaymentMethods: ICurrencyPaymentMethod[]
}

const CurrencyPaymentMethodsTable = memo<IProps>(
  ({ currency, currencyPaymentMethods }) => {
    const [insertModalIsVisible, setInsertModalIsVisible] = useState(false)

    const [editCurrencyMethod, setEditCurrencyMethod] = useState<
      undefined | ICurrencyPaymentMethod
    >(undefined)

    const { data: paymentProviderConfgurationList } =
      useGetPaymentProviderConfgurationListQuery()

    const [createCurrencyPaymentMethod, { isLoading: createIsSubmitting }] =
      useCreateCurrencyPaymentMethodMutation()

    const [editCurrencyPaymentMethod, { isLoading: editIsSubmitting }] =
      useEditCurrencyPaymentMethodMutation()

    const [deleteCurrencyPaymentMethod] =
      useDeleteCurrencyPaymentMethodMutation()

    const [deletingCpmId, setDeletingCpmId] = useState(-1)

    const renderTransactionCosts = (data: ICurrencyPaymentMethod) => {
      if (data.FixedTransactionCosts !== null) {
        return <>{decimalToEuroFormat(data.FixedTransactionCosts)} (Fixed)</>
      }

      if (data.PercentageTransactionCosts !== null) {
        return <>{data.PercentageTransactionCosts} % (Percentage)</>
      }

      return null
    }

    const debtorMerchants: ISelectOption[] = getDebtorMerchantOptions(currency)

    const getTypeName = (paymentMethodTypeID: number) => {
      switch (paymentMethodTypeID) {
        case PaymentMethodTypes.AutomaticChargeCollection:
          return `Automatisch opwaarderen (${PaymentMethodTypes[paymentMethodTypeID]})`
        case PaymentMethodTypes.Withdraw:
          return `Afwaarderen (${PaymentMethodTypes[paymentMethodTypeID]})`

        default: {
          const chargeMethodIndex =
            ChargePaymentMethodTypes.indexOf(paymentMethodTypeID)
          if (chargeMethodIndex > -1) {
            return `Opwaarderen (${PaymentMethodTypes[paymentMethodTypeID]})`
          }
          return `Onbekend (${paymentMethodTypeID})`
        }
      }
    }

    const columns: IColumn<ICurrencyPaymentMethod>[] = [
      {
        text: 'Type',
        formatter: (data) => {
          return <>{getTypeName(data.PaymentMethodTypeID)}</>
        },
      },
      {
        text: 'Transactie kosten',
        formatter: (data) => {
          return (
            <>
              {renderTransactionCosts(data)}
              <div>
                betaald door:
                {getDebtorName(debtorMerchants, data.DebtorMerchantID)}
              </div>
            </>
          )
        },
      },
      {
        text: 'Payment provider',
        formatter: (data: ICurrencyPaymentMethod) => {
          const config = paymentProviderConfgurationList.find(
            (x) => x.Id === data.PaymentProviderConfigurationID
          )

          return <>{config === undefined ? 'Geen Config' : config.Name}</>
        },
      },
      {
        text: '',
        formatter: (data: ICurrencyPaymentMethod) => {
          return (
            <ButtonRow>
              <McButton
                bsStyle="primary"
                bsSize="xs"
                type="button"
                onClick={() => {
                  setEditCurrencyMethod(data)
                }}
              >
                {t.common.edit}
              </McButton>
              <McButton
                bsStyle="danger"
                bsSize="xs"
                type="button"
                pullRight
                isLoading={deletingCpmId === data.CurrencyPaymentMethodID}
                onClick={() =>
                  appUiStore.ShowConfirm({
                    message: `Weet je zeker dat je ${
                      PaymentMethodTypes[data.PaymentMethodTypeID]
                    } wilt verwijderen?`,
                    confirmActionText: t.common.delete,
                    confirmAction: () => {
                      setDeletingCpmId(data.CurrencyPaymentMethodID)
                      deleteCurrencyPaymentMethod(data.CurrencyPaymentMethodID)
                        .unwrap()
                        .then(() => {
                          setDeletingCpmId(-1)
                        })
                    },
                  })
                }
              >
                {t.common.delete}
              </McButton>
            </ButtonRow>
          )
        },
      },
    ]

    const renderTable = () => {
      if (
        currencyPaymentMethods === undefined ||
        currency === undefined ||
        paymentProviderConfgurationList === undefined
      ) {
        return <LoaderIcon IsLoading={true} />
      }

      return <MyDataTable data={currencyPaymentMethods} columns={columns} />
    }

    return (
      <Portlet
        title="Currency PaymentMethods"
        headerContent={
          <>
            <McButton
              iconLeft="fa-plus"
              bsStyle="primary"
              bsSize="xs"
              title={`${t.common.currency} ${t.common.add}`}
              pullRight
              onClick={() => setInsertModalIsVisible(true)}
            >
              {t.common.add}
            </McButton>
          </>
        }
      >
        {renderTable()}
        <McFormModal
          title={'Currency Payment method toevoegen'}
          isVisible={insertModalIsVisible}
          closeClicked={() => setInsertModalIsVisible(false)}
        >
          <CpmForm
            closeClicked={() => setInsertModalIsVisible(false)}
            debtorMerchants={debtorMerchants}
            isLoading={createIsSubmitting}
            onSubmit={(data) => {
              createCurrencyPaymentMethod({
                request: {
                  PaymentMethodTypeId: data.PaymentMethodType.value,
                  PaymentProviderConfigurationId: data.PaymentProvider.value,
                  DebtorMerchantId:
                    data.DebtorMerchant.value === ''
                      ? undefined
                      : data.DebtorMerchant.value,
                  FixedTransactionCosts: data.IsFixed
                    ? data.TransactionCosts
                    : undefined,
                  PercentageTransactionCosts: !data.IsFixed
                    ? data.TransactionCosts
                    : undefined,
                },
                currencyId: currency.CurrencyId,
              }).then(() => {
                //
                setInsertModalIsVisible(false)
              })
              //
            }}
            paymentProviderConfgurationList={paymentProviderConfgurationList}
          />
        </McFormModal>
        <McFormModal
          title={'Currency Payment wijzigen'}
          isVisible={editCurrencyMethod !== undefined}
          closeClicked={() => setEditCurrencyMethod(undefined)}
        >
          <CpmForm
            closeClicked={() => setEditCurrencyMethod(undefined)}
            debtorMerchants={debtorMerchants}
            isLoading={editIsSubmitting}
            editCurrencyPaymentMethod={editCurrencyMethod}
            onSubmit={(data) => {
              editCurrencyPaymentMethod({
                CurrencyPaymentMethodId:
                  editCurrencyMethod.CurrencyPaymentMethodID,
                PaymentMethodTypeId: data.PaymentMethodType.value,
                PaymentProviderConfigurationId: data.PaymentProvider.value,
                DebtorMerchantId:
                  data.DebtorMerchant.value === ''
                    ? undefined
                    : data.DebtorMerchant.value,
                FixedTransactionCosts: data.IsFixed
                  ? data.TransactionCosts
                  : undefined,
                PercentageTransactionCosts: !data.IsFixed
                  ? data.TransactionCosts
                  : undefined,
              }).then(() => {
                //
                setEditCurrencyMethod(undefined)
              })
            }}
            paymentProviderConfgurationList={paymentProviderConfgurationList}
          />
        </McFormModal>

        <ConfirmModal
          confirmMessage={appUiStore.ConfirmMessage}
          isConfirming={appUiStore.IsConfirming}
          closeClicked={() => appUiStore.HideConfirm()}
        />
      </Portlet>
    )
  }
)

export default CurrencyPaymentMethodsTable
