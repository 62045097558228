import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import SiedlerCompanyAddModal from './Components/Add/SiedlerCompanyAddModal'
import FieldValueEditModal from './Components/Edit/FieldValueEditModal'
import { siedlerCompaniesStore } from './Store'

const Companies: React.FC = () => {
  useEffect(() => {
    siedlerCompaniesStore.Initialize()
  }, [null])

  const { IsLoading, Companies, FieldValueId, AddFieldValueModalIsVisible } =
    siedlerCompaniesStore

  //grid, companies
  //insert button

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      //  filter: textFilter({ placeholder: t.common.grid.name.placeholder }),
    },
    {
      dataField: 'actions',
      csvExport: false,
      isDummyField: true,
      text: '',
      formatter: (data: INameIdVTO<number>) => {
        return (
          <McButton
            bsStyle="primary"
            bsSize="xs"
            type="button"
            pullRight
            onClick={() => siedlerCompaniesStore.ToggleEditModal(data.Id)}
          >
            {t.common.edit}
          </McButton>
        )
      },
    },
  ]

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.common.company} />
        <Portlet title={t.common.company}>
          <McButton
            bsStyle="primary"
            type="submit"
            onClick={() => siedlerCompaniesStore.ToggleAddModal()}
          >
            {t.common.insert}
          </McButton>
          <LoaderIcon IsLoading={IsLoading} />
          {!IsLoading && <MyDataTable data={Companies} columns={columns} />}
        </Portlet>

        <FieldValueEditModal
          fieldValueId={FieldValueId}
          onSubmitted={() => {
            siedlerCompaniesStore.Initialize()
          }}
          onClose={() => {
            siedlerCompaniesStore.ToggleEditModal(undefined)
          }}
        />
        <SiedlerCompanyAddModal
          isVisible={AddFieldValueModalIsVisible}
          onSubmitted={() => {
            siedlerCompaniesStore.Initialize()
          }}
          onClose={() => {
            siedlerCompaniesStore.ToggleAddModal()
          }}
        />
      </MainPage>
    </>
  )
}

export default observer(Companies)
