import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable, MyDatePicker } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, userBalancesStore } from './Store'

const UserBalances: React.FC = () => {
  useEffect(() => {
    userBalancesStore.Initialize()
  }, [null])

  const { IsLoading, DataTable, ViewModel, Page, PageSize, TotalRows } =
    userBalancesStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue, getValues } = useFormProps

  const renderContent = () => {
    if (ViewModel === undefined) {
      return null
    }

    return (
      <>
        <Portlet title={t.userBalances.title}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyDatePicker
                  name="TillDate"
                  label={t.common.untill}
                  formObject={ViewModel}
                  useFormProps={useFormProps}
                  onDateChange={(newDate) => {
                    setValue('TillDate', newDate)
                    handleSubmit((data) =>
                      userBalancesStore.GetUserBalances(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            {renderTable()}
          </form>
        </Portlet>
      </>
    )
  }

  const renderTable = () => {
    if (IsLoading) {
      return <LoaderIcon IsLoading />
    }

    if (DataTable === undefined) {
      return <>{t.common.noData}</>
    }

    return (
      <MyDataTable
        data={DataTable.Data}
        columns={DataTable.Columns}
        hasDynamicColumns={true}
        serverPaging={{
          page: Page,
          pageSize: PageSize,
          totalRows: TotalRows,
          onPagination: (newPage) => {
            userBalancesStore.SetPagination(getValues(), newPage)
          },

          onPageSizeChange: (newPageSize) => {
            userBalancesStore.SetPageSize(getValues(), newPageSize)
          },
        }}
      />
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={`${t.userBalances.title}`} />
      {renderContent()}
    </MainPage>
  )
}

export default observer(UserBalances)
