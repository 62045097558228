import {
  action,
  makeObservable,
  makeAutoObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'

import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedMerchants: ISelectOption[]
  SelectedCurrencies: ISelectOption[]
  StartDate: Date
  EndDate: Date
}

export default class Store extends BaseStore<IFormValues> {
  public LoadingTransactions: boolean = false

  public Transactions: ITransactionExtendedSTO[] = []
  public Merchants: ISelectOption[] = []
  public Currencies: ISelectOption[] = []

  public CurrentPage = 1
  public TotalDataSize = 1
  public PageSize = 50
  public SortOrder = 'desc'
  public SortField = 'DateFormatted' //defaults

  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      LoadingTransactions: observable,
      Transactions: observable,
      Merchants: observable,
      Currencies: observable,
      CurrentPage: observable,
      TotalDataSize: observable,
      PageSize: observable,
      SortField: observable,
      SortOrder: observable,
      Initialize: action,
      SetPagination: action,
      SetPageSize: action,
      SetSort: action,
      GetTransactionsPaged: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    this.CurrentPage = 1
    ReportController.GetDefaultFilter()
      .then((response) => {
        runInAction(() => {
          const merchantOptions = SelectOptionsFromNameGuidVTO(
            response.Merchants
          )
          const currencyOptions = SelectOptionsFromNameGuidVTO(
            response.Currencies
          )
          const today = new Date()
          this.Merchants = merchantOptions
          this.Currencies = currencyOptions
          this.ViewModel = {
            SelectedMerchants: merchantOptions,

            SelectedCurrencies: currencyOptions,
            StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
            EndDate: new Date(),
          }

          this.GetTransactionsPaged(this.ViewModel)

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public SetPagination(page: number) {
    this.CurrentPage = page
  }

  public SetPageSize(pageSize: number) {
    this.CurrentPage = 1
    this.PageSize = pageSize
  }

  public SetSort(sortField: string, sortOrder: string) {
    this.SortField = sortField
    this.SortOrder = sortOrder
  }

  public GetTransactionsPaged(data: IFormValues) {
    const merchantGuids = data.SelectedMerchants.map((x) => x.value)
    const currencyGuids = data.SelectedCurrencies.map((x) => x.value)
    const startDate = data.StartDate
    const endDate = data.EndDate

    this.LoadingTransactions = true
    ReportController.GetPaymentTransactions(
      merchantGuids,
      currencyGuids,
      startDate,
      endDate,
      this.CurrentPage,
      this.PageSize,
      this.SortField,
      this.SortOrder
    )
      .then((result) => {
        runInAction(() => {
          this.Transactions = result.Rows
          this.TotalDataSize = result.TotalRows
          this.LoadingTransactions = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.LoadingTransactions = false))
        appUiStore.ShowError(error)
      })
  }
}

export const paymentsReportStore = new Store()
