import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { toprCoreApi } from '../ToprCoreApi'

const merchantSlice = toprCoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveMerchants: builder.query<INameIdVTO<number>[], void>({
      query: () => `/api/v2/merchants/active`,
      providesTags: ['Merchant'],
    }),

    //
  }),
})

export const { useGetActiveMerchantsQuery } = merchantSlice
