import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import IAddManagerUserRequest from 'src/Models/Server/Request/Merchant/IAddManagerUserRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { AuthenticateUserResponse } from 'src/Models/Server/Response/User/AuthenticateUserResponse'
import IManagerUserGridVTO from 'src/Models/Server/Response/User/IManagerUserGridVTO'
import ManagerUserVTO from 'src/Models/Server/Response/User/ManagerUserVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetAuthenticateMerchantUser() {
    return new Promise<ManagerUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get('/api/v1/manageruser/authenticated')
          .then((response: AxiosResponse<ManagerUserVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantManagerUsers(guid: string) {
    return new Promise<IManagerUserGridVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/manageruser/merchant/${guid}`)
          .then((response: AxiosResponse<IManagerUserGridVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetManagerUsersForUser(userGuid: string) {
    return new Promise<INameGuidVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/manageruser/merchant/user/${userGuid}`)
          .then((response: AxiosResponse<INameGuidVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Insert(request: IAddManagerUserRequest) {
    return new Promise<IManagerUserGridVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/manageruser/insert`, request)
          .then((response: AxiosResponse<IManagerUserGridVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  InsertRole(request: IAddManagerUserRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/manageruser/insert/role`, request)
          .then((response: AxiosResponse<void>) => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  SelectManagerUser(mainUserGuid: string, userGuid: string) {
    return new Promise<AuthenticateUserResponse>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/authenticate/switch`, {
            MainUserGuid: mainUserGuid,
            UserGuid: userGuid,
          })
          .then((response: AxiosResponse<AuthenticateUserResponse>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Delete(guid: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/manageruser/delete/${guid}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
