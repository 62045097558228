import { observer } from 'mobx-react'
import React, { memo, useState, PropsWithChildren } from 'react'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { appUiStore } from 'src/Stores/AppUiStore'
interface IProps extends PropsWithChildren {
  title: string
  //urls?: string[]
  urls?: string[]
}

const TopMenuListItem: React.FC<IProps> = ({ children, title, urls = [] }) => {
  //const [isOpen, setIsOpen] = useState(false)

  const isOpen = appUiStore.OpenTopMenu === title
  let topMenuListItemState = 'dropdown'

  if (hasMatchedChildren(...urls)) {
    topMenuListItemState += ' active'
  }

  if (isOpen) {
    topMenuListItemState += ' open'
  }

  return (
    <>
      <li className={topMenuListItemState}>
        <a
          className="dropdown-toggle"
          onClick={(e) => {
            e.stopPropagation()
            appUiStore.ToggleOpenTopMenu(isOpen ? undefined : title)

            //  setIsOpen(!isOpen)
          }}
        >
          {title}
          <i className="fa fa-chevron-down" />
        </a>
        {children}
      </li>
    </>
  )
}

export default observer(TopMenuListItem)
