import React, { memo, useEffect, useReducer } from 'react'
import LoaderIcon from 'src/Components/LoaderIcon'
import { IColumn } from 'src/Models/Client'
import MyColumn from './MyColumn'
import Pager from './Pager'
import {
  initialState,
  reducer,
  reducerActions,
  ServerPaging,
  Sorting,
} from './Reducer'

interface IProps {
  columns: IColumn<any>[]
  data: any[]
  defaultPageSize?: 10 | 25 | 50 | 100
  serverPaging?: ServerPaging
  hasDynamicColumns?: boolean
  defaultSorting?: Sorting
  tableClassName?: string
  selectIdDataColumn?: string
  onSelect?: (selectedIds: any[]) => void
  isLoading?: boolean
}

const defaultProps: Partial<IProps> = {
  defaultPageSize: 10,
  hasDynamicColumns: false,
  isLoading: false,
}

const MyDataTable = memo<IProps>((props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  const propsWithDefaults = {
    ...defaultProps,
    ...props,
  }

  const {
    data,
    columns,
    defaultPageSize,
    serverPaging,
    hasDynamicColumns,
    defaultSorting,
    tableClassName,
    selectIdDataColumn,
    onSelect,
    isLoading,
  } = propsWithDefaults

  useEffect(() => {
    actions.initializePage(
      data,
      columns,
      defaultPageSize,
      serverPaging,
      defaultSorting,
      selectIdDataColumn
    )
  }, [data])

  const {
    page,
    pageSize,
    pagedRows,
    sorting,
    totalRows,
    selectedRowIds,
    allRows,
  } = state

  const createHeader = () => {
    const renderSelectAllCell = () => {
      if (selectIdDataColumn === undefined) {
        return null
      }

      const checked = selectedRowIds.length === allRows.length

      return (
        <th>
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              actions.setSelectAll(e.currentTarget.checked, onSelect)
            }}
          />
        </th>
      )
    }

    return (
      <thead>
        <tr>
          {renderSelectAllCell()}
          {columns.map((column, key) => (
            <MyColumn
              key={key}
              column={column}
              sorting={sorting}
              onSortChanged={(newSorting) => {
                actions.setSorting(newSorting)
              }}
              onFilterChanged={(filter) => {
                actions.setFilter(filter, column)
              }}
            />
          ))}
        </tr>
      </thead>
    )
  }

  const renderRows = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading={true} />
    }
    const renderSelectCell = (row: any) => {
      if (selectIdDataColumn === undefined) {
        return null
      }

      const checked = selectedRowIds.indexOf(row[selectIdDataColumn]) > -1

      return (
        <td>
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              actions.setRowSelection(e.currentTarget.checked, row, onSelect)
            }}
          />
        </td>
      )
    }
    return (
      <tbody>
        {pagedRows.map((row, key) => (
          <tr key={key}>
            {renderSelectCell(row)}
            {columns.map((column, key) => {
              if (column.formatter !== undefined) {
                return <td key={key}>{column.formatter(row)}</td>
              }

              if (hasDynamicColumns) {
                //dynamic datatable can have columns like 'Essensbonus 3,10 (10 Stück)'
                //so row[column.dataField] will be row['Essensbonus 3,10 (10 Stück)']  which is invalid
                return (
                  <td key={key}>{row[`${column.dataField.toString()}`]}</td>
                )
              }
              return (
                <td key={key}>{eval(`row.${column.dataField.toString()}`)}</td>
              )
            })}
          </tr>
        ))}
      </tbody>
    )
  }

  return (
    <>
      <table className={`table table-bordered ${tableClassName}`}>
        {createHeader()}
        {renderRows()}
      </table>
      <Pager
        onPageChange={(newPage: number) => {
          actions.setPage(newPage)
          if (serverPaging !== undefined) {
            serverPaging.onPagination(newPage)
          }
        }}
        pageSize={pageSize}
        page={page}
        totalRows={totalRows}
        onPageSizeChange={(newPageSize: number) => {
          actions.setPageSize(newPageSize)
          if (serverPaging !== undefined) {
            serverPaging.onPageSizeChange(newPageSize)
          }
        }}
      />
    </>
  )
})

export default MyDataTable

// MyDataTable.defaultProps = {
//   defaultPageSize: 10,
//   hasDynamicColumns: false,
// }
