import { action, makeObservable, observable, runInAction } from 'mobx'
import IAddManagerUserRequest from 'src/Models/Server/Request/Merchant/IAddManagerUserRequest'
import IManagerUserGridVTO from 'src/Models/Server/Response/User/IManagerUserGridVTO'
import BaseStore from 'src/Stores/BaseStore'

export enum FormModes {
  FindUser,
  AddNewManagerUser,
}

export default class Store extends BaseStore<IAddManagerUserRequest> {
  public FormMode: FormModes = FormModes.FindUser

  public MerchantGuid: string

  constructor() {
    super()
    makeObservable(this, {
      InitializeAddUserForm: action,

      AddManagerUser: action,
      SetFormMode: action,
      FormMode: observable,
      //FindUser: observable,
    })
  }

  public InitializeAddUserForm(merchantGuid: string, email: string) {
    this.BaseInitialize({
      MerchantGuid: merchantGuid,
      CreatePassword: this.generatePincode(),
      Name: '',
      Email: email,
    })
  }

  public AddManagerUser(data: IAddManagerUserRequest) {
    this.IsSubmitting = true

    //console.log('AddManagerUser')

    return new Promise<IManagerUserGridVTO>((resolve, reject) => {
      setTimeout(() => {
        console.log(data)

        runInAction(() => (this.IsSubmitting = false))
        resolve(undefined)
      }, 2000)
      // ManagerUserController.Insert(data)
      //   .then((managerUser) => {
      //     runInAction(() => (this.IsSubmitting = false))
      //     resolve(managerUser)
      //   })
      //   .catch((error: IGenericError) => {
      //     runInAction(() => {
      //       this.PageAlert = createPageAlert(error, ['USER_EMAIL_NOT_UNIQUE'])
      //       this.IsSubmitting = false
      //     })
      //     reject()
      //   })
    })
  }

  public SetFormMode(formMode: FormModes) {
    this.FormMode = formMode
  }

  private generatePincode() {
    return Math.random().toString(36).slice(-6)
  }
}

export const addManagerUserStore = new Store()
