import { ICreateMerchantCurrencyRequest } from 'src/Models/Server/Request/MerchantCurrency/ICreateMerchantCurrencyRequest'
import { IEditMerchantCurrencyRequest } from 'src/Models/Server/Request/MerchantCurrency/IEditMerchantCurrencyRequest'
import { IMerchantCurrencyVTO } from 'src/Models/Server/Response/Currency/IMerchantCurrencyVTO'
import { toprCoreApi } from '../ToprCoreApi'

const merchantCurrencySlice = toprCoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getMerchantCurrencies: builder.query<IMerchantCurrencyVTO[], string>({
      query: (currencyId) => `/api/v1/currency/${currencyId}/merchant`,
      providesTags: ['MerchantCurrency'],
    }),
    createMerchantCurrency: builder.mutation<
      void,
      { request: ICreateMerchantCurrencyRequest; currencyId: string }
    >({
      query: ({ request, currencyId }) => ({
        url: `/api/v1/currency/${currencyId}/merchant/insert`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['MerchantCurrency'],
    }),
    deleteMerchantCurrency: builder.mutation<void, number>({
      query: (merchantCurrencyId) => ({
        url: `/api/v1/currency/merchant/${merchantCurrencyId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MerchantCurrency'],
    }),
    editMerchantCurrency: builder.mutation<
      void,
      { request: IEditMerchantCurrencyRequest; merchantCurrencyId: number }
    >({
      query: ({ request, merchantCurrencyId }) => ({
        url: `/api/v1/currency/merchant/${merchantCurrencyId}/edit`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['MerchantCurrency'],
    }),
    //
  }),
})

export const {
  useGetMerchantCurrenciesQuery,
  useCreateMerchantCurrencyMutation,
  useDeleteMerchantCurrencyMutation,
  useEditMerchantCurrencyMutation,
} = merchantCurrencySlice
