import React, { PropsWithChildren, useEffect } from 'react'

interface IPreviewModalProps {
  title: string
  closeClicked: () => void
  isVisible: boolean
  size?: 'lg' | 'sm'
  className?: string
  closeOnBackdrop?: boolean
}

const McFormModal: React.FC<PropsWithChildren<IPreviewModalProps>> = ({
  title,
  closeClicked,
  children,
  isVisible,
  size,
  className,
  closeOnBackdrop,
}) => {
  if (!isVisible) {
    return null
  }

  const modalSizeClass =
    size === undefined
      ? `modal-dialog ${className}`
      : `modal-dialog ${className} modal-${size}`

  return (
    // <div className="modal-backdrop" onClick={handleCloseClicked}>
    <div
      className="modal-backdrop"
      onClick={(e) => {
        if (closeOnBackdrop) {
          closeClicked()
        }
      }}
    >
      <div className="modal fade in">
        <div className={modalSizeClass} onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={closeClicked}
                aria-hidden="true"
              >
                ×
              </button>
              <h3 className="modal-title">{title}</h3>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

McFormModal.defaultProps = {
  closeOnBackdrop: false,
}

export default McFormModal
