import { observer } from 'mobx-react'
import React, { PropsWithChildren } from 'react'
interface IProps extends PropsWithChildren {
  checked: boolean
  label: string
  pullRight?: boolean
}

const MyCheckboxLabel: React.FC<IProps> = ({
  checked,
  label,
  pullRight,
  children,
}) => {
  return (
    <div className={`checkbox ${pullRight ? 'pull-right' : ''}`}>
      <label title={label}>
        <input type="checkbox" checked={checked} readOnly={true} />
        {label}
      </label>
      {children}
    </div>
  )
}

export default MyCheckboxLabel

MyCheckboxLabel.defaultProps = {
  pullRight: false,
}
