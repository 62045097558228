import React, { InputHTMLAttributes } from 'react'

import { Path, RegisterOptions, UseFormReturn } from 'react-hook-form'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  label: string
  //onlyBottomPadding?: boolean
}

type ICombinedProps<T> = IProps<T> & InputHTMLAttributes<HTMLInputElement>

const MyCheckbox = <ObjectType,>({
  name,
  rules,
  label,
  useFormProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formObject,
  //onlyBottomPadding,
  ...otherProps
}: ICombinedProps<ObjectType>) => {
  const {
    formState: { errors },
    register,
  } = useFormProps

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const hasError = errorMessage !== undefined

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  // const bottomPadClassName =
  //   onlyBottomPadding === true ? 'form-group bottom-pad' : 'form-group'

  return (
    <>
      {/* <div className={bottomPadClassName}> */}
      <div className="checkbox  bottom-pad">
        <label title={otherProps.title}>
          <input type="checkbox" {...register(name, rules)} {...otherProps} />
          {label}
        </label>
        {/* </div> */}
        {errorText()}
      </div>
    </>
  )
}

export default MyCheckbox
