import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable, MyMonthPeriodSelect, MySelect } from 'src/Components/My'
import { FormatDateTimeLocalized } from 'src/Helpers/DateHelpers'
import { sortDate, sortNumber } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import { userWalletsStore } from '../Store'
//import { userDetailsStore } from '../../../../Details/Store'

interface IProps {
  userCurrencies: ISelectOption[]
  transactions: ITransactionExtendedSTO[]
}

export interface ITransactionsFormValues {
  SelectedCurrencies: ISelectOption[]
  StartDate: Date
  EndDate: Date
}

const TransactionsPortlet: React.FC<IProps> = ({
  transactions,
  userCurrencies,
}) => {
  useEffect(() => {
    const today = new Date()
    const data = {
      SelectedCurrencies: userCurrencies, //default select all userCurrencies
      StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
      EndDate: today,
    }
    reset(data)
    userWalletsStore.GetTransactions(data)
  }, [userCurrencies])

  const useFormProps: UseFormReturn<ITransactionsFormValues> =
    useForm<ITransactionsFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const renderTransactions = () => {
    const columns = [
      {
        dataField: 'RequestDateFormatted',
        text: t.common.date,
        sort: true,
        formatter: (userCoupon: ITransactionExtendedSTO) => {
          return FormatDateTimeLocalized(userCoupon.RequestDate)
        },
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'RequestDate')
        },
      },
      {
        dataField: 'AmountFormatted',
        text: t.common.amount,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortNumber(order, rowA, rowB, 'Amount')
        },
      },
      {
        dataField: 'TransactionType',
        text: t.common.type,
        sort: true,
        // filter: textFilter(),
      },
      {
        dataField: 'PosName',
        text: t.common.pos,
        sort: true,
        // filter: textFilter(),
      },
      {
        dataField: 'Currency.Name',
        text: t.common.currencies,
        sort: true,
      },
    ]

    return <MyDataTable data={transactions} columns={columns} />
  }

  const renderContent = () => {
    return (
      <form className="parsley-form">
        <Row>
          <Col md={6}>
            <MySelect
              formObject={{} as ITransactionsFormValues}
              useFormProps={useFormProps}
              label={t.common.currencies}
              name="SelectedCurrencies"
              options={userCurrencies}
              isMulti
              onSelectionChange={(selectedCurrencyOptions: ISelectOption[]) => {
                setValue('SelectedCurrencies', selectedCurrencyOptions)
                handleSubmit((data) => userWalletsStore.GetTransactions(data))()
              }}
            />
          </Col>
          <Col md={6}>
            <MyMonthPeriodSelect
              onChange={(startDate, endDate) => {
                setValue('StartDate', startDate)
                setValue('EndDate', endDate)
                handleSubmit((data) => userWalletsStore.GetTransactions(data))()
              }}
            />
          </Col>
        </Row>
        {renderTransactions()}
      </form>
    )
  }

  return <Portlet title={t.common.transactions}>{renderContent()}</Portlet>
}

export default observer(TransactionsPortlet)
