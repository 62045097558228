import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import SamController from 'src/Services/ToprCoreApi/Axios/Controllers/SamController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  TableNumber: string
}

export default class Store extends BaseStore<IFormValues> {
  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      EditTableSam: action,
    })
  }

  public Initialize() {
    this.BaseInitialize()
  }

  public EditTableSam(editSam: ISamVTO) {
    this.IsSubmitting = true

    return new Promise<void>((resolve, reject) => {
      SamController.Edit(editSam)
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          resolve()
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.IsSubmitting = false
            this.PageAlert = createPageAlert(error)
          })
          reject()
        })
    })
  }
}

export const editTableSamStore = new Store()
