import React from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'src/I18n'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import TopMenuListItem from './TopMenuListItem'
interface IProps {}

const SystemMenu: React.FC<IProps> = () => {
  const isAdmin = IsAdmin(persistedStore.SelectedManagerUser)

  return (
    <TopMenuListItem
      title={'System'}
      urls={['/subscriptions', '/events', '/tags', '/loyaltyprograms']}
    >
      <ul className="dropdown-menu" role="menu">
        {isAdmin && (
          <li>
            <NavLink to={'/currencies'}>
              <i className={`fa fa-coins dropdown-icon`} />
              {t.common.currencies}
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={'/subscriptions'}>
            <i className={`fa fa-newspaper dropdown-icon`} />
            {t.subscription.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/events'}>
            <i className={`fa fa-calendar dropdown-icon`} />
            {t.event.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/tags'}>
            <i className={`fa fa-tags dropdown-icon`} />
            {t.tags.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/loyaltyprograms'}>
            <i className={`fa fa-piggy-bank dropdown-icon`} />
            {t.common.loyaltyPrograms}
          </NavLink>
        </li>
      </ul>
    </TopMenuListItem>
  )

  //return <>{renderSubscriptions()}</>
}

export default SystemMenu
