import React, { memo, useEffect, useRef, useState } from 'react'
interface IProps {
  message: string
}

interface IPosition {
  left: number
  top: number
}

const MyPopover: React.FC<IProps> = ({ message }) => {
  const refInfoButton = useRef<HTMLElement>(null)
  const [popoverDiv, setPopoverDiv] = useState<HTMLElement>(null) //based on state rendered popover/ isVisible , so use State instead of useRef
  const [popoverPosistion, setPopoverPosition] = useState<IPosition>({
    left: 0,
    top: 0,
  })
  const [isVisible, setIsVisible] = useState(false)

  const calculateAndSetPosition = () => {
    if (isVisible && popoverDiv !== null) {
      const rectsInfoButton = refInfoButton.current.getClientRects()[0]

      const left =
        rectsInfoButton.left -
        popoverDiv.clientWidth / 2 +
        rectsInfoButton.width / 2
      const top = rectsInfoButton.top - popoverDiv.clientHeight

      const newPosition: IPosition = {
        left,
        top,
      }

      setPopoverPosition(newPosition)
    }
  }

  useEffect(() => {
    const scroll = (e) => {
      calculateAndSetPosition()
    }
    document.addEventListener('scroll', scroll)

    calculateAndSetPosition()
    return () => {
      document.removeEventListener('scroll', scroll)
    }
  }, [isVisible, popoverDiv])

  const renderPopOver = () => {
    if (!isVisible) {
      return null
    }

    return (
      <div
        className="modal-backdrop transparant"
        onClick={(e) => {
          setIsVisible(false)
        }}
      >
        <div
          role="tooltip"
          className="popover top"
          style={{
            display: 'block',
            left: `${popoverPosistion.left}px`,
            top: `${popoverPosistion.top}px`,
          }}
          ref={(element) => {
            setPopoverDiv(element)
          }}
        >
          <div className="arrow" style={{ left: '50%' }} />
          {/* <h3 className="popover-title">Popover top</h3> */}
          <div className="popover-content">{message}</div>
        </div>
      </div>
    )
  }
  return (
    <>
      <i
        ref={refInfoButton}
        className="fa fa-exclamation-circle pull-right my-tooltip-button danger"
        onClick={() => {
          setIsVisible(!isVisible)
        }}
      ></i>
      {renderPopOver()}
    </>
  )
}

export default memo<IProps>(MyPopover)
