import React, { memo, useEffect, useState } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  InputMasks,
  MyCheckbox,
  MyLocalizedStringTabs,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import MyEuroTextbox from 'src/Components/My/Form/MyEuroTextbox'
import {
  BalanceCheckTypeOptions,
  PayoutTypeOptions,
} from 'src/Helpers/CurrencyHelpers'

import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { ICurrencyV2VTO } from 'src/Models/Server/Response/Currency/ICurrencyV2VTO'
import {
  LocalizedStringDTO,
  SetMissingLanguageValues,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { useGetActiveMerchantsQuery } from 'src/Services/ToprCoreApi/RTK/Slices/MerchantSlice'

interface IProps {
  onSubmit: (data: IFormValues) => void
  isSubmitting: boolean
  editItem?: ICurrencyV2VTO
  isLoading?: boolean
}

interface IFormValues {
  Merchant?: ISelectOption
  Code: string
  Description: LocalizedStringDTO
  StringFormat: string
  IsDecimal: boolean
  MaxBalance: number
  AmountDescription: string
  IsPublic: boolean
  CancelAllowed: boolean
  IsActive: boolean
  ConsumerCharge: boolean
  ConsumerWithdraw: boolean
  ConsumerSendMoney: boolean
  AutomaticChargeAllowed: boolean
  ManagerCharge: boolean
  ManagerWithdraw: boolean
  HasUserCurrencyValue: boolean

  BalanceCheckType: ISelectOption
  CurrencyPayoutType: ISelectOption
}

const CurrencyForm: React.FC<IProps> = ({
  onSubmit,
  isSubmitting,
  editItem,
  isLoading,
}) => {
  const isEdit = editItem !== undefined

  const { data: merchants, isLoading: isLoadingMerchants } =
    useGetActiveMerchantsQuery()

  const getMerchantOptions = (): ISelectOption[] => {
    if (merchants === undefined) {
      return []
    }

    return merchants.map<ISelectOption>((m) => ({
      label: m.Name,
      value: m.Id.toString(),
    }))
  }

  /*
  let formValues: Partial<IFormValues> = {
    //new currency defaults
    Description: new LocalizedStringDTO(
      'Currency Description',
      TextBoxType.TextBox
    ),
    StringFormat: '€ {0:n2}',
    IsDecimal: true,
    IsPublic: true,
    IsActive: true,
    AmountDescription: 'Tegoed',

    //test
    //Code: 'A23',
  }
  */
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      //new currency defaults
      Description: new LocalizedStringDTO(
        'Currency Description',
        TextBoxType.TextBox
      ),
      StringFormat: '€ {0:n2}',
      IsDecimal: true,
      IsPublic: true,
      IsActive: true,
      AmountDescription: 'Tegoed',

      //test
      //Code: 'A23',
    },
  })
  const { handleSubmit, reset } = useFormProps

  useEffect(() => {
    if (editItem !== undefined) {
      //console.log('effect', formValues.ConsumerCharge)

      reset({
        ...editItem,
        Description: SetMissingLanguageValues(editItem.Description),
        BalanceCheckType: BalanceCheckTypeOptions.find(
          (x) => x.value === editItem.BalanceCheckTypeID.toString()
        ),
        CurrencyPayoutType: PayoutTypeOptions.find(
          (x) => x.value === editItem.CurrencyPayoutTypeID.toString()
        ),
      })
    }
  }, [editItem])

  const renderMerchantsSelect = () => {
    if (isEdit) {
      return null
    }
    return (
      <MySelect
        formObject={{} as IFormValues}
        useFormProps={useFormProps}
        label={'Merchant'}
        name="Merchant"
        rules={{
          required: 'Selecteer een merchant',
        }}
        options={getMerchantOptions()}
      />
    )
  }

  const buttonText = isEdit ? t.common.edit : t.common.add

  if (isLoadingMerchants || isLoading) {
    return <LoaderIcon IsLoading={true} />
  }

  //console.log('render')

  // return (
  //   <MyCheckbox
  //     useFormProps={useFormProps}
  //     formObject={{} as IFormValues}
  //     name="ConsumerCharge"
  //     label="ConsumerCharge"
  //   />
  // )

  return (
    <Portlet title="Betaalmiddel">
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    useFormProps={useFormProps}
                    formObject={{} as IFormValues}
                    name="Code"
                    label="Code"
                    upperCase
                    inputMask={InputMasks.CurrencyCode}
                    rules={{
                      required: 'Voer een CurrencyCode in',
                    }}
                  />
                </Col>
                <Col md={6}>
                  <MyTextbox
                    useFormProps={useFormProps}
                    formObject={{} as IFormValues}
                    name="AmountDescription"
                    label="AmountDescription"
                    maxLength={12}
                    rules={{
                      required: 'Voer een AmountDescription in',
                    }}
                  />
                </Col>
              </Row>

              <MyLocalizedStringTabs
                formObject={{} as IFormValues}
                useFormProps={useFormProps}
                name="Description"
                label={t.common.description}
                isRequired
              />
            </Col>
            <Col md={6}>
              {renderMerchantsSelect()}
              <Row>
                <Col md={6}>
                  <MyTextbox
                    useFormProps={useFormProps}
                    formObject={{} as IFormValues}
                    name="StringFormat"
                    label="StringFormat"
                    upperCase
                    rules={{
                      required: 'Voer een StringFormat in',
                    }}
                  />
                </Col>
                <Col md={6}>
                  <MyCheckbox
                    useFormProps={useFormProps}
                    formObject={{} as IFormValues}
                    name="IsDecimal"
                    label="IsDecimal"
                  />
                </Col>
              </Row>
              <MyEuroTextbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="MaxBalance"
                label="MaxBalance"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h6>Instellingen</h6>
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="IsPublic"
                label="IsPublic"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="IsActive"
                label="IsActive"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="CancelAllowed"
                label="CancelAllowed"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="HasUserCurrencyValue"
                label="HasUserCurrencyValue"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h6>Klanten</h6>
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="ConsumerCharge"
                label="ConsumerCharge"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="ConsumerWithdraw"
                label="ConsumerWithdraw"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="ConsumerSendMoney"
                label="ConsumerSendMoney"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="AutomaticChargeAllowed"
                label="AutomaticChargeAllowed"
              />
            </Col>
            <Col md={6}>
              <h6>Manager</h6>
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="ManagerCharge"
                label="ManagerCharge"
              />
              <MyCheckbox
                useFormProps={useFormProps}
                formObject={{} as IFormValues}
                name="ManagerWithdraw"
                label="ManagerWithdraw"
              />
            </Col>
          </Row>
          <h6>MerchantPayout</h6>
          <Row>
            <Col md={6}>
              <MySelect
                formObject={{} as IFormValues}
                useFormProps={useFormProps}
                label={'BalanceCheck type'}
                name="BalanceCheckType"
                rules={{
                  required: 'Selecteer een BalanceCheck type',
                }}
                options={BalanceCheckTypeOptions}
              />
            </Col>
            <Col md={6}>
              <MySelect
                formObject={{} as IFormValues}
                useFormProps={useFormProps}
                label={'CurrencyPayoutType'}
                name="CurrencyPayoutType"
                rules={{
                  required: 'Selecteer een CurrencyPayoutType',
                }}
                options={PayoutTypeOptions}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <ButtonToolbar className="pull-right">
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {buttonText}
            </McButton>
          </ButtonToolbar>
        </div>
      </form>
    </Portlet>
  )
}

export default memo<IProps>(CurrencyForm)
