export default {
  addAppUserModal: {
    fields: {
      Email: {
        label: 'E-mailadres',
        errors: {
          required: 'Voer het e-mailadres in.',
          regex: 'Voer een geldig e-mailadres in.',
        },
      },
      Name: {
        label: 'Naam',
        errors: {
          required: 'Voer de naam in.',
        },
      },
      Password: {
        label: 'Wachtwoord',
        errors: {
          required: 'Voer het wachtwoord in.',
        },
      },
      Currency: {
        label: 'Betaalmiddel',
        errors: {
          required: 'Selecteer een betaalmiddel.',
        },
      },
    },
    checkEmail: 'Check e-mailadres',
  },
  addManagerUserModal: {
    fields: {
      Email: {
        label: 'E-mailadres',
        errors: {
          required: 'Voer het e-mailadres in.',
          regex: 'Voer een geldig e-mailadres in.',
        },
      },
      Name: {
        label: 'Naam',
        errors: {
          required: 'Voer de naam in.',
        },
      },
      Password: {
        label: 'Wachtwoord',
        errors: {
          required: 'Voer het wachtwoord in.',
        },
      },
    },
  },
  automaticChargeInsert: {
    title: 'Automatic Charge instellen (SEPA)',
    fields: {
      AccountHolder: {
        label: 'Rekeninghouder',
        errors: {
          required: 'Voer de rekeninghouder in.',
        },
      },
      AccountNumber: {
        label: 'Rekeningnummer (IBAN)',
        errors: {
          required: 'Voer het rekeningnummer in.',
          iban: 'Voer een geldig IBAN rekeningnummer in.',
        },
      },

      City: {
        label: 'Plaatsnaam',
        errors: {
          required: 'Voer de plaatsnaam in.',
        },
      },
      EmailAddress: {
        label: 'E-mailadres',
        errors: {
          required: 'Voer het e-mailadres in.',
          regex: 'Voer een geldig e-mailadres in',
        },
      },
      LastName: {
        label: 'Achternaam',
        errors: {
          required: 'Voer de achternaam in.',
        },
      },
      PhoneNumber: {
        label: 'Telefoonnummer',
        errors: {
          required: 'Voer het telefoonnummer in.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank van de klant',
        errors: {
          required: 'Selecteer een bank.',
          bank: 'Ongeldige bank voor dit rekeningnummer',
        },
      },
      SelectedCurrency: {
        info: 'Het betaalmiddel dat opgewaardeerd zal worden.',
        errors: {
          required: 'Selecteer een betaalmiddel.',
        },
      },
      SepaFileData: {
        label: 'SEPA formulier',
        info: 'Het SEPA formulier wat door de klant is opgestuurd.',
        dropFile:
          'Klik hier om het SEPA formulier (.pdf/.jpg/.png) te uploaden.',
        errors: {
          required: 'Upload het SEPA formulier.',
        },
      },
      StepAmount: {
        label: 'Opwaardeerbedrag',
        info: 'Het bedrag dat wordt opgewaardeerd (minimum €10 en maximum €150).',
        errors: {
          required: 'Voer het opwaardeerbedrag in.',
        },
      },
      StepLimit: {
        label: 'Limiet (per week)',
        info: 'Het  maximum aantal opwaarderingen dat per week mag worden uitgevoerd (minimum 1, maximum 10).',
        errors: {
          required: 'Voer het limiet in.',
        },
      },

      StreetName: {
        label: 'Straatnaam',
        errors: {
          required: 'Voer de straatnaam in.',
        },
      },
      StreetNumber: {
        label: 'Huisnummer',
        errors: {
          required: 'Voer het huisnummer in.',
        },
      },
      Zipcode: {
        label: 'Postcode',
        errors: {
          required: 'Voer de postcode in.',
        },
      },
    },
    success: 'Automatisch opwaarderen aangezet voor: {currencyName}',
  },
  automaticChargeEdit: {
    title: 'Automatic Charge Wijzigen',
    fields: {
      FileData: {
        label: 'SEPA wijzigingsformulier',
        info: 'Het SEPA wijzigingsformulier wat door de klant is opgestuurd.',
        dropFile:
          'Klik hier om het SEPA wijzigingsformulier (.pdf/.jpg/.png) te uploaden.',
        errors: {
          required: 'Upload het SEPA wijzigingsformulier.',
        },
      },
      AccountHolder: {
        label: 'Rekeninghouder',
        errors: {
          required: 'Voer de rekeninghouder in.',
        },
      },
      AccountNumber: {
        label: 'Rekeningnummer (IBAN)',
        errors: {
          required: 'Voer het rekeningnummer in.',
          iban: 'Voer een geldig IBAN rekeningnummer in.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank van de klant',
        errors: {
          required: 'Selecteer een bank.',
          bank: 'Ongeldige bank voor dit rekeningnummer',
        },
      },
      StepAmount: {
        label: 'Opwaardeerbedrag',
        info: 'Het bedrag dat wordt opgewaardeerd (minimum €10 en maximum €150).',
        errors: {
          required: 'Voer het opwaardeerbedrag in.',
        },
      },
      StepLimit: {
        label: 'Limiet (per week)',
        info: 'Het  maximum aantal opwaarderingen dat per week mag worden uitgevoerd (minimum 1, maximum 10).',
        errors: {
          required: 'Voer het limiet in.',
        },
      },
    },
    sepaChangeForm: 'Wijzigingsformulier',
    success: 'Automatisch opwaarderen gewijzigd',
  },
  automaticChargesPortlet: {
    chargeSettings: 'Instellingen',
    stepAmount: 'Opwaardeerbedrag',
    stepLimit: 'Max per week',
    turnOff: {
      action: 'Uitzetten',
      confirmMessage:
        'Deze automatisch opwaardering ({currencyName}) zal <b>uitgezet</b> worden.<br/>Weet je het zeker?',
      success: 'Automatische opwaardering uitgezet.',
    },
  },
  common: {
    accountNumber: 'Rekening',
    active: 'actief',
    activate: 'Activeren',
    activationCode: 'Activatiecode',
    accepted: 'Geaccepteerd',
    add: 'Toevoegen',
    address: 'Adres',
    always: 'altijd',
    all: '<Alle>',
    amountTypes: {
      default: {
        title: 'Aantal',
        required: 'Voer het aantal in.',
      },
      currency: {
        title: 'Bedrag',
        required: 'Voer het bedrag in.',
      },
      percentage: {
        title: 'Percentage (%)',
        required: 'Voer het percentage in.',
      },
    },
    amount: 'Aantal',
    amountText: 'Bedrag',
    authenticatedUser: 'ingelogde gebruiker',
    back: 'Terug',
    backTo: 'Terug naar',
    bank: 'Bank',
    blocked: 'Geblokkeerd',
    cancel: 'Annuleren',
    city: 'Plaatsnaam',
    close: 'Sluiten',
    charge: 'Opwaarderen',
    confirm: 'Bevestigen',
    consumer: 'Klant',
    consumers: 'Klanten',
    consumerName: 'Klantnaam',
    company: 'Bedrijf',
    coupon: 'Voucher',
    coupons: 'Vouchers',
    created: 'Aangemaakt',
    currencies: 'Betaalmiddelen',
    currency: 'Betaalmiddel',
    custom: 'aangepast...',
    date: 'Datum',
    day: 'Dag',
    deactivate: 'Deactiveren',
    delete: 'Verwijderen',
    description: 'Omschrijving',
    deselectAll: 'Deselecteer alle',
    details: 'Details',
    download: 'Downloaden',
    dropImage: 'Klik hier om een plaatje toe tevoegen',
    edit: 'Wijzigen',
    email: 'E-mailadres',
    error: 'Fout',

    example: 'Bijvoorbeeld',
    extraInformation: 'Extra informatie',
    file: 'Bestand',
    filter: 'Filter',
    from: 'Vanaf',
    generate: 'Genereren',
    grid: {
      name: {
        label: 'Naam',
        placeholder: 'Zoek op naam',
      },
      email: {
        label: 'E-mailadres',
        placeholder: 'Zoek op e-mailadres',
      },
    },
    hidden: 'verborgen',
    inActive: 'niet actief',
    info: 'Info',
    insert: 'Invoeren',
    image: 'Plaatje',
    lastName: 'Achternaam',
    location: 'Locatie',
    locations: 'Locaties',
    logoff: 'Uitloggen',
    loyaltyProgram: 'Spaarprogramma',
    loyaltyPrograms: "Spaarprogramma's ",
    merchant: 'Merchant',
    merchants: 'Merchants',
    modified: 'Aangepast',
    module: 'Module',
    month: 'Maand',
    myProfile: 'Mijn profiel',
    myCustomers: 'Mijn klanten',
    name: 'Naam',
    no: 'Nee',
    noData: 'Geen data',
    other: 'Anders',
    pagingText: 'Toon {from} tot {to} van {size} rijen',
    preview: 'Voorbeeld',
    password: 'Wachtwoord',
    payments: 'Betalingen',
    period: 'Periode',
    phoneNumber: 'Telefoonnummer',
    pincode: 'Pincode',
    processed: 'Verwerkt',
    pos: 'Pos',
    redeemed: 'Ingeleverd',
    revenue: 'Omzet',
    reward: 'Beloning',
    sam: 'Sam',
    save: 'Opslaan',
    savingcard: 'Spaarkaart',
    savingcards: 'Spaarkaarten',
    search: 'Zoeken',
    selectAll: 'Selecteer alle',
    serverErrors: {
      defaultError: {
        Title: 'Fout',
        Code: 'DefaultError',
        Message: 'Helaas er is iets misgegaan',
      },
    },
    settings: 'Instellingen',
    status: 'Status',
    streetName: 'Straatname',
    streetNumber: 'Nummer',
    sum: 'Bedrag',
    subscriptions: 'Abonnementen',
    tenant: 'Tenant',
    tenants: 'Tenants',
    terminal: ' Terminal',
    terminals: 'Terminals',
    ticket: 'Ticket',
    tickets: 'Tickets',
    till: 'Tot en met',
    title: 'Titel',
    tokens: 'Betaalsleutels',
    tokenTypes: 'Kaarttypen',
    total: 'Totaal',
    totals: 'Totalen',
    transactions: 'Transacties',
    turnoff: 'Uitzetten',
    type: 'Type',
    validity: 'Geldig',
    validFrom: 'Vanaf',
    validTill: 'Tot en met',
    wallets: 'Portemonnees',
    warning: 'Waarschuwing',
    week: 'Week',
    thisWeekFormat: 'Deze week ({0})',
    lastWeekFormat: 'Vorige week ({0})',
    weekFormat: 'Week {0}',
    withdraw: 'Afwaarderen',
    trip: 'Rit',
    time: 'Tijd',
    untill: 't/m',
    user: 'Gebruiker',
    users: 'Gebruikers',
    username: 'Gebruikersnaam',
    unlimited: 'Oneindig',
    year: 'Jaar',
    zipCode: 'Postcode',
  },
  commonFields: {
    Amount: {
      label: 'Bedrag',
      placeholder: {
        number: '0',
        decimal: '0,00',
      },
      errors: {
        required: 'Voer het bedrag in.',
        minNumber: 'Het minimale bedrag is 1.',
        minDecimal: 'Het minimale bedrag is 0,01',
      },
    },
  },
  commonErrors: {
    localizedStringRequired: 'Voer een {0} ({1}) in.',
  },
  confirmationSend: {
    header: 'Je bent er bijna!',
    heading: 'Bevestiging verzonden',
    bodyTextLine1:
      '<p>Leuk dat je je hebt geregistreerd <b>{0}</b>!</p>' +
      '<p>Er is een e-mail verstuurd naar <b>{1}</b> met daarin een confirmatielink.' +
      ' Wanneer je op deze link klinkt wordt je account bevestigd en kan je je eerste spaarkaart gaan maken!</p>',
  },
  coupons: {
    deleteUserCouponConfirm:
      'Weet je zeker dat <i>{fullTitle}</i> wilt verwijderen?',
    userCouponAdd: {
      title: 'Vouchers toevoegen',
      fields: {
        amount: {
          label: 'Aantal coupons',
          required: 'Voer het aantal coupons in.',
        },
        coupon: {
          label: 'Voucher',
          required: 'Selecteer een coupon.',
        },
      },
      successMessage:
        'Er zijn {amount}x {couponTitle} toegevoegd aan {count} klanten.',
    },
  },
  dashboard: {
    totalUsers: 'Totaal gebruikers',
    totalActivated: 'Totaal geactiveerde gebruikers',
    newUsersThisWeek: 'Nieuwe gebruikers deze week',
    activeUsersThisWeek: 'actieve gebruikers deze week',
  },
  dashboardReport: {
    revenueToday: 'Omzet vandaag',
    paymentsToday: 'Betalingen vandaag',
    revenueMonth: 'Omzet deze maand',
    paymentsMonth: 'Betalingen deze maand',
    revenueStats: 'Omzet Stats',
    top10Pos: 'Top 10 deze maand',
    revenueHour: 'Omzet per uur',
    registeredUsers: 'Geregistreerde gebruikers',
    averageAmount: 'Gemiddelde betaling',
    outstandingBalance: 'Openstaand saldo',
    redeemedCouponsToday: 'Verzilverde vouchers vandaag',
    issuedCouponsToday: 'Uitgegeven vouchers vandaag',
    redeemedCouponsMonth: 'Verzilverde vouchers deze maand',
    issuedCouponsMonth: 'Uitgegeven vouchers deze maand',
    paymentsPerLocation: 'Betalingen per locatie vandaag',
    revenuePerLocation: 'Omzet per location vandaag',
  },
  editEmail: {
    title: 'E-mailadres wijzigen',
    emailField: {
      errors: {
        required: 'Voer een e-mailadres in',
        validateEmail: 'Voer een geldig e-mailadres in',
      },
    },
    success: 'Het e-mailadres is bijgewerkt.',
  },
  editName: {
    title: 'Naam wijzigen',
    nameField: {
      errors: {
        required: 'Voer een naam in',
      },
    },
    success: 'De naam is bijgewerkt.',
  },
  editPassword: {
    title: 'Wachtwoord wijzigen',
    nameField: {
      errors: {
        required: 'Voer een wachtwoord in',
      },
    },
    success: 'Het wachtwoord is bijgewerkt.',
  },
  editUserName: {
    title: 'Gebruikersnaam wijzigen',
    userNameField: {
      errors: {
        required: 'Voer een gebruikersnaam in',
      },
    },
    success: 'De gebruikersnaam is bijgewerkt.',
  },
  enums: {
    savingCardStatusDTO: {
      Active: 'Actief',
      NotYetActive: 'Nog niet actief',
      Expired: 'Verlopen',
      Deactivated: 'Gedeactiveerd',
    },
    LoyaltyRuleTypes: {
      Article: 'Artikel',
      ArticleGroup: 'Artikelgroep',
      Balance: 'Saldo',
      TransactionAmountPositive: 'Opwaardering', //opwaardering
      TransactionAmountNegative: 'Betaling', //betaling
      RedeemCoupon: 'Voucher inleveren',
    },
    LoyaltyRewardTypes: {
      CouponReward: 'Voucher',
      CurrencyStaticReward: 'Betaalmiddel, vast bedrag',
      CurrencyOrderPercentageReward: 'Betaalmiddel, percentage bedrag',
    },
  },
  event: {
    index: {
      title: 'Evenementen',
      confirmDelete:
        'Weet je zeker dat je het ticket <b>{name}</b> wilt verwijderen?',
    },
    edit: {
      title: 'Evenement wijzigen',
    },
    insert: {
      title: 'Evenement toevoegen',
      fields: {
        name: {
          label: 'Naam',
          required: 'Voer een naam in.',
        },
        description: {
          label: 'Omschrijving',
          required: 'Voer een omschrijving in.',
        },
        start: {
          title: 'Aanvangstijd',
          required: 'Voer een aanvangstijd in.',
        },
        end: {
          title: 'Eindtijd',
          required: 'Voer een eindtijd.',
          greaterThan: 'De eindtijd moet later zijn dan de aanvangstijd.',
        },
      },
    },
  },
  eventTicket: {
    insert: {
      title: 'Ticket toevoegen',
      fields: {
        name: {
          label: 'Naam',
          info: 'Bijvoorbeeld: kind tot 13 jaar, volwassen of brons,zilver, goud etc.',
          required: 'Voer een naam in.',
        },
        onSale: {
          title: 'Beschikbaar voor online verkoop.',
        },
        price: {
          title: 'Prijs',
          required: 'Voer een prijs in.',
        },
      },
    },
  },
  addTicket: {
    title: 'Ticket toevoegen',
    fields: {
      ticket: {
        title: 'Ticket',
      },
      amount: {
        title: 'Aantal',
        required: 'Voer een aantal in.',
      },
    },
  },
  fileEdit: {
    title: 'Bestand bewerken',
    form: {
      dataField: {
        label: 'bestand (.*)',
        dropfile: 'Klik hier om het bestand (.*) te uploaden.',
        error: {
          required: 'Voer het bestand in.',
        },
      },
      failed: 'Het bijwerken van het bestand is MISLUKT',
      success: 'Het bijwerken van het bestand is  GELUKT',
    },
  },
  fileInsert: {
    title: 'Bestand toevoegen',
    form: {
      dataField: {
        label: 'bestand (.*)',
        dropfile: 'Klik hier om het bestand (.*) te uploaden.',
        error: {
          required: 'Voer het bestand in.',
        },
      },
      failed: 'Het uploaden van het bestand is MISLUKT',
      success: 'Het uploaden van het bestand is  GELUKT',
    },
  },
  forgotPassword: {
    fieldEmailAddress: {
      label: 'E-mailadres',
      errors: {
        required: 'Voer het e-mailadres in.',
        regex: 'Voer een geldig e-mailadres in.',
      },
    },
    header: 'Wachtwoord vergeten',
    login: 'Inloggen',
    info: 'Voer je e-mailadres in en we sturen je een link om je wachtwoord te resetten.',
    submitText: 'Versturen',
    success:
      'Er is een mail verstuurd met een link om je wachtwoord te resetten.',
  },
  generateAutomaticCharge: {
    availableAutomaticChargesText:
      "Er zijn {count} incasso's beschikbaar om te genereren.",
    title: 'Genereer SEPA bestand',
    fileBeingGenerated: 'Het bestand wordt gegenereert.',
    noFile: 'Geen bestand',
  },
  helpdesk: {
    fieldSearch: {
      label: 'Zoeken',
      placeholder: 'Naam, gebruikersnaam, activatiecode, e-mailadres (Topr)',
    },
    fieldCardNumberSearch: {
      placeholder: 'Kaartnummer (Mybility)',
    },
    loginUserUser: 'Inloggen als gebruiker',
    selectTenantModal: {
      header: 'Selecteer een tenant',
      loginActionText: 'Inloggen',
    },
    submitText: 'Zoek klant',
    title: 'Helpdesk',
  },
  location: {
    insert: {
      title: 'Nieuwe locatie',
      fields: {
        City: {
          label: 'Plaats',
        },
        Email: {
          label: 'E-mailadres',
        },
        Lat: {
          label: 'Latitude',
        },
        Long: {
          label: 'Longitude',
        },
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voer de naam in.',
          },
        },
        IsVisibleInApp: {
          label: 'IsVisibleInApp',
        },
        PhoneNumber: {
          label: 'Telefoonnummer',
        },
        POSOrderUrl: {
          label: 'POSOrderUrl',
        },
        StreetName: {
          label: 'Straatnaam',
        },
        StreetNumber: {
          label: 'Huisnummer',
        },
        ZipCode: {
          label: 'Postcode',
        },
      },
      getCoordinates: 'Haal coordinaten op',
      showMap: 'Toon kaart',
    },
    setVisibleActive: 'Set Visible actief ',
    setVisibleInActive: 'Set Visible inactief ',
  },
  login: {
    createAccount: 'Maak een account!',
    fieldEmailAddress: {
      label: 'E-mailadres',
      errors: {
        required: 'Voer je e-mailadres in.',
      },
    },
    fieldPassword: {
      label: 'Wachtwoord',
      errors: {
        required: 'Voer je wachtwoord in.',
      },
    },
    forgotPassword: 'Wachtwoord vergeten?',
    noAccount: 'Geen account?',
    header: 'Welkom terug bij {name}!',
    remeberMe: 'Wachtwoord onthouden',
    submitText: 'Inloggen',
  },
  loyaltyprogram: {
    insert: {
      title: 'Nieuw spaarprogramma',
      fields: {
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voor een naam in.',
          },
        },
        LoyaltyProgramTypeId: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type spaarprogramma.',
          },
        },
        CurrencyId: {
          label: 'Betaalmiddel',
          errors: {
            required: 'Selecteer een betaalmiddel.',
          },
        },
        CouponId: {
          label: 'Voucher',
          errors: {
            required: 'Selecteer een voucher.',
          },
        },
        ArticleCode: {
          label: 'Artikelcode',
          errors: {
            required: 'Voer een artikelcode in.',
          },
        },
        ArticleGroup: {
          label: 'Artikelgroep',
          errors: {
            required: 'Voer een artikelgroep in.',
          },
        },
        StartDate: {
          label: 'Startdatum',
          required: 'Voer een startdatum in.',
        },
        EndDate: {
          label: 'Tot en met',
          errors: {
            greaterThan: 'De datum moet groter zijn dan de startdatum.',
          },
        },
      },
    },
    details: {
      confirmDelete:
        'Weet je zeker dat je dit spaarprogramma wilt verwijderen?',
      deleteSuccess: 'Spaarprogramma verwijdert.',
      rules: 'Regels',
      ruleRunOn: {
        Article: 'Een artikel ({name} - {articleCode}) wordt afgerekend.',
        ArticleGroup:
          'Een artikel uit deze artikelgroep ({name} - {articleGroup}) wordt afgerekend.',
        TransactionAmountNegative:
          'Wanneer er wordt betaald met dit betaalmiddel ({currencyName}).',
        RedeemCoupon: "wanneer een '{couponTitle}' voucher wordt ingeleverd.",
      },
      runWhen: 'Uitgevoerd wanneer',
    },
  },
  merchant: {
    index: {},
    insert: {
      title: 'Nieuwe merchant',
      fields: {
        FinanceEmailAddress: {
          label: 'Finance e-mailadres',
        },
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voer de naam in.',
          },
        },
        RelationID: {
          label: 'Relatie',
          errors: {
            required: 'Selecteer een relatie.',
          },
        },
      },
    },
    details: {
      appUsers: {
        addPosModule: 'Module toevoegen',
        addUser: 'App Login toevoegen',
        editUser: 'App Login wijzigen',
        deleteAppUserConfirm:
          'Weet je zeker dat je de app login <b>{0}</b>  wilt verwijderen?',
        deletePosModuleConfirm:
          'Weet je zeker dat je de module <b>{moduleName} ({currencyName})</b> wilt verwijderen?',
        title: 'App logins',
      },
      managerUsers: {
        addUser: 'Manager Login toevoegen',
        editUser: 'Manager Login wijzigen',
        deleteConfirm:
          'Weet je zeker dat je de manager login <b>{0}</b> wilt verwijderen?',

        title: 'Manager logins',
      },
    },
    addConsumerUser: {
      title: 'Klant toevoegen',
      fields: {
        activationCode: {
          label: 'ActivatieCode',
          required: 'Voer de activatiecode in.',
          minLength: 'Een activatiecode is altijd 8 karakters lang.',
        },
      },
      successMessage: '{activationCode} is toegevoegd.',
    },
  },
  merchantPayments: {
    title: 'Merchant uitbetalingen',
  },
  merchantRefunds: {
    accountName: 'Rekening houder',
    accountNumber: 'Rekeningnummer',
    amount: 'Aantal refunds',
    amountText: 'Bedrag',
    downloaded: 'Gedownload op',
    generateFile: 'Genereer bestand',
    refunds: 'Terugboekingen',
    title: 'Merchant Refunds',
  },
  merchantPaymentDetails: {
    title: 'Transacties',
  },
  months: {
    januari: 'Januari',
    februari: 'Februari',
    march: 'Maart',
    april: 'April',
    may: 'Mei',
    june: 'Juni',
    july: 'Juli',
    august: 'Augustus',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
  },
  montlyTransactionTotals: {
    amountTotal: 'Totaal bedrag',
    amountValue: 'Bedrag met waarde',
    amountNoValue: 'Bedrag zonder waarde',
  },
  modules: {
    POS_PAYMENT: 'Betalen',
    POS_TOPUP: 'Opwaarderen',
    POS_DEDUCT: 'Afwaarderen',
    LOYALTY_REDEEM_COUPON: 'Voucher verzilveren',
    LOYALTY_PUSH_COUPON: 'Voucher uitgeven',
    POS_PRODUCT_REGISTER: 'Producten kassa',
    USER_INFO: 'Userinfo',
    LOYALTY_SAVINGCARDS: 'Spaarkaarten',
    MYB_TRIPS: 'Trips module',
    PSDPP_PAY_BALANCE: 'PSDPP PAY BALANCE',
    PSDPP_BALANCE: 'PSDPP BALANCE',
    USER_ACCESS_CONTROL: 'Toegangscontrole (abonnement)',
    EVENT_USER_ACCESS_CONTROL: 'Toegangscontrole (evenement)',
    SAVING_ARTICLE: 'Sparen via Artikelen',
    HB_CARD_MANAGEMENT: 'HB_CARD_MANAGEMENT',
  },
  noMatch: {
    header: 'Pagina niet gevonden',
    text: 'De opgevraagde pagina is niet gevonden. ',
  },
  currencyUsage: {
    title: 'Verbruik per {0}',
  },
  currencyCharge: {
    title: 'Opwaarderingen per {0}',
  },
  paymentsPerDay: {
    title: 'Betalingen per dag',
  },
  paymentTransactions: {
    title: 'Betalingen',
  },
  paymentsTokenType: {
    title: 'Betalingen per kaarttype',
  },
  pos: {
    activate: {
      action: 'Activeren',
      confirmMessage:
        'Weet je zeker dat je deze pos({name}) wilt <b>activeren</b>?.',
      success: 'Pos({name}) is geactiveerd.',
    },
    deactivate: {
      action: 'Deactiveren',
      confirmMessage:
        'Weet je zeker dat je deze pos({name}) wilt <b>deactiveren</b>?.',
      success: 'Pos({name}) is gedeactiveerd.',
    },
    insert: {
      title: 'Nieuwe pos',
      fields: {
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voer de naam in.',
          },
        },
        PosTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
  },
  register: {
    header: 'Wordt ook een Topr!',
    heading: 'Registreren',
    headerHow: 'Hoe werkt het?',
    textHow:
      'Registreer je via het formulier aan de rechterkant. Je ontvangt ons een bevestigings mailtje waarna je meteen aan de slag kan met je eerste spaarkaart!',
    fieldBic: {
      label: 'Bank',
      info: 'De bank waar de automatische incasso wordt geincasseerd.<br/>Na de 1e 60 dagen gaat het abonnement in.',
    },
    fieldContactName: {
      label: 'Contactpersoon',
      errors: {
        required: 'Voer de contactpersoon in.',
      },
    },
    fieldMerchantName: {
      label: 'Bedrijfsnaam',
      errors: {
        required: 'Voer de bedrijfsnaam in.',
      },
    },
    submitText: 'Ik ben een Topr!',
    fieldPhonenumber: {
      label: 'Telefoonnummer',
      errors: {
        required: 'Voer het telefoonnummer in.',
      },
    },
    fieldEmailAddress: {
      label: 'E-mailadres',
      errors: {
        required: 'Voer het e-mailadres in.',
        regex: 'Voer een geldig e-mailadres in.',
      },
    },
    fieldConfirmEmailAddress: {
      label: 'Bevestig e-mailaddress',
      errors: {
        required: 'Voer het bevestig e-mailadres in.',
        regex: 'Voer een geldig bevestig e-mailadres in.',
        match:
          'Het bevestig e-mailadres komt niet overeen met het e-mailadres.',
      },
    },
    fieldZipcode: {
      label: 'Postcode',
      errors: {
        required: 'Voer de postcode in.',
      },
    },
    fieldHouseNumber: {
      label: 'Huisnummer',
      errors: {
        required: 'Voer het huisnummer in.',
      },
    },
    fieldCity: {
      label: 'Plaatsnaam',
      errors: {
        required: 'Voer de plaatsnaam in.',
      },
    },
    fieldStreet: {
      label: 'Straat',
      errors: {
        required: 'Voer de straat in.',
      },
    },
    fieldPassword: {
      label: 'Wachtwoord',
      placeholder: 'Tenminste 6 karakters',
      errors: {
        required: 'Voer het wachtwoord in.',
        min: 'Voer tenminste 6 karakters in.',
      },
    },
    fieldConfirmPassword: {
      label: 'Bevestig wachtwoord',
      errors: {
        required: 'Voer het bevestig wachtwoord in.',
        min: 'Voer tenminste 6 karakters in.',
        match: 'Het bevestig wachtwoord komt niet overeen met het wachtwoord.',
      },
    },
  },
  registerConfirm: {
    header: 'Aan het werk!',
    headingConfirming: 'Account bevestigen',
    headingRegisterSuccess: 'Account bevestigd',
    loaderText: 'Bevestigen...',
    succesText:
      'Je wordt in {0}s automatisch doorgestuurd om je eerste spaarkaart te maken ' +
      'of klik <a href="{1}">hier</a>!',
  },
  resetPassword: {
    fields: {
      password: {
        label: 'Wachtwoord',
        placeholder: 'Tenminste 6 karakters',
        errors: {
          required: 'Voer het wachtwoord in.',
          min: 'Voer tenminste 6 karakters in.',
        },
      },
      passwordConfirm: {
        label: 'Bevestig wachtwoord',
        errors: {
          required: 'Voer het bevestig wachtwoord in.',
          min: 'Voer tenminste 6 karakters in.',
          match:
            'Het bevestig wachtwoord komt niet overeen met het wachtwoord.',
        },
      },
    },

    header: 'Wachtwoord resetten',
    info: 'Voer hieronder je nieuwe wachtwoord in. Het wachtwoord moet minimaal 6 karakters lang zijn.',
    serverErrors: {
      REQUESTIDENTIFIER_NOT_FOUND:
        "<span>Deze resetlink is niet meer actief. </span><a href='/forgotpassword'>Nieuwe resetlink sturen</a>.",
    },
    submitText: 'Reset wachtwoord',
    success: 'Je wachtwoord is gewijzigd',
  },
  sam: {
    block: {
      title: 'Blokkeren',
      success: 'Sam ({SamId}) is geblokkeerd.',
    },
    insert: {
      title: 'Nieuwe VSAM',
      fields: {
        SamTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
    insertTableSam: {
      title: 'Nieuwe Tafel VSAM',
      fields: {
        TableNumber: {
          label: 'Tafelnummer',
          errors: {
            required: 'Voer een tafelnummer in.',
          },
        },
      },
    },
    link: {
      title: 'Link sam',
      samId: {
        label: 'SamID',
        errors: {
          required: 'Voer een SamID in.',
          alreadyLinked:
            'Deze sam is al gelinkt aan <a href="/terminal/details/{terminalId}">deze terminal</a>. ',
        },
      },
    },
    unblock: {
      title: 'Deblokkeren',
      success: 'Sam ({SamId}) is gedeblokkeerd.',
    },
    unlink: {
      action: 'Ontkoppel',
      confirmMessage:
        'Weet je zeker dat je de sam({SamId}) wilt <b>ontkoppelen</b>?.',
      success: 'Sam({SamId}) is ontkoppeld.',
    },
  },
  savingcard: {
    averageStamps: {
      text: 'Per spaarkaart zijn er gemiddeld <strong>{0}</strong> stempels uitgegeven.',
      title: 'Gemiddeld aantal stempels',
    },
    totalCards: {
      text: 'Er zijn <strong>{0}</strong> spaarkaarten uitgegeven.',
      title: 'Aantal spaarkaarten',
    },
    totalCardsProcessed: {
      text: 'Er zijn <strong>{0}</strong> spaarkaarten voltooid.',
      title: 'Aantal spaarkaarten voltooid',
    },
    confirmDelete:
      'Weet je zeker dat je deze spaarkaart wilt verwijderen?<br/>' +
      'LET OP: Ook voor uw gebruikers wordt deze spaarkaart verwijderd.',
  },
  savingcardDetails: {
    couponsIssued: 'Totaal Vouchers uitgegeven',
    couponsRedeemed: 'Vouchers ingeleverd',
    couponsNotRedeemed: '<i>Niet</i> ingeleverde Vouchers',
    percentText:
      '<strong>{0}%</strong> van de spaarkaarten heeft <strong>{1}</strong> stempel(s)',
    percentTitle: 'Percentage per stempel',
    savingcardsAverage:
      'Stempels zijn er gemiddeld per spaarkaart zijn uitgegeven.',
    savingcardsCompleted: 'Spaarkaarten voltooid',
    savingcardsIssued: 'Spaarkaarten uitgegeven',
  },
  savingCardCreated: {
    title: 'Spaarkaart aangemaakt',
    contentIntro: 'De spaarkaart <i>{0}</i> is aangemaakt.',
  },
  savingcardInsert: {
    title: 'Nieuwe spaarkaart',
    fields: {
      AutoRefresh: {
        label: 'Automatisch vernieuwen',
        info: 'Wanneer <b>Automatisch vernieuwen</b> is aangevinkt, krijgt de klant meteen een nieuwe spaarkaart wanneer zijn spaarkaart voltooid is.',
      },
      CouponDescription: {
        label: 'Omschrijving',
        info:
          'De omschrijving/uitleg van de voucher. <ul>' +
          '<li>Wat de klant ontvangt tegen inlevering van deze voucher.</li>' +
          '<li>Op welke producten/diensten deze voucher van toepassing is.</li>' +
          '<li>Op welke locaties de klant deze voucher kan inleveren</li>' +
          '</ul>' +
          'Bijv: <i>Tegen inlevering van deze voucher ontvang je een heerlijk kopje koffie.' +
          '<br/><br/>Niet van toepassing op een Latte Macchiato.</i>',

        errors: {
          required: 'Voer de omschrijving ({0}) in.',
        },
      },
      Description: {
        label: 'Omschrijving',
        info:
          'De omschrijving/uitleg van de spaarkaart. <ul>' +
          '<li>Hoe er een stempel verdient kan worden.</li>' +
          '<li>Welke beloning de klant krijgt bij een volle spaarkaart.</li>' +
          '<li>Wanneer een spaarkaart is voltooid, ontvangt de klant een voucher voor het spaardoel.</li>' +
          '</ul>' +
          'Bijv: <i>Bij elk bezoek ontvang je 1 stempel. Bij 5 stempels ontvang je een voucher voor een gratis kopje koffie.</i>',

        errors: {
          required: 'Voer een omschrijving ({0}) in.',
        },
      },
      DiscountAmount: {
        Amount: {
          label: 'Bedrag korting',
          placeholder: '1,50',
          info: 'De bedrag korting van de voucher.<br/>Bijv: <i>€ 1,50</i>',
          errors: {
            required: 'Voer de bedrag korting in.',
          },
        },
        Percentage: {
          label: 'Percentage korting',
          placeholder: '33',
          info: 'De percentage korting van de voucher.<br/>Bijv: <i>33%</i>',
          errors: {
            required: 'Voer het percentage in.',
          },
        },
      },
      ImageFile: {
        label: 'Plaatje',
        info: 'Het plaatje voor de spaarkaart',
        errors: {
          required: 'Voer een plaatje in.',
        },
      },
      Reward: {
        label: 'Beloning mailtekst',
        info:
          'De tekst voor de notificatie mail die een klant krijgt wanneer zijn spaarkaart is voltooid. <ul>' +
          '</ul>',

        errors: {
          required: 'Voer de beloning mailtekst ({0}) in.',
        },
      },
      RewardAmount: {
        label: 'Bedrag',
        placeholder: '1,50',
        info: 'Het bedrag van de beloning.<br/>Bijv: <i>€ 1,50</i>',
        errors: {
          required: 'Voer het bedrag van beloning in.',
        },
      },
      RewardType: {
        label: 'Beloning type',
      },
      Title: {
        label: 'Titel',
        info: 'De titel van de spaarkaart. Bijv "Gratis kopje koffie"',
        errors: {
          required: 'Voer een titel ({0}) in.',
        },
      },
      StampCount: {
        label: 'Aantal stempels',
        info: 'Het aantal stempels van de spaarkaart.',
        errors: {
          required: 'Voer het aantal stempels in.',
        },
      },
      ValidFrom: {
        label: 'Ingangsdatum',
        info: 'Ingangsdatum van de spaarkaart. Indien er geen ingangsdatum wordt ingevoerd dan is de spaarkaart meteen actief.',
      },
      ValidTill: {
        label: 'Einddatum',
        info:
          'Einddatum van de spaarkaart.<ul>' +
          '<li>Indien er geen einddatum wordt ingevoerd blijft de spaartkaart altijd actief.</li>' +
          '<li>De einddatum is tot en met, dus wanneer de eindatum 15-11-2018 dan is de spaarkaart op deze dag ook nog actief</li>' +
          '</ul>',
        errors: {
          fromGreater: 'De eindatum moet groter zijn dan de ingangsdatum.',
        },
      },
      VoucherType: {
        label: 'Voucher type',
        info:
          'Type van de voucher die de klant ontvangt bij een volle spaarkaart.<ul>' +
          '<li><b>Product weggeven</b>, De klant ontvangt een voucher voor een gratis product.</li>' +
          '<li><b>Procent korting</b>, De klant ontvangt een voucher voor een percentage korting op een of meerdere producten.</li>' +
          '<li><b>Bedrag korting</b>, De klant ontvangt een voucher voor een bedrag korting op een of meerdere producten.</li>' +
          '</ul>',
      },
    },
    VoucherTypes: {
      FreeProduct: 'Product weggeven',
      AmountDiscount: 'Bedrag korting',
      PercentageDiscount: 'Procent korting',
    },

    untill: 'Tot en met',
  },
  savingcardEdit: {
    title: 'Wijzig spaarkaart',
    saveSuccess: 'De spaarkaart is opgeslagen.',
  },

  siedlerChargeGuthaben: {
    title: 'Kaart opwaarderingen',
    actionText: 'Genereren',
  },
  siedlerCompanyCoupons: {
    title: 'Vouchers per Bedrijf',
    actionText: 'Genereren',
    customerTitle: 'Periode titel',
  },
  siedlerInvoiceCoupons: {
    title: 'Factuur Vouchers',
    actionText: 'Genereren',
  },
  siedlerGebuhrCurrency: {
    title: 'Vergoeding Knödels',
    actionText: 'Genereren',
  },
  siedlerBearbeitungsGebuhr: {
    title: 'Transactiekosten Guthaben',
    actionText: 'Genereren',
  },
  siedlerInvoiceCurrency: {
    title: 'Factuur Betaalmiddelen',
    actionText: 'Genereren',
  },
  siedlerRemove: {
    action: 'Verwijderen uit Siedler',
    confirmMessage:
      '{name} zal <b>verwijdert</b> worden uit Siedler.<br/>Weet je het zeker?',
    success: '{name} is verwijdert uit Siedler.',
  },
  stornos: {
    title: "Upload Storno's",
    form: {
      stornoField: {
        label: 'Storno bestand (.csv)',
        dropfile: 'Klik hier om het storno bestand (.csv) te uploaden.',
        error: {
          required: 'Voer het storno bestand in.',
        },
      },
      failed: "Het verwerken van de storno's is MISLUKT",
      success: "Het verwerken van de storno's is  GELUKT",
    },
  },
  stornoStatus: {
    title: "Storno's",
    status: {
      Canceled: 'Gestorneerd',
      CanceledMailMerged: 'Brief verstuurt',
      ReadyForBlock: 'Gereed voor blokkeren',
      Blocked: 'Geblokkeerd',
      Collected: 'Betaald',
    },
  },
  subscription: {
    index: {
      title: 'Abonnementen',
      confirmDelete:
        'Weet je zeker dat je  het item <b>{name}</b> van <i>{subscriptionName}</i> wilt verwijderen?',
    },
    editItem: {
      title: 'Item wijzigen',
    },
    insert: {
      title: 'Abonnement toevoegen',
      fields: {
        name: {
          label: 'Name',
          required: 'Voer een naam in.',
        },
        systemName: {
          label: 'Systeemnaam',
          required: 'Voer een systeemnaam in.',
        },
      },
    },
    edit: {
      title: 'Abonnement wijzigen',
    },
    insertItem: {
      title: 'Item toevoegen',
      fields: {
        amount: {
          label: 'Aantal',
          labelEnum: {
            Day: 'Aantal dagen',
            Week: 'Aantal weken',
            Month: 'Aantal maanden',
            Year: 'Aantal jaar',
            Trips: 'Aantal ritten',
            Unlimited: 'Oneindig',
          },
          required: 'Voer een aantal in',
        },
        name: {
          label: 'Naam',
          required: 'Voer een naam in',
        },
        description: {
          label: 'Omschrijving',
          required: 'Voer een omschrijving in',
        },
      },
    },
    insertUserSubscription: {
      title: 'Abonnement toevoegen',
      fields: {
        ItemType: {
          label: 'Abonnement',
          errors: {
            required: 'Selecteer het abonnement.',
          },
        },
        StartDate: {
          label: 'Startdatum',
          required: 'Voer een startdatum in.',
        },
        EndDate: {
          label: 'Tot en met',
          errors: {
            greaterThan: 'De datum moet groter zijn dan de startdatum.',
          },
        },
      },
    },
    editUserSubscription: {
      title: 'Abonnement wijzigen',
    },
    //eigenlijk merchantSubscription
    expiredMessage: {
      header: 'Je abonnement is verlopen.',
      text: 'Ga naar de <a href="/subscriptions">abonnementen pagina</a> om je abonnement te vernieuwen',
    },
  },
  tags: {
    index: {
      title: 'Tags',
    },
    insert: {
      title: 'Tag toevoegen',
      fields: {
        name: { title: 'Tagnaam', required: 'Voer een tagnaam in.' },
      },
    },
    insertUserTag: {
      fields: {
        tag: { title: 'Tag', required: 'Selecteer een tag.' },
      },
    },
    deleteUserTagConfirm:
      'Weet je zeker dat je de tag <b>{tagName}</b> wilt verwijderen?',
    edit: {
      title: 'Tag wijzigen',
    },
  },
  terminal: {
    insert: {
      title: 'Nieuwe terminal',
      fields: {
        Description: {
          label: 'Omschrijving',
          errors: {
            required: 'Voer de omschrijving in.',
          },
        },
        TerminalTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
  },
  topMenu: {
    changeMerchant: {
      label: 'Verander merchant',
      required: 'Selecteer een merchant',
    },
    consumers: {
      helpdesk: 'Zoek klant',
      tags: 'Tags',
    },
    finance: {
      title: 'Financiën',
      merchantPayments: 'Merchant uitbetalingen',
      montlyTransactionTotals: 'Maandelijks uitbetalingen',
    },
    loyalty: {
      title: 'Loyalty',
      savingCards: 'Spaarkaarten',
      vouchers: 'Vouchers',
    },
    reports: {
      title: 'Rapportages',
      payments: {
        groupName: 'Betalingen',
        paymentsPeriod: 'Betalingen in periode',
        paymentsDay: 'Betalingen per dag',
      },
      wallets: {
        groupName: 'Portemonnees',
      },
      managerReports: {
        Mybility: {
          MybTrips: 'Ritten',
        },
        Pdx: {
          PdxCardPaymentTotals: 'Betalingen per kaart',
        },
        Siedler: {
          SiedlerInvoiceCoupons: 'Factuur Werksvouchers',
          SiedlerGebuhrKnodels: 'Vergoeding Knödels',
          SiedlerinvoiceCurrency: 'Afrekening Betaalmiddelen',
          SiedlerBearbeitungsGebuhr: 'Transactiekosten Guthaben',
          SiedlerCouponsUnternehmen: 'Vouchers per bedrijf',
          SiedlerChargeGuthaben: 'Kaart opwaarderingen',
        },
      },
    },
  },
  tokens: {
    activationCode: 'Activatiecode',
    blockToken: {
      action: 'Blokkeren',
      confirmMessage:
        'De betaalsleutel zal <b>geblokkeerd</b> worden.<br/>Weet je het zeker?',
      success: 'De betaalsleutel is geblokkeerd.',
    },
    dateActivated: 'Geactiveerd',
    validThru: 'Geldig tot',
    unblockToken: {
      action: 'Deblokkeren',
      confirmMessage:
        'De betaalsleutel zal <b>gedeblokkeerd</b> worden.<br/>Weet je het zeker?',
      success: 'De betaalsleutel is gedeblokkeerd.',
    },
  },
  tokenStatus: {
    activated: 'Actief',
    notActivated: 'Niet actief',
    blocked: 'Geblokkeerd',
    expired: 'Verlopen',
  },
  transaction: {
    title: 'Zoek transactie',
  },
  trips: {
    bookedTrips: 'Geboekte ritten',
    checkedInTrips: 'Ingecheckte ritten',
    completedTrips: 'Voltooide ritten',
    merchants: 'Centrales',
    title: 'Ritten',
    name: {
      label: 'Naam',
      placeholder: 'Zoek op naam',
    },
  },
  userBalances: {
    title: 'Gebruikers Saldi',
  },
  userDetails: {
    blockedStornos: "Geblokkeerde storno's",
    childUserConnections: 'Kind gebruikers',
    parentUserConnections: 'Ouder gebruiker',
    resetPassword: {
      action: 'Reset wachtwoord',
      confirmResetPassword:
        'Het wachtwoord zal worden gereset naar <b>welkom123</b>.<br/>Weet je het zeker?',
      success: 'Het wachtwoord is bijgewerkt',
    },
    saveUserFieldSuccess: '{fieldName} opgeslagen',
  },
  userScheduleBonus: {
    edit: {
      title: 'UserScheduleBonus wijzigen',
    },
    insert: {
      title: 'UserScheduleBonus inplannen',
      fields: {
        Type: {
          label: 'Type',
          errors: {
            required: 'Selecteer het type.',
          },
        },
        ReplenishCurrency: {
          label: 'Aanvullen tot',
          tooltip:
            'Wanneer dit aangevinkt is dan wordt het saldo van de klant opgehoogd tot en met het bedrag.',
        },
        ReplenishCoupon: {
          label: 'Aanvullen tot',
          tooltip:
            'Wanneer dit aangevinkt is dan wordt aantal vouchers van de klant opgehoogd tot dit aantal.',
        },
        ScheduleType: {
          label: 'Interval',
          enum: {
            Daily: 'Dagelijks',
            Weekly: 'Wekelijks',
            Monthly: 'Maandelijks',
          },
        },
        ScheduleDay: {
          label: 'Dag',
        },
        StartDate: {
          label: 'Startdatum',
          required: 'Voer een startdatum in.',
        },
        EndDate: {
          label: 'Tot en met',
          errors: {
            greaterThan: 'De datum moet groter zijn dan de startdatum.',
          },
        },
      },
    },
    status: {
      Active: 'Actief',
      NotYetActive: 'Nog niet actief',
      Expired: 'Verlopen',
    },
  },
  walletBulkChange: {
    title: 'Saldo bijwerken (bulk)',
    tabGenerateFile: {
      title: '1. Genereer template bestand',
      info:
        '<ol>' +
        '<li>Selecteer een betaalmiddel</li>' +
        '<li>Klik op Genereer template bestand</li>' +
        '</ol>',
      action: 'Genereer template bestand',
    },
    tabUploadChanges: {
      title: '2. Upload saldo wijzigingen',
      info:
        'Via het gegenereerde template bestand kunt u voor meerdere' +
        'klanten het saldo aanpassen.<br />' +
        'Per rij in het import bestand kunt u voor een klant' +
        "zijn/haar saldo bijwerken.<br /><br /> Via de kolom '<i>Amount</i>' " +
        'kunt het bedrag in centen invoeren ( dus 750 invoeren voor een bedrag van 7,50).<br />' +
        "Via de kolom '<i>Modifier</i>' kun u de volgende wijzigingen uitvoeren:" +
        "<ul><li><b>+</b>, het saldo van de klant opwaarderen met het bedrag in de kolom '<i>Amount</i>'.</li>" +
        '<li><b>-</b>, het saldo van de klant afwaarderen met het' +
        "bedrag in de kolom '<i>Amount</i>'.</li>" +
        '<li><b>=</b>, het saldo van de klant aanvullen tot en met' +
        "het bedrag in de kolom '<i>Amount</i>'.</li>" +
        '<li><b>0</b>, het saldo van de klant resetten naar 0.</li>' +
        '</ul>',
      codeExplained:
        '<li>Het <b>{{currencyName}}</b> saldo van Jan wordt opgewaardeerd met <i>7,40</i></li>',
    },
  },
  walletCharge: {
    title: 'Opwaarderen',
    fields: {
      Currency: {
        label: 'Betaalmiddel',
      },
      Description: {
        label: 'Omschrijving',
      },
    },
    successAlert: {
      message:
        'Er is {amountFormatted} {currencyName} opgewaardeerd voor {count} klanten. ',
    },
    failedAlert: {
      message: 'Opwaarderen mislukt voor {count} klanten. ',
    },
  },
  walletWithdraw: {
    title: 'Afwaarderen',
    fields: {
      Description: {
        label: 'Omschrijving',
      },
    },
    successAlert: {
      message:
        'Er is {amountFormatted} {currencyName} afgewaardeerd voor {count} klanten.',
    },
    failedAlert: {
      message: 'Afwaarderen mislukt voor {count} klanten. ',
    },
  },
}
