import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ButtonRow, LoaderIcon, McButton, McLink } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { filterLocalizedStringDTO } from 'src/Helpers/TableHelpers'
import { GetLocalTextDTO } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import { ICurrencyV2VTO } from 'src/Models/Server/Response/Currency/ICurrencyV2VTO'
import { useGetCurrenciesAllQuery } from 'src/Services/ToprCoreApi/RTK/Slices/CurrencySlice'

const Currencies: React.FC = () => {
  const navigate = useNavigate()

  const { data: currencies, isLoading } = useGetCurrenciesAllQuery()

  const renderCurrenciesTable = () => {
    if (isLoading || currencies === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }
    const columns: IColumn<ICurrencyV2VTO>[] = [
      {
        dataField: 'Code',
        text: 'Code',
        filter: true,
        sort: true,
        formatter: (data: ICurrencyV2VTO) => {
          return <>{`${GetLocalTextDTO(data.Description)} (${data.Code})`}</>
        },
      },
      {
        text: t.common.name,
        filter: true,
        filterFunc: (filterValue: string, allRows) => {
          if (filterValue.trim() === '') {
            return allRows
          }

          return allRows.filter((x) => {
            return filterLocalizedStringDTO(filterValue, x.Description)
          })
        },
        formatter: (data: ICurrencyV2VTO) => {
          return (
            <>
              <ButtonRow>
                <McLink
                  to={`/currency/${data.CurrencyId}/details`}
                  style="buttonPrimary"
                >
                  {t.common.details}
                </McLink>
                <McLink to={`/currency/${data.CurrencyId}/edit`} icon="fa-edit">
                  {t.common.edit}
                </McLink>
              </ButtonRow>
            </>
          )
        },
      },
    ]

    return (
      <MyDataTable
        data={currencies}
        columns={columns}
        defaultSorting={{
          dataField: 'CurrencyId',
          order: 'desc',
        }}
        defaultPageSize={25}
        //tableClassName="card"
      />
    )
  }

  const renderPortlet = () => {
    return (
      <Portlet
        title={t.common.currencies}
        headerContent={
          <McButton
            iconLeft="fa-plus"
            bsStyle="primary"
            bsSize="xs"
            title={`${t.common.currency} ${t.common.add}`}
            pullRight
            onClick={() => navigate(`/currency/insert`)}
          >
            {`${t.common.currency} ${t.common.add.toLocaleLowerCase()}`}
          </McButton>
        }
      >
        {renderCurrenciesTable()}
      </Portlet>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.common.currencies} />
        {renderPortlet()}
      </MainPage>
    </>
  )
}

export default Currencies
