import { action, makeObservable, observable } from 'mobx'
//import { IConfirmMessage } from 'src/Components/Modals/ConfirmModal'
import { createPageAlert, IPageAlert } from 'src/Components/McPageAlert'
import FileDTO from 'src/Models/Server/File/FileDTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { LocalizedStringDTO } from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'

//| T[]=[]
export default class BaseStore<T = {}> {
  public ViewModel?: T = undefined

  public IsSubmitting = false
  public IsLoading = false

  public PageAlert: IPageAlert = undefined
  public IsConfirming: boolean = false

  constructor() {
    makeObservable(this, {
      ViewModel: observable,
      IsSubmitting: observable,
      IsLoading: observable,
      PageAlert: observable,
      HandleInputChange: action,
      HandleChange: action,
      HandleLocalizedStringChange: action,
      HandleImageChange: action,
      BaseInitialize: action,
      UpdateElement: action,
      AddElement: action,
      DeleteElement: action,
      UpdateViewModel: action,
      SetPageAlert: action,
    })
  }

  public BaseInitialize(viewModel: T = undefined) {
    this.ViewModel = viewModel
    this.IsSubmitting = false
    this.PageAlert = undefined
  }

  public UpdateViewModel(viewModel: T) {
    this.ViewModel = { ...viewModel }
  }

  public HandleInputChange(event) {
    //event: React.FormEvent<HTMLInputElement>
    const target = event.currentTarget //check if ok and not .target
    //const value = target.type === 'checkbox' ? target.checked : target.value
    let value = ''

    switch (target.type) {
      case 'checkbox':
        value = target.checked
        break
      case 'file': {
        const file = target.files[0]
        // if (file) {
        //   value = file
        // }
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const dataUrl = reader.result as string
            const base64 = dataUrl.split(',')[1]
            value = base64
            this.ViewModel[name] = value
          },
          false
        )

        if (file) {
          reader.readAsDataURL(file)
        }

        break
      }
      default:
        value = target.value
        break
    }

    const name = target.name

    this.ViewModel[name] = value
  }

  public HandleChange(value: any, name: string) {
    this.ViewModel[name] = value
    //console.log('Name', name)
    //console.log('Value', value)
  }

  public HandleLocalizedStringChange(
    key: string,
    localizedString: LocalizedStringDTO
  ) {
    this.ViewModel[key] = localizedString

    //spread not allowed on generic
    //this.ViewModel = { ...(this.ViewModel as object) }
    this.ViewModel = Object.assign({}, this.ViewModel)
  }

  public HandleImageChange(key: string, imageFile: FileDTO) {
    this.ViewModel[key] = imageFile
    this.ViewModel = Object.assign({}, this.ViewModel)
  }

  //array methods

  public UpdateElement<K>(
    element: K,
    predicate: (value: K, index: number, obj: K[]) => unknown
  ) {
    const myArray = this.ViewModel as unknown as K[]
    const index = myArray.findIndex(predicate)
    if (index !== -1) {
      myArray[index] = element
    }
    this.ViewModel = [...myArray] as unknown as T
  }

  public AddElement<K>(element: K) {
    const myArray = this.ViewModel as unknown as K[]
    myArray.push(element)
    this.ViewModel = [...myArray] as unknown as T
  }

  public DeleteElement<K>(element: K) {
    let myArray = this.ViewModel as unknown as K[]
    myArray = myArray.filter((x) => x !== element)

    this.ViewModel = [...myArray] as unknown as T
  }

  public SetPageAlert(pageAlert: IPageAlert) {
    this.PageAlert = pageAlert
  }

  public SetPageAlertFromError(error: IExceptionError) {
    this.PageAlert = createPageAlert(error)
  }
}
