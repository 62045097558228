import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'

import SelectTenantModal from 'src/Components/Modals/SelectTenantModal/SelectTenantModal'
import { selectTenantStore } from 'src/Components/Modals/SelectTenantModal/Store'
import {
  InputMasks,
  MainPage,
  MyDataTable,
  MyDropDownButton,
  MyListItem,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import { IBaseUserVTO } from 'src/Models/Server/Response/User/IBaseUserVTO'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import { IFormValues, usersStore } from './Store'

interface IProps {}

const Users: React.FC<IProps> = () => {
  const { LoadingUsers, Users, TagOptions, SelectIds } = usersStore
  const navigate = useNavigate()

  useEffect(() => {
    usersStore.Initialize()
  }, [null])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Cardnumber: '',
      Search: '',
      TagOption: undefined,
    },
  })
  const { handleSubmit, watch } = useFormProps

  const onSubmitSearch = (data: IFormValues) => {
    usersStore.FindUsers(data.Search)
  }

  const onSubmitSearchCardNumber = (data: IFormValues) => {
    usersStore.FindUserByCardnumber(data.Cardnumber)
  }

  const onSubmitSearchTag = (data: IFormValues) => {
    if (data.TagOption) {
      usersStore.FindUsersByTagId(data.TagOption.value)
    }
  }

  const renderUsers = () => {
    const actionButtonsFormatter = (data: IBaseUserVTO) => {
      const { SelectedManagerUser } = persistedStore
      return (
        <>
          <Link
            className="btn btn-xs btn-primary mr-5"
            to={`/user/details/${data.UserGuid}`}
            target="_blank"
          >
            {t.common.details}
          </Link>
          {IsAdmin(SelectedManagerUser) && (
            <button
              className="btn btn-xs btn-default mr-5"
              onClick={() => selectTenantStore.OpenTenantSelect(data.UserGuid)}
            >
              {t.helpdesk.loginUserUser}
            </button>
          )}
        </>
      )
    }

    const userInfoFormatter = function (data: IBaseUserVTO) {
      return (
        <Row>
          <Col md={6}>
            <div>
              <label>{t.common.name}</label>
              <span>{data.Name}</span>
            </div>
            {data.UniqueEmailAddress && (
              <div>
                <label>{t.common.email}</label>
                <span>{data.UniqueEmailAddress}</span>
              </div>
            )}
            {data.UserName && (
              <div>
                <label>{t.common.username}</label>
                <span>{data.UserName}</span>
              </div>
            )}
          </Col>
        </Row>
      )
    }

    const columns: IColumn<IBaseUserVTO>[] = [
      {
        dataField: 'UserId',
        text: 'Id',
        sort: true,
      },
      {
        dataField: 'Name',
        text: t.common.name,
        formatter: userInfoFormatter,
        sort: true,
      },
      {
        text: '',
        formatter: actionButtonsFormatter,
      },
    ]

    if (LoadingUsers) {
      return <LoaderIcon IsLoading={LoadingUsers} />
    }

    return (
      <MyDataTable
        data={Users}
        columns={columns}
        selectIdDataColumn="UserId"
        onSelect={(selectedIds) => {
          usersStore.SetSelectIds(selectedIds)
        }}
      />
    )
  }

  const consumerPlural =
    SelectIds.length === 1 ? t.common.consumer : t.common.consumers

  const search = watch('Search')
  const cardnumber = watch('Cardnumber')
  const tagOption = watch('TagOption')

  const renderMerchantTagSearch = () => {
    if (TagOptions.length == 0) {
      return null
    }

    return (
      <form className="parsley-form" onSubmit={handleSubmit(onSubmitSearchTag)}>
        <MySelect
          formObject={{} as IFormValues}
          name="TagOption"
          useFormProps={useFormProps}
          options={TagOptions}
          isClearable={true}
          actionButton={{
            type: 'submit',
            text: t.helpdesk.submitText,
            disabled: tagOption === undefined || tagOption === null,
          }}
        />
      </form>
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.helpdesk.title} />
      <Portlet title={t.helpdesk.title}>
        <label>{t.helpdesk.fieldSearch.label}</label>
        <Row>
          <Col md={6}>
            <form
              className="parsley-form"
              onSubmit={handleSubmit(onSubmitSearch)}
            >
              <MyTextbox
                formObject={{} as IFormValues}
                placeholder={t.helpdesk.fieldSearch.placeholder}
                name="Search"
                useFormProps={useFormProps}
                maxLength={150}
                icon="fa-search"
                actionButton={{
                  type: 'submit',
                  text: t.helpdesk.submitText,
                  disabled: search.length < 3,
                }}
                tabIndex={1}
              />
            </form>
          </Col>
          <Col md={6}>
            <form
              className="parsley-form"
              onSubmit={handleSubmit(onSubmitSearchCardNumber)}
            >
              <MyTextbox
                formObject={{} as IFormValues}
                placeholder={t.helpdesk.fieldCardNumberSearch.placeholder}
                name="Cardnumber"
                useFormProps={useFormProps}
                maxLength={20}
                icon="fa-search"
                inputMask={InputMasks.Number}
                actionButton={{
                  type: 'submit',
                  text: t.helpdesk.submitText,
                  disabled: cardnumber.length < 3,
                }}
                tabIndex={2}
              />
            </form>
          </Col>
          <Col md={6}>{renderMerchantTagSearch()}</Col>
          <Col md={6} style={{ marginBottom: 20 }}>
            <MyDropDownButton
              bsStyle="default"
              style={{ minHeight: 38 }}
              disabled={SelectIds.length === 0}
              pullRight
              label={`${SelectIds.length} ${consumerPlural}`}
            >
              <MyListItem
                icon="fa-plus"
                label={t.common.charge}
                onClick={() => {
                  navigate('/wallet/charge', {
                    state: { ids: toJS(SelectIds) },
                  })
                }}
              />
              <MyListItem
                icon="fa-minus"
                label={t.common.withdraw}
                onClick={() => {
                  navigate('/wallet/withdraw', {
                    state: { ids: toJS(SelectIds) },
                  })
                }}
              />
              <MyListItem
                icon="fa-tags"
                label={t.coupons.userCouponAdd.title}
                onClick={() => {
                  navigate('/usercoupon/add', {
                    state: { ids: toJS(SelectIds) },
                  })
                }}
              />
            </MyDropDownButton>
          </Col>
        </Row>
        {renderUsers()}
      </Portlet>
      <SelectTenantModal />
    </MainPage>
  )
}

export default observer(Users)
