import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable } from 'src/Components/My'
import { sortlocalizedString } from 'src/Helpers/TableHelpers'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { IColumn } from 'src/Models/Client'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'
import { IUserEventTicketSto } from 'src/Models/Server/Response/Event/IUserEventTicketSto'
import { appUiStore } from 'src/Stores/AppUiStore'
import AddTicketModal from './Components/AddTicket/AddTicketModal'
import { initialState, reducer, reducerActions } from './Reducer'

const SubscriptionTab: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  const params = useParams<'id'>()

  const userGuid = params.id
  useEffect(() => {
    actions.initialize(userGuid)
  }, [null])

  const { isLoading, userEvents, events, addEventId } = state
  if (isLoading) {
    return <LoaderIcon IsLoading={isLoading} />
  }

  const renderButtons = (eventId: number) => {
    return (
      <ButtonToolbar className="pull-right">
        <McButton
          iconLeft="fa-plus"
          bsStyle="primary"
          bsSize="xs"
          title={t.common.add}
          onClick={() => {
            actions.toggleAddTicket(eventId)
          }}
        >
          {t.common.add} {t.common.ticket}
        </McButton>
      </ButtonToolbar>
    )
  }

  const renderEventTickets = (event: IEventVTO) => {
    const userEventInfo = userEvents.find((x) => x.EventId == event.EventId)
    if (userEventInfo === undefined) {
      return null
    }

    const columns: IColumn<IUserEventTicketSto>[] = [
      {
        text: t.common.ticket,
        sort: true,
        sortFunc(order, dataField, rowA, rowB) {
          return sortlocalizedString(
            order,
            rowA.EventTicket.Name,
            rowB.EventTicket.Name
          )
        },
        formatter: (data: IUserEventTicketSto) =>
          GetLocalText(data.EventTicket.Name),
      },
      {
        text: '',
        formatter: (data: IUserEventTicketSto) => {
          return (
            <McButton
              bsStyle="danger"
              bsSize="xs"
              type="button"
              pullRight
              onClick={() =>
                appUiStore.ShowConfirm({
                  message: t
                    .formatString(t.event.index.confirmDelete, {
                      name: GetLocalText(data.EventTicket.Name),
                    })
                    .toString(),
                  confirmActionText: t.common.delete,
                  confirmAction: () => {
                    actions.delete(data.UserEventTicketId, userGuid)
                  },
                })
              }
            >
              {t.common.delete}
            </McButton>
          )
        },
      },
    ]

    return (
      <MyDataTable
        data={userEventInfo.UserEventTickets}
        columns={columns}
        defaultSorting={{
          dataField: 'Name',
          order: 'asc',
        }}
      />
    )
  }

  const renderEvents = () => {
    return events.map((event, key) => {
      return (
        <Portlet
          key={key}
          title={GetLocalText(event.Name)}
          headerContent={renderButtons(event.EventId)}
        >
          {renderEventTickets(event)}
        </Portlet>
      )
    })
  }

  return (
    <>
      {renderEvents()}
      <AddTicketModal
        eventId={addEventId}
        onClose={() => {
          actions.toggleAddTicket(undefined)
          actions.initialize(userGuid)
        }}
      />
    </>
  )
}

export default SubscriptionTab
