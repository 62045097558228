import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { GetHbQrCodeCanvas } from 'src/Helpers/CanvasHelpers'
import { t } from 'src/I18n'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'

interface IProps {
  onClose: () => void
  qrSam: ISamVTO
}

interface IProps {}
const TableQrCodeModal: React.FC<IProps> = ({ onClose, qrSam }) => {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>(null)
  useEffect(() => {
    const setCanvas = async () => {
      const myCanvas = await GetHbQrCodeCanvas(qrSam)
      setCanvasElement(myCanvas)
      document.getElementById('myCanvasContainer').appendChild(myCanvas)
    }

    setCanvas()
  }, [null])

  return (
    <McModal
      size="lg"
      title={'QR-Code'}
      footer={
        <ButtonToolbar className="pull-right">
          <McButton
            bsStyle="primary"
            onClick={() => {
              const pdf = new jsPDF({
                orientation: 'landscape',
              })
              const data = canvasElement.toDataURL('image/png')

              const imgProperties = pdf.getImageProperties(data)
              const pdfWidth = pdf.internal.pageSize.getWidth()
              const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width

              pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
              pdf.save('QrCode.pdf')
            }}
          >
            PDF
          </McButton>
          <McButton onClick={onClose}>{t.common.close}</McButton>
        </ButtonToolbar>
      }
      closeClicked={onClose}
    >
      <div id="myCanvasContainer"></div>
    </McModal>
  )
}

export default TableQrCodeModal
