import { createApi } from '@reduxjs/toolkit/query/react'
import { baseAuthQuery } from './BaseQuery'

export const toprCoreApi = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    return await baseAuthQuery(args, api, extraOptions)
  },
  endpoints: () => ({}),
  tagTypes: [
    'Currency',
    'CurrencyPaymentMethod',
    'Merchant',
    'MerchantCurrency',
    'Subscription',
    'SubScriptionItem',
  ],
})
